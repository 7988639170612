import React from 'react';
import styled from 'styled-components';
import { Container } from '../../components/Layout';
import { Grid, Color, Breakpoints } from '../../StyleGuide';
import windowService from '../../services/windowService';
import { PrimaryButton } from '../../components/Buttons';

const InfoBanner = styled.div`
  width: 100%;
  background-color: ${Color.Gray._10};
  padding: ${Grid._3} 0;
`;

const InfoBannerContent = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  button {
    width: 100%;
  }

  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    flex-direction: row;
    justify-content: space-between;
    button {
      width: auto;
    }
  }
`;

export default function CreateAChurchBanner() {
  return (
    <InfoBanner>
      <InfoBannerContent>
        <div>
          <h5>Want to Assign Training to Others?</h5>
          <p>Is your church already setup in Ministry Grid? Check with your team before creating a new church.</p>
        </div>
        <PrimaryButton onClick={() => windowService.redirectTo('/org/create')}>
          <span className="fas fa-church" />
          Create a Church
        </PrimaryButton>
      </InfoBannerContent>
    </InfoBanner>
  );
}
