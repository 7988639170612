import React from 'react';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { MediumButton, MediumPrimaryButton } from '../../components/Buttons';
import { FormField, FormFieldContainer, Label } from '../../components/FormElements';
import { ModalBody, ModalContainer, ModalFooter, ModalHeader } from '../../components/ModalElements';
import alertService from '../../services/AlertService';
import curriculumService from '../../services/curriculumService';
import { handleError } from '../../utils/apiUtils';
import BibleStudyPodcast from '../components/BibleStudyPodcast';
import { Material } from '../models/material';

type Params = {
  podcast: Material;
  sessionId: string;
  closeModal: () => void;
};

type FormValues = {
  episodeName: string;
};

export const EditPodcastEpisodeModal = ({ podcast, sessionId, closeModal }: Params) => {
  const handleSubmit = (values: FormValues) => {
    return curriculumService
      .updateSessionMaterialName(sessionId, podcast.materialId, values.episodeName)
      .then(() => {
        alertService.show('Podcast Episode Saved');
        closeModal();
      })
      .catch((error: Error) => {
        handleError(error);
      });
  };

  return (
    <ModalContainer dismissHandler={closeModal}>
      <ModalHeader>
        <h3>Edit Podcast Episode</h3>
      </ModalHeader>
      <Formik
        initialValues={{
          episodeName: podcast.name,
        }}
        initialTouched={{ episodeUrl: true }}
        validationSchema={Yup.object().shape({
          episodeName: Yup.string().required('Podcast name is required'),
        })}
        onSubmit={handleSubmit}
      >
        {({ isSubmitting, handleChange }) => (
          <Form>
            <ModalBody>
              <FormFieldContainer>
                <Label>Podcast Preview</Label>
                <BibleStudyPodcast episodeUrl={podcast.providerData} />
              </FormFieldContainer>
              <FormFieldContainer>
                <FormField
                  label="Podcast Name"
                  name="episodeName"
                  type="text"
                  placeholder="Enter Podcast Name"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    handleChange(e);
                  }}
                />
              </FormFieldContainer>
            </ModalBody>
            <ModalFooter>
              <MediumPrimaryButton type="submit" operating={isSubmitting}>
                Save Podcast Episode
              </MediumPrimaryButton>
              <MediumButton onClick={closeModal}>Cancel</MediumButton>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </ModalContainer>
  );
};
