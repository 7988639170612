import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useUser } from '../../authentication';
import { addEmailInvitees } from '../api/addEmailInvitees';
import { createEmailInvite } from '../api/createEmailInvite';
import { getInvite } from '../api/getInvites';
import { EmailInvite } from '../models/invite';
import { InviteSettings } from '../models/inviteSettings';
import { Recipient } from '../models/recipient';
import { getStoredInvite } from '../state/storedInviteState';

type Status = {
  isPending: boolean;
  isSubmitting?: boolean;
  error?: Error;
};

export const useManageEmailInvite = () => {
  const user = useUser();
  const userId = user?.userId ?? '';
  const { orgId, inviteId } = useParams<{ orgId: string; inviteId: string }>();
  const [recipients, setRecipients] = useState<Recipient[]>([]);
  const [invite, setInvite] = useState<EmailInvite | undefined>();
  const [status, setStatus] = useState<Status>({ isPending: true });

  useEffect(() => {
    if (!orgId) return setStatus({ isPending: false, error: new Error('Organization ID is required') });
    if (!inviteId) return setStatus({ isPending: false });

    getInvite(orgId, inviteId)
      .then(response => {
        setInvite(response as EmailInvite);
        setStatus({ isPending: false });
      })
      .catch(error => {
        setStatus({ isPending: false, error });
      });
  }, [inviteId, orgId]);

  const addRecipient = (recipient: Recipient) => {
    if (invite?.invitees.some(i => i.email === recipient.email)) throw Error('Recipient already invited');
    setRecipients(prev => [...prev, recipient]);
  };

  const removeRecipient = (recipient: Recipient) => {
    setRecipients(prev => prev.filter(r => r.email !== recipient.email));
  };

  const sendInvitations = (emailBody: string) => {
    setStatus({ ...status, isSubmitting: true });

    const email = {
      subject: 'Invitation',
      body: emailBody,
    };

    if (!inviteId) {
      const storedInvite = getStoredInvite();

      if (!storedInvite?.inviteName) {
        setStatus({ ...status, isSubmitting: false });
        return Promise.reject('Invite name not found in storage. User needs to create a new invite.');
      }

      const { inviteName, roles, groups, trainings } = storedInvite;
      const settings = { roles, groups, trainings } as InviteSettings;

      return createEmailInvite(orgId, inviteName, settings, email, recipients, userId, userId)
        .then(({ inviteId }: { inviteId: string }) => `/invite/${orgId}/email/${inviteId}`)
        .finally(() => setStatus({ ...status, isSubmitting: false }));
    }

    return addEmailInvitees(inviteId, orgId, recipients, email, userId, userId)
      .then(() => `/invite/${orgId}/email/${inviteId}`)
      .finally(() => setStatus({ ...status, isSubmitting: false }));
  };

  return { ...status, invite, recipients, addRecipient, removeRecipient, sendInvitations };
};
