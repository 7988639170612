import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

const ContentWrapper = styled.div`
  position: absolute;
  left: 0;
  width: 100%;
  background: linear-gradient(rgba(255, 255, 255, 0.6) 5%, rgba(255, 255, 255, 0.9) 30%, rgb(255, 255, 255) 45%);
`;

type Props = {
  fadeHeight: number;
  top: number;
  children?: React.ReactNode;
};

export const FadedOverlay = ({ fadeHeight, top, children }: Props) => {
  const spacerElementRef = useRef<HTMLDivElement>(null);
  const innerElementRef = useRef<HTMLDivElement>(null);

  const topPx = `${top}px`;
  const fadeHeightPx = `${fadeHeight}px`;

  useEffect(() => {
    if (!innerElementRef.current) return;

    // adjust the height of the spacer element to match the height of the main content element
    const resizeObserver = new ResizeObserver(() => {
      const spacerElement = spacerElementRef.current;
      const innerElement = innerElementRef.current;

      if (!spacerElement || !innerElement) return;

      const innerHeight = innerElement.offsetHeight - (spacerElement.offsetTop - top);
      spacerElement.style.height = `${innerHeight}px`;
    });

    resizeObserver.observe(innerElementRef.current);

    return () => resizeObserver.disconnect();
  }, [top]);

  return (
    <div ref={spacerElementRef}>
      <ContentWrapper ref={innerElementRef} style={{ top: topPx, paddingTop: fadeHeightPx }}>
        {children}
      </ContentWrapper>
    </div>
  );
};
