import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import curriculumService from '../services/curriculumService';
import LoadingState from '../components/LoadingState';
import { ErrorMessage } from '../components/AlertMessages';
import { FullWidthCard, Container } from '../components/Layout';
import BibleStudyNavigation from './components/BibleStudyNavigation';
import HelpAndInfoBox from '../components/HelpAndInfoBox';

import { Button, PrimaryButton } from '../components/Buttons';
import { SessionDateHeaderAndDetails, SessionDescription } from '../components/SessionElements';
import SessionTimeline from './timeline/SessionTimeline';
import DownloadPresentationMediaButton, {
  getPresentationMediaArchiveName,
} from './timeline/DownloadPresentationMediaButton';
import windowService from '../services/windowService';
import sessionTimelineService from '../services/sessionTimelineService';
import { handleError } from '../utils/apiUtils';
import { ymdFromDate } from '../utils/dateUtils';
import { SessionTimelineProvider } from '../contexts/SessionTimelineContext';
import useCurriculum from '../hooks/useCurriculum';
import SessionTimelinePrintStyles from './timeline/SessionTimelinePrintStyles';
import useModal from '../hooks/useModal';
import ConfirmationModal from '../components/ConfirmationModal';
import { useUser } from '../authentication';
import alertService from '../services/AlertService';
import { Session } from './models/session';
import { Timeline } from './models/timeline';
import { ModalWarningList } from '../components/ModalElements';

type UrlParams = { curriculumId: string; sessionId: string; timelineId: string; originalTimelineId: string };

type State = {
  isLoading: boolean;
  isError: boolean;
  timeline?: Timeline;
  session?: Session;
};

type Crumbs = {
  name: string;
  route?: string;
};

const PreviewButton = ({ url }: { url: string }) => (
  <PrimaryButton onClick={() => windowService.openRoute(url, true)}>
    <i className="fas fa-external-link-alt" /> View as Group Leader
  </PrimaryButton>
);

export default function ManageBibleStudySession() {
  const { brand, ageCategory } = useCurriculum();
  const { curriculumId, sessionId, timelineId, originalTimelineId } = useParams<UrlParams>();
  const [data, setData] = useState<State>({
    isLoading: true,
    isError: false,
  });
  const user = useUser();
  const presentationMediaArchiveName = getPresentationMediaArchiveName(
    brand.name,
    ageCategory,
    ymdFromDate(data.session?.scheduledDate),
    data.timeline?.name
  );

  const [crumbs, setCrumbs] = useState<Crumbs[]>([
    { name: 'Curriculum', route: '#/bible-studies' },
    {
      name: `${brand.name}: ${ageCategory}`,
      route: `#/manage-bible-study/schedule/${brand.code}/${ageCategory}/${curriculumId}`,
    },
  ]);

  const redirectToResetTimeline = (resetTimelineId: string) => {
    windowService.redirectTo(
      `#/manage-bible-study/schedule/${brand.code}/${ageCategory}/${curriculumId}/${sessionId}/timeline/${originalTimelineId}/${resetTimelineId}`
    );
  };

  const submitTimelineResetAndRedirect = () => {
    sessionTimelineService
      .deleteCustomizedTimeline(originalTimelineId, timelineId, user?.lastSelectedAccount, user?.userId)
      .then(() =>
        sessionTimelineService.createCustomizedTimeline(
          data.timeline?.name,
          originalTimelineId,
          user?.lastSelectedAccount,
          user?.userId
        )
      )
      .then((timeline: { id: string }) => {
        redirectToResetTimeline(timeline.id);
        alertService.showOnNextPage('Leader Guide Reset');
      })
      .catch((error: Error) => {
        handleError(error);
        setData({ isLoading: false, isError: true });
      });
  };

  const [modal, openModal, dismissModal] = useModal((type: string, payload: any, dismissModal: () => void) => {
    if (!type) return;

    return (
      <ConfirmationModal
        buttonActionText="Reset Leader Guide"
        buttonType="danger"
        title="Reset Leader Guide"
        prompt={
          <span>
            This will delete your notes, toggle all the sections on, and restore the original sequence of events.
          </span>
        }
        subtext={'Are you sure?'}
        handleSubmit={submitTimelineResetAndRedirect}
        handleDismiss={dismissModal}
      >
        <ModalWarningList warnings={['This action cannot be undone']} />
      </ConfirmationModal>
    );
  });

  useEffect(() => {
    Promise.all([
      curriculumService.getSessionFromCurriculumSchedule(curriculumId, sessionId),
      sessionTimelineService.getPublishedAdminSessionTimeline(originalTimelineId, timelineId),
    ])
      .then(([session, timeline]) => {
        setCrumbs(prevCrumbs => [...prevCrumbs, { name: 'Leader Guide' }]);
        setData({ session, timeline, isLoading: false, isError: false });
      })
      .catch(error => {
        handleError(error);
        setData({ isLoading: false, isError: true });
      });
  }, []);

  return (
    <>
      <SessionTimelinePrintStyles options={brand.printOptions} />

      <BibleStudyNavigation crumbs={crumbs} curriculumId={curriculumId} activeTab="schedule" />
      <FullWidthCard>
        {data.isLoading ? (
          <Container>
            <LoadingState />
          </Container>
        ) : data.isError ? (
          <Container>
            <ErrorMessage>
              A problem occurred showing this page. Please refresh the page to try again.{' '}
              <a href="#/help">Contact Us</a>
            </ErrorMessage>
          </Container>
        ) : (
          <>
            <Container data-qa-hook="manageTimelineViewContainer">
              <HelpAndInfoBox
                iconClass="fas fa-chalkboard-teacher"
                title="Session Leader Guide"
                description="Here you can customize this session’s leader guide to fit your church’s time by including or excluding activities. You can also add notes to activities to give your leaders additional information."
              />
              <SessionDateHeaderAndDetails session={data.session}>
                <p>{data.session?.getAvailableThroughMessage('MMMM d, yyyy')}</p>
              </SessionDateHeaderAndDetails>
              <SessionDescription
                dangerouslySetInnerHTML={{ __html: data.session?.description ?? '' }}
              ></SessionDescription>
            </Container>
            <SessionTimelineProvider
              timeline={data.timeline ?? {}}
              curriculum={{ brandCode: brand.code, name: ageCategory }}
              session={data.session}
            >
              <SessionTimeline
                customizable
                useViewPreferences={false}
                title={`Manage ${data.timeline?.name}`}
                subtitle="Manage session activities and add notes for your leaders"
                actionButtons={
                  <>
                    <PreviewButton
                      url={`/curriculum/${brand.code}/${ageCategory}/session/${sessionId}/issue/${data.session?.issueId}/timeline/${originalTimelineId}/${timelineId}/preview`}
                    />
                    {presentationMediaArchiveName && (
                      <DownloadPresentationMediaButton
                        archiveName={presentationMediaArchiveName}
                        ButtonComponent={Button}
                      />
                    )}
                    <Button onClick={() => openModal('resetModal')}>Reset Leader Guide</Button>
                  </>
                }
              />
            </SessionTimelineProvider>
          </>
        )}
        {modal}
      </FullWidthCard>
    </>
  );
}
