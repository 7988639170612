import cacheService from '../../services/cacheService';
import submitCommand from '../../services/submitCommand';
import { Recipient } from '../models/recipient';

type Email = {
  subject: string;
  body: string;
};

export const addEmailInvitees = (
  inviteId: string,
  orgId: string,
  invitees: Recipient[],
  email: Email,
  invitor: string,
  initiatingUserId: string
) => {
  cacheService.remove(`/v1/invites/${orgId}`);
  const command = { id: inviteId, orgId: { id: orgId }, invitees, email, invitor: { id: invitor }, initiatingUserId };
  return submitCommand(inviteId, command, 'AddEmailInvitees', 'EmailInviteesAdded', 'DirectInviteError');
};
