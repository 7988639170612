import React, { useEffect, useState } from 'react';
import curriculumService from '../services/curriculumService';
import LoadingState from '../components/LoadingState';
import ErrorMessage from '../components/ErrorMessage';
import CurriculumCarousel from './components/CurriculumCarousel';
import { Session } from '../curriculum/models/session';
import { BrandCode } from '../hooks/useCurriculum';
import EmptyState from '../components/EmptyState';
import cacheService from '../services/cacheService';
import windowService from '../services/windowService';

export type Curriculum = {
  brandCode: BrandCode;
  name: string;
  brand: string;
  id: string;
};

export default function AssignedCurriculum() {
  const [data, setData] = useState<{
    isLoading: boolean;
    isError?: boolean;
    curriculums?: { curriculum: Curriculum; sessions: Session[] }[];
  }>({ isLoading: true });

  useEffect(() => {
    const redirectingThroughTabs = cacheService.get('redirectingThroughTabs');
    cacheService.remove('redirectingThroughTabs');

    curriculumService
      .getDistributedCurriculum()
      .then((res: { curriculum: Curriculum; sessions: Session[] }[]) => {
        // If the user navigates to the dashboard while not redirecting
        // through tabs and there is no curriculum assigned, redirect to the training page
        if (!redirectingThroughTabs && res.length === 0) {
          windowService.redirectTo('/dashboard/training');
        }
        res.sort((a, b) => {
          if (a.curriculum.brand === b.curriculum.brand) {
            return curriculumService.compareAgeCategory(a.curriculum.name, b.curriculum.name);
          } else {
            return curriculumService.compareBrandName(a.curriculum.brand, b.curriculum.brand);
          }
        });
        setData({ curriculums: res, isLoading: false });
      })
      .catch(reason => {
        console.error(reason);
        setData({ isLoading: false, isError: true });
      });
  }, []);

  return data.isLoading ? (
    <LoadingState />
  ) : data.isError ? (
    <ErrorMessage />
  ) : data.curriculums && data.curriculums.length === 0 ? (
    <EmptyState title="No Curriculum" description="When you are assigned curriculum it will show up here." />
  ) : (
    data.curriculums &&
    data.curriculums.map(item => (
      <CurriculumCarousel key={item.curriculum.id} curriculum={item.curriculum} sessions={item.sessions} />
    ))
  );
}
