import React from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import { Form, Formik } from 'formik';
import { MediumButton, MediumPrimaryButton } from '../../components/Buttons';
import { FormField, FormFieldContainer, Label } from '../../components/FormElements';
import LoadingIndicator from '../../components/LoadingIndicator';
import { ModalBody, ModalContainer, ModalFooter, ModalHeader } from '../../components/ModalElements';
import alertService from '../../services/AlertService';
import curriculumService from '../../services/curriculumService';
import { Border, Color } from '../../StyleGuide';
import { handleError } from '../../utils/apiUtils';
import { debounce } from '../../utils/funcUtils';
import BibleStudyPodcast from '../components/BibleStudyPodcast';
import { useAddPodcast } from '../hooks/useAddPodcast';
import { Material } from '../models/material';

type Params = {
  closeModal: () => void;
  curriculumId: string;
  sessionId: string;
  onMaterialAdded: (material: Material) => void;
};

type FormValues = {
  episodeUrl: string;
  episodeName: string;
};

const PodcastPlaceholder = styled.div`
  height: 200px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  background: ${Color.Gray._20};
  border-radius: ${Border.radius};
  border: ${'none'};
`;

export const AddPodcastEpisodeModal = ({ closeModal, curriculumId, sessionId, onMaterialAdded }: Params) => {
  const { episodeData, setEpisodeData, getBuzzsproutEpisode, extractPodcastEpisodeId, PodcastValidation } =
    useAddPodcast();

  const handleUrlChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    setFieldValue: (field: string, value: string) => void,
    setFieldError: (field: string, value: string) => void
  ) => {
    setFieldError('episodeUrl', '');
    setEpisodeData({ loading: true });

    const url = e.target.value;

    const episodeId = extractPodcastEpisodeId(url, PodcastValidation.buzzsprout);

    if (episodeId) {
      getBuzzsproutEpisode(episodeId)
        .then(data => {
          setEpisodeData({ id: episodeId, url, loading: false });
          setFieldValue('episodeName', data.title);
        })
        .catch((error: Error) => {
          setEpisodeData({ error: true, loading: false });
          handleError(error);
          setFieldError('episodeUrl', 'Failed to find episode');
        });
    } else {
      setEpisodeData({ error: true, loading: false });
    }
  };

  const handleSubmit = (values: FormValues) => {
    let collectionData: { collectionId: string; name: string }[] = [];
    return curriculumService
      .getCurriculumCollections(curriculumId)
      .then((collections: { collectionId: string; name: string }[]) => {
        collectionData = collections;
        return curriculumService.createSessionMaterial(sessionId, {
          materialType: 'podcast',
          provider: 'buzzsprout',
          providerData: episodeData?.url,
          name: values.episodeName,
          order: 0,
          package: 'Basic',
          seriesIds: collections.map(item => item.collectionId),
        });
      })
      .then(({ id }: { id: string }) => {
        alertService.show('Podcast Episode Added');
        onMaterialAdded({
          id: id,
          materialType: 'podcast',
          materialId: id,
          provider: 'buzzsprout',
          providerData: episodeData?.url || '',
          name: values.episodeName,
          order: 0,
          package: 'Basic',
          collections: collectionData.map((item, index) => ({
            collectionId: item.collectionId,
            name: item.name,
            order: index,
          })),
        });
        closeModal();
      })
      .catch((error: Error) => {
        handleError(error);
      });
  };

  return (
    <ModalContainer dismissHandler={closeModal}>
      <ModalHeader>
        <h3>Add Podcast Episode</h3>
      </ModalHeader>
      <Formik
        initialValues={{
          episodeUrl: '',
          episodeName: '',
        }}
        initialTouched={{ episodeUrl: true }}
        validationSchema={Yup.object().shape({
          episodeUrl: Yup.string()
            .required('A valid Buzzsprout episode URL is required')
            .matches(PodcastValidation.buzzsprout, 'Please enter a valid Buzzsprout episode URL'),
          episodeName: Yup.string().required('Podcast name is required'),
        })}
        validateOnChange={false}
        onSubmit={handleSubmit}
      >
        {({ values, isSubmitting, handleChange, setFieldValue, setFieldError }) => (
          <Form>
            <ModalBody>
              <FormField
                label="Buzzsprout Episode URL"
                name="episodeUrl"
                type="text"
                placeholder="Enter Buzzsprout Episode URL"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  handleChange(e);
                  setEpisodeData(undefined);
                  setFieldValue('episodeName', '');
                  debounce(() => handleUrlChange(e, setFieldValue, setFieldError), values.episodeName ? 2000 : 0);
                }}
              />
              <FormFieldContainer>
                <Label>Podcast Preview</Label>
                {episodeData?.url ? (
                  <BibleStudyPodcast episodeUrl={episodeData.url} />
                ) : (
                  <PodcastPlaceholder>
                    {episodeData?.loading ? <LoadingIndicator /> : <p>Enter podcast url to preview</p>}
                  </PodcastPlaceholder>
                )}
              </FormFieldContainer>
              <FormFieldContainer>
                <FormField
                  label="Podcast Name"
                  name="episodeName"
                  type="text"
                  placeholder="Enter Podcast Name"
                  disabled={!episodeData?.id}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    handleChange(e);
                  }}
                />
              </FormFieldContainer>
            </ModalBody>
            <ModalFooter>
              <MediumPrimaryButton type="submit" operating={isSubmitting} disabled={!values.episodeUrl}>
                Add Podcast Episode
              </MediumPrimaryButton>
              <MediumButton onClick={closeModal}>Cancel</MediumButton>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </ModalContainer>
  );
};
