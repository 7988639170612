import { useState, useEffect } from 'react';
import { useInvite } from './useInvite';
import { updateStoredInvite } from '../state/storedInviteState';
import { InviteFormValues } from '../models/inviteForm';

type Status = {
  isLoading: boolean;
  error?: Error;
};

export const useInviteSettingsForm = (orgId: string, inviteId: string) => {
  const [status, setStatus] = useState<Status>({ isLoading: true });
  const { invite, inviteSettings, isLoading, error } = useInvite(inviteId, orgId);

  useEffect(() => {
    if (!inviteId && !isLoading) return; // we've already processed this effect
    if (!inviteId && isLoading) return setStatus({ isLoading: false });

    if (error) return setStatus({ isLoading: false, error });

    if (!isLoading) {
      if (!invite) return setStatus({ isLoading: false, error: new Error('Failed to fetch invite') });

      const { name, redemptionLimit, expirationDate } = invite;

      updateStoredInvite(
        () =>
          ({
            inviteName: name,
            redemptionLimit,
            expirationDate,
            hasRedemptionLimit: !!redemptionLimit,
            hasExpirationDate: !!expirationDate,
            ...(inviteSettings || {}),
          } as InviteFormValues)
      );

      setStatus({ isLoading: false });
    }
  }, [inviteId, isLoading, error, invite, inviteSettings]);

  return status;
};
