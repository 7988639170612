import { AxiosResponse } from 'axios';
import cacheableRequest from '../../services/cacheableRequest';
import { BrandCode } from '../models/curriculum';
import { Curriculum, CurriculumPricingEntity } from '../models/curriculumPricing';

const getCurriculumCombos = (curriculum: Curriculum, allBrandCurriculum: Curriculum[]) => {
  return allBrandCurriculum.filter(
    c =>
      c.isCombo &&
      c.id !== curriculum.id &&
      c.license.findIndex(l => curriculum.license.map(l => l.itemNumber).includes(l.itemNumber)) > -1
  );
};

export const getCurriculumPricing = (brandCode?: BrandCode) => {
  return cacheableRequest(`/v1/curriculumPricing?v=${window.config.CURRICULUM_PRICING_VERSION}`).then(
    (response: AxiosResponse<CurriculumPricingEntity[]>) => {
      if (!brandCode) return response.data;

      const curriculumEntity = response.data.find(c => c.brand.code.toLowerCase() === brandCode.toLowerCase());
      if (!curriculumEntity) throw new Error(`Invalid curriculum brand code: ${brandCode}`);
      return curriculumEntity;
    }
  );
};

export const getCurriculumPricingById: (
  brandCode: BrandCode,
  curriculumId: string
) => Promise<[CurriculumPricingEntity, Curriculum[]]> = (brandCode, curriculumId) => {
  return getCurriculumPricing(brandCode).then((curriculumEntity: CurriculumPricingEntity) => {
    const curriculum = curriculumEntity.curriculum.find(c => c.id === curriculumId);

    if (!curriculum) throw new Error(`Unable to find ${brandCode} curriculum with ID of ${curriculumId}`);

    const combos = getCurriculumCombos(curriculum, curriculumEntity.curriculum);

    const filteredCurriculumEntity = {
      brand: curriculumEntity.brand,
      curriculum: [curriculum],
    } as CurriculumPricingEntity;

    return [filteredCurriculumEntity, combos];
  });
};
