import { AnalyticsAdapter } from './AnalyticsAdapter';

declare global {
  interface Window {
    FS: (command: string, data: any) => void;
  }
}

type ToggledSegmentEventData = {
  activity: string;
  group: string;
  leader: string;
  name: string;
  isFlex: boolean;
  enabled: boolean;
  images?: string[];
  videos?: string[];
};

type PartialSegment = {
  name: string;
  activity: string;
  group: string;
  leader: string;
  isFlex: boolean;
  enabled: boolean;
  sections: any[];
};

type PageProperties = {
  pageName: string;
  brandCode?: string;
  ageCategory?: string;
  isCustomizedTimeline?: boolean;
};

type PageChangedData = {
  pageName: string;
  urlParams?: { [key: string]: string };
  curriculum?: { brand: { code: string }; ageCategory: string };
};

export class FullStoryAdapter extends AnalyticsAdapter {
  #setPageProperties(properties: PageProperties) {
    if (window.featureToggles.get('DEBUG_ANALYTICS')) {
      console.debug('FullStory:setPageProperties', properties);
    }

    try {
      window.FS('setProperties', { type: 'page', properties });
    } catch (e) {
      console.error('Unable to set FullStory page properties', e, properties);
    }
  }

  #trackEvent(eventName: string, eventData: unknown) {
    if (window.featureToggles.get('DEBUG_ANALYTICS')) {
      console.debug('FullStory:trackEvent', eventName, eventData);
    }

    try {
      window.FS('trackEvent', { name: eventName, properties: eventData });
    } catch (e) {
      console.error('Unable to track FullStory event', e, eventName, eventData);
    }
  }

  handlePageChanged(data: PageChangedData) {
    const { pageName, urlParams, curriculum } = data;
    const properties: PageProperties = { pageName };

    if (pageName.indexOf('BibleStudySessionTimeline') === 0) {
      properties.isCustomizedTimeline = !!urlParams?.timelineCustomizationId;
    }

    if (curriculum) {
      properties.brandCode = curriculum.brand?.code;
      properties.ageCategory = curriculum.ageCategory;
    }

    this.#setPageProperties(properties);
  }

  handleTimelineSegmentToggled(segment: PartialSegment) {
    if (!segment) return;

    const { activity, group, leader, name, isFlex, enabled, sections } = segment;
    const eventData: ToggledSegmentEventData = { activity, group, leader, name, isFlex, enabled };

    if (Array.isArray(sections)) {
      const images = sections
        .map(section => (section.media?._type === 'image' ? section.media.name : null))
        .filter(Boolean);
      if (images.length) eventData.images = images;

      const videos = sections
        .map(section => (section.media?._type === 'video' ? section.media.name : null))
        .filter(Boolean);
      if (videos.length) eventData.videos = videos;
    }

    this.#trackEvent('Timeline Segment Toggled', eventData);
  }
}
