import React, { useEffect, useState } from 'react';
import { Assignment } from '../models/assignment';
import { Select } from '../../components/FormElements';
import orgService from '../../services/orgService';
import cacheableRequest from '../../services/cacheableRequest';
import { AxiosResponse } from 'axios';
import { shareResults } from '../api/shareResults';
import { useUser } from '../../authentication';
import alertService from '../../services/AlertService';
import { handleError } from '../../utils/apiUtils';
import LoadingState from '../../components/LoadingState';
import { ModalBody, ModalContainer, ModalFooter, ModalHeader } from '../../components/ModalElements';
import { MediumButton, MediumPrimaryButton } from '../../components/Buttons';
import { getAssignmentShares, Share } from '../api/getAssignmentShares';

type ShareResultsModalProps = {
  assignment: Assignment;
  dismissModal: () => void;
};

type Organization = {
  _id: { id: string };
  name: string;
};

function formatDate(date: number): string {
  return new Date(date).toLocaleDateString('en-US', { month: 'long', day: 'numeric', year: 'numeric' });
}

function OrganizationOption({ assignment, org, shares }: { assignment: Assignment; org: Organization; shares: Share }) {
  const isAssignedByOrg = org._id.id === assignment.assignorId?.id;
  const isAlreadyShared = shares?.sharedWith && shares?.sharedWith.some(share => share.orgId.id === org._id.id);

  let sharedDate = '';

  if (isAssignedByOrg && assignment.assignedOn) {
    sharedDate = formatDate(assignment.assignedOn);
  } else if (isAlreadyShared) {
    const share = shares?.sharedWith.find(share => share.orgId.id === org._id.id);
    sharedDate = (share?.share.on && formatDate(share?.share.on)) || '';
  }

  return (
    <option key={org.name} value={org._id.id} id={org._id.id} disabled={isAssignedByOrg || isAlreadyShared}>
      {org.name} {sharedDate && `(Shared on: ${sharedDate})`}
    </option>
  );
}

export default function ShareResultsModal({ assignment, dismissModal }: ShareResultsModalProps) {
  const user = useUser();
  const userId = user?.userId || '';
  const [organizations, setOrganizations] = useState<Organization[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [shares, setShares] = useState<Share>();
  const [selectedOrganization, setSelectedOrganization] = useState<Organization | undefined>();

  useEffect(() => {
    setIsLoading(true);
    Promise.all([
      orgService.getLicensePairingOrgs().then((orgs: Organization[]) => {
        setOrganizations(orgs);
      }),
      getAssignmentShares(assignment).then(data => setShares(data)),
    ]).then(() => {
      setIsLoading(false);
    });
  }, [assignment.instanceId]);

  return (
    <ModalContainer dismissHandler={dismissModal}>
      <ModalHeader>
        <h3>Share Results</h3>
      </ModalHeader>
      <ModalBody>
        {isLoading ? (
          <LoadingState />
        ) : (
          <div>
            <h4>Choose the organization you would like to share {assignment.name} training results with:</h4>
            <label>Organization</label>
            <Select
              value={selectedOrganization?._id.id}
              onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
                setSelectedOrganization(organizations.find(org => org._id.id === event.target.value));
              }}
            >
              <option value="">Select Organization</option>
              {shares &&
                organizations.map(org => (
                  <OrganizationOption assignment={assignment} shares={shares} key={org.name} org={org} />
                ))}
            </Select>
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <MediumPrimaryButton
          onClick={() => {
            shareResults(assignment.instanceId, selectedOrganization?._id.id || '', userId, userId)
              .then(() => {
                alertService.show('Results shared');
                dismissModal();
              })
              .catch(handleError);
          }}
          disabled={!selectedOrganization}
        >
          Yes, Share Results
        </MediumPrimaryButton>
        <MediumButton onClick={dismissModal}>No</MediumButton>
      </ModalFooter>
    </ModalContainer>
  );
}
