import React, { useEffect, useState } from 'react';
import { useUser } from '../authentication';
import ErrorMessage from '../components/ErrorMessage';
import { Container, ContainerFluid } from '../components/Layout';
import LoadingState from '../components/LoadingState';
import SectionHeader from '../components/SectionHeader';
import cacheService from '../services/cacheService';
import curriculumService from '../services/curriculumService';
import { combineUnique } from '../utils/arrayUtils';
import { getCurriculumPricing } from './api/getCurriculumPricing';
import BibleStudiesInformationBanner from './components/BibleStudiesInformationBanner';
import BibleStudyCards from './components/BibleStudyCards';
import NewPurchasesOverlay from './components/NewPurchasesOverlay';
import { Curriculum } from './models/curriculum';
import { CurriculumPricingEntity } from './models/curriculumPricing';

export default function BibleStudies() {
  const user = useUser();
  const [data, setData] = useState<{
    curriculum?: Curriculum[];
    subscribedCurriculumIds?: string[];
    recentlyPairedCurriculumIds?: string[];
    isLoading: boolean;
    isError?: boolean;
  }>({ isLoading: true });

  const recentlyPairedItemNumbers = cacheService.get('pairedItemNumbers') || [];

  useEffect(() => {
    getCurriculumPricing()
      .then((curriculumPricing: CurriculumPricingEntity[]) => {
        const curriculumLines = curriculumPricing.map(brand => ({
          ...brand,
          curriculum: brand.curriculum
            .filter(c => !c.isCombo)
            .map(c => ({ ...c, previewable: curriculumService.isPreviewCurriculum(c.id) })),
        })) as Curriculum[];

        if (!user?.lastSelectedAccount)
          return setData({
            curriculum: curriculumLines.filter(c => !c.isBeta),
            subscribedCurriculumIds: [],
            recentlyPairedCurriculumIds: [],
            isLoading: false,
          });

        curriculumService.getPairedCurriculumItemNumbers(user.lastSelectedAccount).then(activeItemNumbers => {
          const subscribedItemNumbers = combineUnique(activeItemNumbers, recentlyPairedItemNumbers);
          const allCurriculum: Curriculum[] = curriculumLines.flatMap(c => c.curriculum);

          const [subscribedCurriculumIds, recentlyPairedCurriculumIds] = subscribedItemNumbers.reduce(
            (acc, itemNumber) => {
              const crm = allCurriculum.find(crm => crm.license.findIndex(l => l.itemNumber == itemNumber) > -1);

              if (!crm) return acc;

              const [subscribedCurriculumIds, recentlyPairedCurriculumIds] = acc;

              return [
                combineUnique(subscribedCurriculumIds, [crm.id]),
                recentlyPairedItemNumbers.includes(itemNumber)
                  ? combineUnique(recentlyPairedCurriculumIds, [crm.id])
                  : recentlyPairedCurriculumIds,
              ];
            },
            [[], []]
          );

          setData({
            curriculum: curriculumLines,
            subscribedCurriculumIds: subscribedCurriculumIds,
            recentlyPairedCurriculumIds: recentlyPairedCurriculumIds,
            isLoading: false,
          });
        });
      })
      .catch(() => setData({ isError: true, isLoading: false }));

    return () => {
      cacheService.remove('pairedItemNumbers');
    };
  }, []);

  return (
    <ContainerFluid style={{ margin: '30px auto' }}>
      <Container data-qa-hook="bibleStudiesView">
        <SectionHeader title="Ongoing Curriculum" />
      </Container>
      {data.isLoading ? (
        <Container>
          <LoadingState />
        </Container>
      ) : data.isError || !data.curriculum ? (
        <Container>
          <ErrorMessage>
            A problem occurred showing this page. Please refresh the page to try again. <a href="#/help">Contact Us</a>
          </ErrorMessage>
        </Container>
      ) : (
        <>
          <Container>
            <BibleStudyCards
              BibleStudies={data.curriculum}
              subscribedCurriculumIds={data.subscribedCurriculumIds}
              recentlyPairedCurriculumIds={data.recentlyPairedCurriculumIds}
            />
          </Container>
          <BibleStudiesInformationBanner />
          <NewPurchasesOverlay curriculum={data.curriculum} instructionsType="curriculum" />
        </>
      )}
    </ContainerFluid>
  );
}
