import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactCrop, { makeAspectCrop, width, height } from 'react-image-crop';
import { Grid, Border, Shadow, Type } from '../StyleGuide';
import { Image, Container } from './Layout';
import { Input, Label } from './FormElements';
import {
  MediumOutlineButton,
  SmallRoundedIconButton,
  Buttons,
  Button,
  PrimaryButton,
  ImageButtonGroup,
} from './Buttons';
import Overlay from './Overlay';
import uuid from '../services/uuid';

export const BaseImageCrop = ({ defaultImage, onUpdate, aspectRatio, children, isCircularCrop, autoClose = true }) => {
  const [imageToCrop, setImageToCrop] = useState('');
  const [croppedImage, setCroppedImage] = useState(defaultImage || '');

  const fileInputRef = useRef();
  const onFileSelected = e => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => setImageToCrop(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  useEffect(() => {
    if (autoClose) setImageToCrop('');
    onUpdate(croppedImage);
  }, [croppedImage]);

  return (
    <>
      <Input
        ref={fileInputRef}
        type="file"
        accept="image/png,image/jpeg"
        onChange={onFileSelected}
        style={{ display: 'none' }}
        files={imageToCrop}
        value=""
      />
      {children({
        inputRef: fileInputRef,
        image: croppedImage,
        resetImage: () => setCroppedImage(''),
        closeModal: () => setImageToCrop(''),
      })}
      {imageToCrop && (
        <ImageCropOverlay
          image={imageToCrop}
          onComplete={setCroppedImage}
          onDismiss={() => setImageToCrop('')}
          aspectRatio={aspectRatio}
          isCircularCrop={isCircularCrop}
        />
      )}
    </>
  );
};
export default function ImageCrop({
  defaultImage,
  onUpdate,
  onRemove,
  buttonText = 'Upload Image',
  aspectRatio = 6 / 1,
  showRemoveButton = true,
}) {
  return (
    <BaseImageCrop defaultImage={defaultImage} onUpdate={onUpdate} aspectRatio={aspectRatio} isCircularCrop={false}>
      {({ inputRef, image, resetImage }) =>
        image ? (
          <>
            <Image src={image} style={{ width: '100%' }} />

            {showRemoveButton && (
              <ImageButtonGroup>
                <SmallRoundedIconButton
                  aria-label="Remove Image"
                  data-tracking-id="image-crop-remove"
                  className="transparent"
                  onClick={onRemove || resetImage}
                >
                  <i className="far fa-trash-alt"></i>
                </SmallRoundedIconButton>
              </ImageButtonGroup>
            )}
          </>
        ) : (
          <MediumOutlineButton
            data-tracking-id="image-crop-upload"
            onClick={() => inputRef.current.click()}
            style={{ maxWidth: '147px' }}
          >
            {buttonText}
          </MediumOutlineButton>
        )
      }
    </BaseImageCrop>
  );
}

ImageCrop.propTypes = {
  defaultImage: PropTypes.string,
  onUpdate: PropTypes.func.isRequired,
  onRemove: PropTypes.func,
  buttonText: PropTypes.string,
  aspectRatio: PropTypes.number,
  showRemoveButton: PropTypes.bool,
  isCircularCrop: PropTypes.bool,
};

export const ImageCropUtils = {
  isCroppedImage: image => image && image.indexOf('data:image/') === 0,
  convertCroppedImageToBlob: image => fetch(image).then(res => res.blob()),
  generateFilename: prefix => (prefix || uuid.generate()) + '.png',
};

const Preview = styled.div`
  canvas {
    border-radius: ${props => (props.isCircularCrop ? '50%' : Border.radius)};
    box-shadow: ${Shadow.regular};
    width: 100%;
    min-width: 100%;
  }
`;

const DimensionLabel = styled.p`
  font-size: ${Type.Scale._1};
  font-style: italic;
`;

const ImageCropAndPreview = ({ image, onCropUpdate, aspectRatio = 16 / 9, isCircularCrop }) => {
  const imgRef = useRef(null);
  const previewCanvasRef = useRef(null);
  const [crop, setCrop] = useState({ unit: '%', width: 100, aspect: aspectRatio });
  const [originalImageSize, setOriginalImageSize] = useState({ width: '', height: '' });
  const [outputImageSize, setOutputImageSize] = useState({ width: '', height: '' });
  // Increase pixel density for crop preview quality on retina screens.
  const pixelRatio = window.devicePixelRatio || 1;
  const getCroppedImage = (image, cropProps) => {
    const tmpCanvas = document.createElement('canvas');
    tmpCanvas.width = cropProps.width;
    tmpCanvas.height = cropProps.height;
    const ctx = tmpCanvas.getContext('2d');
    ctx.imageSmoothingQuality = 'high';
    ctx.drawImage(
      image,
      cropProps.x,
      cropProps.y,
      cropProps.width,
      cropProps.height,
      0,
      0,
      cropProps.width,
      cropProps.height
    );
    return tmpCanvas;
  };
  const onLoad = useCallback(img => {
    imgRef.current = img;
    setOriginalImageSize({ width: img.naturalWidth.toFixed(), height: img.naturalHeight.toFixed() });
  }, []);
  const onCropComplete = crop => {
    const image = imgRef.current;
    const canvas = previewCanvasRef.current;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');
    canvas.width = crop.width * pixelRatio;
    canvas.height = crop.height * pixelRatio;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';
    const scaled = {
      x: crop.x * scaleX,
      y: crop.y * scaleY,
      width: Math.ceil(crop.width * scaleX),
      height: Math.ceil(crop.height * scaleY),
    };
    ctx.drawImage(image, scaled.x, scaled.y, scaled.width, scaled.height, 0, 0, crop.width, crop.height);
    setOutputImageSize({ width: scaled.width, height: scaled.height });
    onCropUpdate(() => getCroppedImage.bind(null, image, scaled));
  };

  return (
    <Container>
      <div className="grid-container grid-col-4 grid-sm-col-8 grid-gap-24">
        <div className="grid-col-span-4 grid-sm-col-span-5">
          <Label>Crop Image</Label>
          <p>Drag and resize the rectangle to update the preview</p>
          <ReactCrop
            src={image}
            onImageLoaded={onLoad}
            crop={crop}
            onChange={c => setCrop(c)}
            onComplete={onCropComplete}
            circularCrop={isCircularCrop}
          />
          <DimensionLabel>
            Actual image size: {originalImageSize.width} x {originalImageSize.height}
          </DimensionLabel>
        </div>
        <Preview className="grid-col-span-4 grid-sm-col-span-3" isCircularCrop={isCircularCrop}>
          <Label>Preview</Label>
          <canvas ref={previewCanvasRef} />
          <DimensionLabel>
            Output image size: {outputImageSize.width} x {outputImageSize.height}
          </DimensionLabel>
        </Preview>
      </div>
    </Container>
  );
};
ImageCropAndPreview.propTypes = {
  image: PropTypes.string.isRequired,
  onCropUpdate: PropTypes.func.isRequired,
  aspectRatio: PropTypes.number,
  isCircularCrop: PropTypes.bool,
};

const ImageCropOverlay = ({ image, onComplete, onDismiss, aspectRatio = 16 / 9, isCircularCrop }) => {
  const [getCanvas, setGetCanvas] = useState(() => {});
  const [isOperating, setIsOperating] = useState(false);

  const onImageApprove = canvas => {
    setIsOperating(true);
    return canvas.toDataURL();
  };
  return (
    <Overlay dismissHandler={onDismiss}>
      <Container style={{ padding: Grid._6 }}>
        <ImageCropAndPreview
          image={image}
          onCropUpdate={setGetCanvas}
          aspectRatio={aspectRatio}
          isCircularCrop={isCircularCrop}
        />
        <Buttons style={{ justifyContent: 'flex-end' }}>
          <Button data-tracking-id="image-crop-cancel" onClick={onDismiss}>
            Cancel
          </Button>
          <PrimaryButton
            data-tracking-id="image-crop-confirm"
            data-qa-hook="imageApprove"
            operating={isOperating}
            onClick={() => onComplete(onImageApprove(getCanvas()))}
          >
            Done
          </PrimaryButton>
        </Buttons>
      </Container>
    </Overlay>
  );
};
ImageCropOverlay.propTypes = {
  image: PropTypes.string.isRequired,
  onComplete: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  aspectRatio: PropTypes.number,
  isCircularCrop: PropTypes.bool,
};
