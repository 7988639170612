angular.module('lwNamb').controller(
  'loginCtrl',

  [
    '$rootScope',
    '$scope',
    'userService',
    'windowService',
    'licenseService',
    '$window',
    '$log',
    '$routeParams',
    'inviteService',
    'redeemService',
    'intercomService',
    '$localStorage',
    '$interval',
    'api',
    'ssoService',
    '$timeout',
    'apiUrlService',
    function(
      $rootScope,
      $scope,
      userService,
      windowService,
      licenseService,
      $window,
      $log,
      $routeParams,
      inviteService,
      redeemService,
      intercomService,
      $localStorage,
      $interval,
      api,
      ssoService,
      $timeout,
      apiUrlService
    ) {
      $scope.bypass = $localStorage.bypass === undefined && $routeParams.bypass !== undefined;
      $localStorage.syncOrg = true;
      $scope.isLoading = $localStorage.isLoading || $routeParams.isLoading;
      $scope.checkingSession = true;
      $scope.originCss = '';
      $scope.apiIsDown = false;
      $scope.failureThreshold = 0;
      $scope.currentYear = new Date().getFullYear();
      $scope.displayLWInfo = false;

      var init = function() {
        var prefix = '//com-lifeway-bethlehem-prod.s3.amazonaws.com/publicAssets/';
        switch ($scope.origin) {
          case 'GRID':
            $scope.imgSrc = prefix + 'mgpro_logo_alt.svg';
            $scope.dataHook = 'logInGridLogo';
            $scope.alt = 'Ministry Grid';
            break;
          case 'TRANSFORMATION':
            $scope.imgSrc = prefix + 'transformation_logo_black.png';
            $scope.dataHook = 'logInTransformationLogo';
            $scope.alt = 'Transformation Church';
            break;
          case 'IDLEWILD':
            $scope.imgSrc = prefix + 'idlewildchurch_logo_color.png';
            $scope.dataHook = 'logInIdlewildChurchLogo';
            $scope.alt = 'Idlewild Church';
            break;
          case 'BELLEVUE':
            $scope.imgSrc = prefix + 'bellevue_logo.svg';
            $scope.dataHook = 'logInBellevueLogo';
            $scope.alt = 'Bellevue Training';
            break;
          case 'MBCB':
            $scope.imgSrc = prefix + 'mbcb-logo.png';
            $scope.dataHook = 'logInMBCBLogo';
            $scope.alt = 'MBCB Training';
            break;
          case 'CONNEXUS':
            $scope.imgSrc = prefix + 'connexus_logo_color.png';
            $scope.dataHook = 'logInConnexusChurchLogo';
            $scope.alt = 'Connexus Church';
            break;
          case 'CENTRIKID':
            $scope.imgSrc = prefix + 'centrikid_logo.svg';
            $scope.dataHook = 'logInCentriKidLogo';
            $scope.alt = 'CentriKid Training Online';
            break;
          case 'FUGE':
            $scope.imgSrc = prefix + 'fuge_logo_color.png';
            $scope.dataHook = 'logInFugeLogo';
            $scope.alt = 'FUGE Training Online';
            break;
          case 'STUDENTLIFE':
            $scope.imgSrc = prefix + 'studentlife_logo.png';
            $scope.dataHook = 'logInStudentLifeLogo';
            $scope.alt = 'Student Life Camp Training Online';
            break;
          case 'SBC':
            $scope.imgSrc = prefix + 'sbc_black_logo.png';
            $scope.dataHook = 'logInSBCLogo';
            $scope.alt = 'SBC Training & Orientation Portal';
            break;
          case 'A21':
            $scope.imgSrc = prefix + 'a21-logo-color.png';
            $scope.dataHook = 'logInA21Logo';
            $scope.alt = 'A21 Training Center';
            break;
          default:
            $scope.imgSrc = prefix + 'mgpro_logo_alt.svg';
            $scope.dataHook = 'logInGridLogo';
            $scope.alt = 'Ministry Grid';
            break;
        }
        $scope.redeemMC = false;
        [
          { path: 'redeem-invite', title: 'You\'re on the right track!', msg: 'Log in to redeem your Easy Link.' },
          { path: 'easy-link', title: 'You\'re on the right track!', msg: 'Log in or Register to join your organization.', easyLink: true },
          { path: 'add-to-your-training', title: 'You\'re on the right track!', msg: 'Log in or Register to access your content.' },
          { path: 'checkout', title: 'You\'re Almost There!', msg: 'Log in or Register to complete the checkout process.'}
        ].forEach(function(track) {
          if ($window.location.hash.indexOf(track.path) > -1) {
            if (track.easyLink) {
              var p = decodeURIComponent($window.location.hash.split('easy-link')[1]);
              var parts = p.split('/');
              if (parts.length === 3) {
                var prefix = parts[1];
                var suffix = parts[2];
                if(suffix.length === 4) { //Group EasyLink and not a Training Easy Link
                  inviteService.validateGroupEasyLink(prefix, suffix).then(
                    function() {
                      $scope.trackTitle = track.title;
                      $scope.trackMsg = track.msg;
                    },
                    function() {
                      $scope.showDisabledUI = true;
                    }
                  );
                } else {
                  $scope.trackTitle = track.title;
                  $scope.trackMsg = track.msg;
                }
              } else {
                $scope.trackTitle = track.title;
                $scope.trackMsg = track.msg;
              }
            } else {
              $scope.trackTitle = track.title;
              $scope.trackMsg = track.msg;
            }
          }
        });

        if ($scope.isLoading) {
          $timeout(function() {
            $scope.isLoading = $localStorage.isLoading = $routeParams.isLoading = false;
          }, 5000);
        }

        function check(f) {
          userService.checkSession().then(
            function(user) {
              $rootScope.$broadcast('LOGIN_PAGE_VALID_SESSION', user);
              loginSuccess(user);
            },
            function(reason) {
              $log.debug('check for user: failed' + JSON.stringify(reason));
              $log.debug(reason);
              $scope.checkingSession = false;
              if (f !== undefined) {
                f();
              } else if (apiUrlService.getIsSSO()) {
                ssoService.getSignedIn();
              }
            }
          );
        }

        function bypass() {
          $localStorage.bypass = true;
          $scope.redirectToLogin();
        }

        if ($routeParams.code !== undefined && $routeParams.state !== undefined) {
          $scope.authorizing = true;
          if (apiUrlService.getIsSSO()) {
            $log.debug('id_token flow');
            ssoService
              .getMgr()
              .signinRedirectCallback()
              .then(function(id_token) {
                $log.debug('id_token callback: ' + JSON.stringify(id_token));
                userService.authorizeV2(id_token.id_token, $window.location.host).then(loginSuccess, loginFailure);
              });
          } else {
            userService
              .authorize($routeParams.code, $routeParams.state, $window.location.host)
              .then(loginSuccess, loginFailure);
          }
        } else if ($localStorage.redirectEmail !== undefined) {
          userService
            .redirectToLogin($window.location.host, $window.location.href, $localStorage.redirectEmail)
            .then(function(result) {
              windowService.redirect(result);
            }, loginFailure);
        } else if ($scope.bypass) {
          check(bypass);
        } else {
          $localStorage.bypass = undefined;
          check();
        }

        $scope.checkApiStatus = $interval(function() {
          api.get('/system-info/version');
        }, 2000);

        if (intercomService.show()) {
          intercomService.bootForGuest();
        }
      };

      //Login from redeem function for non logged in users
      $scope.redeem = function(isValid, code) {
        $scope.submitted = true;
        if (isValid) {
          var codeParts = redeemService.parseCode(code);
          userService.redirectToLoginV2(
            $window.location.host + '/#/redeem-code?prefix=' + codeParts[0] + '&suffix=' + codeParts[1]
          );
        }
      };

      $scope.redirectToLogin = function() {
        if (
          $scope.origin === 'GRID' ||
          $scope.origin === 'CENTRIKID' ||
          $scope.origin === 'FUGE' ||
          $scope.origin === 'STUDENTLIFE'
        ) {
          userService.redirectToLoginV2($window.location.href);
        } else {
          userService.redirectToLogin($window.location.host, $window.location.href).then(function(result) {
            windowService.redirect(result);
          }, loginFailure);
        }
      };

      $scope.redirectToRegister = function() {
        if (
          $scope.origin === 'GRID' ||
          $scope.origin === 'CENTRIKID' ||
          $scope.origin === 'FUGE' ||
          $scope.origin === 'STUDENTLIFE'
        ) {
          userService.redirectToRegisterV2($window.location.href);
        } else {
          userService.redirectToRegister($window.location.host, $window.location.href).then(function(result) {
            windowService.redirect(result);
          }, loginFailure);
        }
      };

      $scope.showThirdPartyLogin = function() {
        var directInviteOrgId = getParameterByName('directInviteOrgId');
        var forEmail = getParameterByName('forEmail');
        return !(directInviteOrgId !== undefined && forEmail !== undefined);
      };

      function getParameterByName(name, url) {
        if (!url) url = $window.location.href;
        name = name.replace(/[\[\]]/g, '\\$&');
        var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
        var results = regex.exec(url);
        if (!results || !results[2]) return undefined;
        return decodeURIComponent(results[2].replace(/\+/g, ' '));
      }

      var loginSuccess = function(user) {
        var directInviteOrgId = getParameterByName('directInviteOrgId', user.redirectURL);
        var forEmail = getParameterByName('forEmail', user.redirectURL);
        var prefix = getParameterByName('prefix', user.redirectURL);
        var suffix = getParameterByName('suffix', user.redirectURL);
        var route = getParameterByName('route', user.redirectURL);

        if ($window.location.hash.indexOf('purchaseCheck') > -1) {
          checkLicensesAndRedirect(user);
        } else if (directInviteOrgId !== undefined && forEmail !== undefined) {
          windowService.redirectHash('/invite/accept/' + directInviteOrgId + '/' + forEmail);
        } else if (prefix !== undefined && suffix !== undefined) {
          windowService.redirectHash('/redeem-invite/' + prefix + '/' + suffix);
        } else if (route !== undefined && route.indexOf('login') === -1) {
          if (route.indexOf('create') > -1) {
            route = route + '/true';
          }
          if (route.indexOf('purchaseCheck') === -1) {
            //remove route from storage so it's not there on subsequent logins
            userService.clearRedirectUrl();
            windowService.redirectHash(route.replace('%2F', '/'));
          } else {
            redirectFallback(user);
          }
        } else if (user.redirectURL && user.redirectURL.indexOf("curriculum/onboarding") >= 0) {
          windowService.redirectHash(user.redirectURL);
        } else {
          //Check for Unpaired Subscriptions, if so, take them to the license pairing flow, if not, redirect
          checkLicensesAndRedirect(user, checkInvites);
        }
      };

      function checkInvites(user) {
        //lookup to see if pending invites, if so, show them, if not, redirect
        inviteService.lookupInvitesForEmail(user.logInEmail).then(
          function(invites) {
            if (invites.length > 0) {
              windowService.redirectHash('/pending-invites');
            } else {
              redirectFallback(user);
            }
          },
          function() {
            redirectFallback(user);
          }
        );
      }

      function checkLicensesAndRedirect(user, f) {
        licenseService.getAllUnpairedGroupLicenses().then(
          function(response) {
            if (response.length > 0) {
              $log.info(response);
              windowService.redirectHash('#/pairing');
            } else {
              if (f !== undefined) {
                f(user);
              } else {
                redirectFallback(user);
              }
            }
          },
          function() {
            redirectFallback(user);
          }
        );
      }

      function redirectFallback(user) {
        var hash = userService.getRedirectHashAfterLogin(user);
        if ($window.location.hash.indexOf('purchaseCheck') > -1) {
          hash = '/dashboard?purchased=true';
        } else if (user.redirectURL && user.redirectURL.indexOf('onboarding') > -1) {
          hash = user.redirectURL.split('#')[1];
        } else if (user.redirectURL && user.redirectURL.indexOf('select-org-and-assign') > -1) {
          hash = user.redirectURL.split('#')[1];
        } else if (
          $localStorage.syncOrg &&
          (
            $rootScope.origin === 'TRANSFORMATION' ||
            $rootScope.origin === 'IDLEWILD' ||
            $rootScope.origin === 'BELLEVUE' ||
            $rootScope.origin === 'MBCB' ||
            $rootScope.origin === 'CONNEXUS' ||
            $rootScope.origin === 'CENTRIKID' ||
            $rootScope.origin === 'FUGE' ||
            $rootScope.origin === 'STUDENTLIFE' ||
            $rootScope.origin === 'SBC' ||
            $rootScope.origin === 'A21'
          )
        ) {
          $rootScope.$broadcast('SYNC_WHITELABEL_ORG');
          hash = $localStorage.newHash ? $localStorage.newHash : '/dashboard';
        }

        windowService.redirectHash(hash);
      }

      var loginFailure = function(httpResponse) {
        $log.error(httpResponse.status + ': ' + httpResponse.data);
        windowService.redirectHash('/login');
        $scope.error = true;
      };

      $scope.toggleRedeem = function() {
        $scope.redeemMC = !$scope.redeemMC;
      };

      $scope.$on('$destroy', function() {
        $interval.cancel($scope.checkApiStatus);
      });

      $scope.toggleLWInfo = function() {
        $scope.displayLWInfo = !$scope.displayLWInfo;
      };

      init();
    },
  ]
);
