import React, { useEffect, useState } from 'react';
import { useAddPodcast } from '../hooks/useAddPodcast';

export default function BibleStudyPodcast({ episodeUrl }: { episodeUrl?: string }) {
  const { episodeData, setEpisodeData, extractPodcastEpisodeId, PodcastValidation } = useAddPodcast();

  useEffect(() => {
    if (!episodeUrl) return;

    const episodeId = extractPodcastEpisodeId(episodeUrl, PodcastValidation.buzzsprout);

    if (!episodeId) return;

    setEpisodeData({ id: episodeId, loading: false });
  }, [episodeUrl]);

  return (
    <iframe
      title="Buzzsprout Player"
      style={{ width: '100%', height: '200px', border: 'none' }}
      src={`https://www.buzzsprout.com/episodes/${episodeData?.id}?client_source=small_player&iframe=true`}
    />
  );
}
