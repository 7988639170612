const origin = typeof getOrigin === 'function' ? getOrigin() : 'GRID';

const whiteLabels = {
  default: {
    key: origin,
    name: 'Ministry Grid',
    url: 'ministrygrid.com',
    logoSrc: '/img/mgpro_logo_alt.svg',
    logoSmSrc: '/img/mgpro_logo_sm.svg',
    labels: {
      org: 'church',
      course: 'course',
      pathway: 'pathway',
      step: 'step',
    },
    catalog: {
      shortCode: 'MG',
    },
    contact: {
      phone: '866-627-8553',
      email: 'ministrygrid@lifeway.com',
      feedback: false,
    },
  },
  GRID: {},
  BELLEVUE: {
    name: 'Bellevue Training',
    url: 'training.bellevue.org',
    logoSrc: '/img/bellevue_logo.svg',
    logoSmSrc: '/img/bellevue_logo_color.png',
    catalog: {
      shortCode: 'BT',
    },
    contact: {
      email: 'jgwyn@bellevue.org',
      feedback: true,
    },
  },
  MBCB: {
    name: 'MBCB Training',
    url: 'training.mbcb.org',
    logoSrc: '/img/mbcb-logo.png',
    logoSmSrc: '/img/mbcb-logo-sm.png',
    catalog: {
      shortCode: 'MB',
    },
    contact: {
      email: 'msbaptist@mbcb.org',
      feedback: true,
    },
  },
  TRANSFORMATION: {
    name: 'Transformation Church',
    url: 'hub.transformationchurch.tc',
    logoSrc: '/img/transformation_logo_black.png',
    catalog: {
      shortCode: 'TC',
    },
    contact: {
      phone: '803-756-4692',
      email: 'TCHub@transformationchurch.tc',
      feedback: true,
    },
  },
  IDLEWILD: {
    name: 'Idlewild Church Training',
    url: 'training.idlewild.org',
    logoSrc: '/img/idlewildchurch_logo_color.png',
    catalog: {
      shortCode: 'IW',
    },
  },
  CONNEXUS: {
    name: 'Connexus Church Training',
    url: 'training.connexuschurch.com',
    logoSrc: '/img/connexus_logo_color.png',
    catalog: {
      shortCode: 'CX',
    },
    contact: {
      email: 'jhood@connexuschurch.com',
      feedback: true,
    },
  },
  CENTRIKID: {
    name: 'CentriKid Training Online',
    url: 'training.centrikid.lifeway.com',
    logoSrc: '/img/centrikid_logo.svg',
    catalog: {
      shortCode: 'CK',
    },
    contact: {
      email: 'centrikid@lifeway.com',
    },
  },
  FUGE: {
    name: 'FUGE Training Online',
    url: 'training.fuge.lifeway.com',
    logoSrc: '/img/fuge_logo_color.png',
    catalog: {
      shortCode: 'FUGE',
    },
    contact: {
      email: 'fuge@lifeway.com',
    },
  },
  STUDENTLIFE: {
    name: 'Student Life Camp Training Online',
    url: 'training.studentlife.lifeway.com',
    logoSrc: '/img/studentlife_logo.png',
    catalog: {
      shortCode: 'SL',
    },
    contact: {
      email: 'Rebekah@studentlife.com',
    },
  },
  SBC: {
    name: 'SBC Training & Orientation Portal',
    url: 'training.sbc.net',
    logoSrc: '/img/sbc_black_logo.png',
    catalog: {
      shortCode: 'SBC',
    },
    contact: {
      email: 'info@sbc.net',
    },
  },
  A21: {
    name: 'A21 Training Center',
    logoSrc: '/img/a21-logo-color.png',
    catalog: {
      shortCode: 'A21',
    },
    contact: {
      email: 'info@a21.org',
      feedback: true,
    },
  },
};

const WhiteLabel = Object.assign({}, whiteLabels.default, whiteLabels[origin] || {});
export default WhiteLabel;

export const WhiteLabelKeys = Object.keys(whiteLabels).reduce((acc, val) => ((acc[val] = val), acc), {});

export const isGrid = () => WhiteLabel.key === WhiteLabelKeys.GRID;
