import { useEffect, useState } from 'react';
import { getTraining } from '../api/getTraining';
import { Assignment } from '../models/assignment';
import { useUser } from '../../authentication';
import { handleError } from '../../utils/apiUtils';
import cacheService from '../../services/cacheService';

export type Organization = {
  _id: { id: string };
  name: string;
};

export default function useTraining(shouldGetCompleted = false) {
  const user = useUser();
  const userId = user?.userId || '';
  const [data, setData] = useState<{ training?: Assignment[]; isLoading?: boolean; isError?: boolean }>({
    isLoading: true,
  });

  function sortTraining(trainingResponse: Assignment[]) {
    if (shouldGetCompleted) {
      return trainingResponse.sort((a, b) => (b.completedOn || 0) - (a.completedOn || 0));
    } else {
      return trainingResponse.sort((a, b) => (b.assignedOn || 0) - (a.assignedOn || 0));
    }
  }

  useEffect(() => {
    cacheService.remove('redirectingThroughTabs');
    getTraining(userId, shouldGetCompleted)
      .then((trainingResponse: Assignment[]) => {
        setData({
          training: sortTraining(trainingResponse),
          isLoading: false,
        });
      })
      .catch((error: Error) => {
        handleError(error);
        setData({ isLoading: false, isError: true });
      });
  }, [userId, shouldGetCompleted]);

  return { data, setData };
}
