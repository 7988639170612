import styled from 'styled-components';
import { PreviewEmailTemplate } from '../../email';
import { Breakpoints, Grid } from '../../StyleGuide';

export const ContentContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: ${Grid._6};
  margin-bottom: ${Grid._12};

  legend {
    margin-bottom: ${Grid._4};
  }

  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    grid-template-columns: 1fr 1fr;
  }
`;

export const EmailEditor = styled.div`
  margin-bottom: ${Grid._6};
`;

export const EmailPreview = styled(PreviewEmailTemplate)`
  table#card {
    min-width: auto !important;

    tbody tr td table tbody tr td {
      h3 {
        margin-bottom: 20px !important;
      }

      table {
        width: 100%;

        a {
          display: inline-block;
          width: 100%;
        }
      }
    }
  }
`;
