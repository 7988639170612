angular.module('lwNamb').controller(
  'ContentSelectCtrl',

  [
    '$scope',
    '$routeParams',
    '$q',
    '$log',
    '$modal',
    '$location',
    'userService',
    'uuid4',
    'analyticsService',
    'contentService',
    'assessBuilderService',
    'fileUrlService',
    'taskListService',
    'alertService',
    'buildService',
    '$filter',
    function(
      $scope,
      $routeParams,
      $q,
      $log,
      $modal,
      $location,
      userService,
      uuid4,
      analyticsService,
      contentService,
      assessBuilderService,
      fileUrlService,
      taskListService,
      alertService,
      buildService,
      $filter
    ) {
      var ctrlName = 'TaskListContentSelect',
        queryParams = $location.search(),
        queryParamsWorkflowId = queryParams.workflowId,
        ownerId;

      var init = function() {
        $scope.loading = true;
        $scope.type = queryParams.taskType;
        $scope.order = queryParams.order || 1;
        if (queryParamsWorkflowId) {
          ctrlName = 'WFBuilderTaskListContentSelect';
          $scope.isWorkflowBuilder = true;
        }
        analyticsService.trackFeature(ctrlName, 'loaded');
        $scope.selectedTasks = [];
        $scope.filteredContent = [];

        //Select All boolean
        $scope.select = true;
        $scope.submitted = false;
        $scope.spinner = false;

        var label = [$filter('label')('step'), $filter('capitalize')($filter('label')('course'))];

        $scope.popover = {
          title: 'Help',
          content:
            'Here you may select a published ' +
            label[0] +
            ' to add to your ' +
            label[1] +
            '. If you dont see what you want, make sure that your content is published or use the Create button above to make your own.',
        };

        $scope.ctxRedirectTo = function (url) {
          if (queryParamsWorkflowId) {
            return buildService.ctxRedirectBackTo(url, { workflowId: queryParamsWorkflowId });
          } else {
            return buildService.ctxRedirectBackTo(url);
          }
        };
        $scope.workflowId = $routeParams.tasklistId;

        $q.all([userService.user(), buildService.ownerId()]).then(function(result) {
          var user = result[0];
          ownerId = result[1];
          $scope.user = user;
          retrieveContentData();
        });
      };

      var retrieveContentData = function() {
        contentService.getAllAvailableContentForOrg
          .with({ orgId: ownerId })
          .$promise.then(
            function(response) {
              if ($scope.type === 'Assessment') {
                contentService.filterAssessmentsRetrieved(response);
                $scope.content = contentService.assessments;
                $scope.content = contentService.assessments.filter(function(a) {
                  return a.published;
                });
              }
              if ($scope.type === 'Video' || $scope.type === 'Audio') {
                contentService.filterVideosRetrieved(response);
                $scope.content = contentService.videos;
              }
              if ($scope.type === 'Download') {
                contentService.filterDownloadsRetrieved(response);
                $scope.content = contentService.downloads;
              }
            },
            function(response) {
              $log.error('Error: Org not found; ' + response);
            }
          )
          .finally(function() {
            $scope.loading = false;
          });
      };

      function showAlertFailure() {
        alertService.show({
          title: 'Unexpected Error!',
          content: 'We were unable to add at this time. Please try again.',
          type: 'danger danger-with-content',
          duration: 20,
        });
      }

      $scope.clearInput = function() {
        $scope.searchContent = {};
      };

      //Create Content
      $scope.createContent = function() {
        $scope.task = { id: uuid4.generateId(), _type: $scope.type };
        analyticsService.trackFeature(ctrlName, 'createContent');
        if ($scope.type === 'Download' || $scope.type === 'Video' || $scope.type === 'Audio') {
          buildService.ctxRedirectTo('/courses/' + $routeParams.tasklistId + '/tasks/', {
            taskType: $scope.type,
            order: $scope.order,
            workflowId: queryParamsWorkflowId,
          });
        }
      };

      //Create New Assessment
      $scope.createAssessment = function(name, isValid, close) {
        $scope.submitted = true;
        if (isValid) {
          analyticsService.trackFeature(ctrlName, 'createAssessment');
          //Generic Data set up for Templating
          $scope.task = { id: uuid4.generateId(), type: 'Assessment', name: name, questions: [] };
          $scope.spinner = true;
          assessBuilderService
            .createAssessment($scope.task.id.id, $scope.user.userId, $scope.user.lastSelectedAccount, $scope.task.name)
            .then(
              function() {
                $scope.closeModal(close);
                buildService.ctxRedirectBackTo('/assessments/' + $scope.task.id.id);
              },
              function(reason) {
                $log.error(reason);
                if (reason === 'AssessmentNameTaken') {
                  $scope.spinner = false;
                  $scope.duplicateNameError = true;
                } else {
                  $scope.spinner = false;
                  showAlertFailure();
                }
              }
            );
        }
      };

      //Select Task
      $scope.selectTask = function(item) {
        if (item.selected === undefined || item.selected === false) {
          $scope.selectedTasks.push(item);
        } else {
          for (var i = 0; i < $scope.selectedTasks.length; i++) {
            if ($scope.selectedTasks[i].id === item.id) {
              $scope.selectedTasks.splice(i, 1);
            }
          }
        }
      };

      $scope.saveTasks = function(items) {
        $scope.spinner = true;
        analyticsService.trackFeature(ctrlName, 'savingTasks');
        if ($scope.type === 'Audio') {
          //until we officialy create an audio task type
          $scope.type = 'Video';
        }
        taskListService.addContentTasks($routeParams.tasklistId, items, $scope.order, $scope.type, $scope.user.userId).then(
          function() {
            buildService.ctxRedirectBackTo('/courses/' + $routeParams.tasklistId, queryParams);
          },
          function(event) {
            $log.error('Reason for saveTasks failure: ' + event.reason);
            showAlertFailure();
          }
        );
      };

      $scope.downloadFile = function(item) {
        analyticsService.trackFeature(ctrlName, 'downloadingFile');
        fileUrlService.openFile(item.id, item.name);
      };

      $scope.openModal = function(template) {
        var modal = $modal({
          show: true,
          templateUrl: template,
          placement: 'center',
          scope: $scope,
          controller: 'ContentSelectCtrl',
        });
        modal.$promise.then(modal.show);
      };

      $scope.closeModal = function(close) {
        $scope.$broadcast('$stateChangeStart'); //video player has a hook to destroy the video player object on this event
        if (typeof close === 'function') close();
      };

      init();
    },
  ] //End Content Select Ctrl
);
