import React from 'react';
import ConfirmationModal from '../../components/ConfirmationModal';
import { Assignment } from '../models/assignment';
import { ModalWarningList } from '../../components/ModalElements';

export default function UnassignTrainingModal({
  assignment,
  handleDismiss,
  handleSubmit,
}: {
  assignment: Assignment;
  handleDismiss: () => void;
  handleSubmit: () => void;
}) {
  return (
    <ConfirmationModal
      title="Unassign Training"
      prompt="Are you sure you want to unassign this training?"
      buttonType="danger"
      buttonActionText="Unassign Training"
      subtext={assignment.name}
      handleDismiss={handleDismiss}
      handleSubmit={handleSubmit}
    >
      <ModalWarningList warnings={['All Progress will be lost']} />
    </ConfirmationModal>
  );
}
