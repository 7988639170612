import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useUser } from '../../authentication';
import alertService from '../../services/AlertService';
import windowService from '../../services/windowService';
import workflowService, { transformWorkflow } from '../../services/workflowService';
import trainingService from '../../services/trainingService';
import { handleError } from '../../utils/apiUtils';
import { sessionStorage } from '../../utils/storageUtils';
import { reorderSortableItems } from '../../utils/reorderSortableItems';
import useManageTrainingRoute from '../../hooks/useManageTrainingRoute';
import { Pathway, PathwayEntity, PathwayTransition } from '../models/pathway';

type State = {
  pathway: Pathway;
  tasklists: PathwayEntity[];
  isLoading: boolean;
  isError: boolean;
};

export const usePathwayBuilder = () => {
  const user = useUser();
  const [data, setData] = useState<State>({
    pathway: {
      id: '',
      name: '',
      owner: '',
      published: false,
      entities: [],
      transitions: [],
    },
    tasklists: [],
    isLoading: true,
    isError: false,
  });

  const { pathway, tasklists } = data;
  const { trainingId } = useParams<{ trainingId: string }>();
  const { manageTrainingRoutePrefix } = useManageTrainingRoute();

  const redirectToSettings = () =>
    windowService.redirectTo(`${manageTrainingRoutePrefix}/pathways/${trainingId}/settings`);

  const setPathwayData = (pathway: Pathway) => {
    const filteredEntities = pathway.entities.filter(entity => entity._type !== 'Start' && entity._type !== 'End');

    setData({
      pathway,
      tasklists: filteredEntities,
      isLoading: false,
      isError: false,
    });
  };

  useEffect(() => {
    sessionStorage.removeItem('pathway-builder__select-course');
    workflowService
      .getWorkflowById(trainingId)
      .then(setPathwayData)
      .catch(() => setData(prev => ({ ...prev, isError: true })));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const reorder = reorderSortableItems(tasklists, (newEntities, originalEntities) => {
    const [startEntity, endEntity] = pathway.entities.filter(
      entity => entity._type === 'Start' || entity._type === 'End'
    );

    const entities = [startEntity, ...newEntities, endEntity];
    const entityIds = entities.map(entity => entity.id);

    setData(previousData => ({ ...previousData, tasklists: newEntities }));

    workflowService
      .reorderWorkflowEntities(trainingId, entityIds, user?.userId)
      .then(({ transitions }) => {
        // TODO: fix this
        // @ts-expect-error
        setPathwayData(transformWorkflow({ ...pathway, transitions }));
        alertService.show('Training Items Reordered');
      })
      .catch(error => {
        handleError(error);
        setData(previousData => ({ ...previousData, tasklists: originalEntities }));
      });
  });

  const removeEntity = (entityId: string) =>
    workflowService
      .removeStepFromWorkflow(trainingId, entityId, user?.userId)
      .then(({ addedTransitions, removedTransitions }) => {
        const updatedEntities = pathway.entities.filter(entity => entity.id !== entityId);
        const updatedTransitions: PathwayTransition[] = [
          ...(pathway?.transitions || []).filter(
            transition =>
              !removedTransitions.some((removedTransition: PathwayTransition) => removedTransition.id === transition.id)
          ),
          ...addedTransitions,
        ];

        setPathwayData(
          // TODO: Fix this
          // @ts-expect-error
          transformWorkflow({
            ...pathway,
            entities: updatedEntities,
            transitions: updatedTransitions,
          }) as unknown as Pathway
        );

        alertService.show('Training Item Removed');
      })
      .catch(handleError);

  const setPathwayPublishState = (isPublished: boolean) => {
    setData(previousData => ({
      ...previousData,
      pathway: { ...previousData.pathway, published: isPublished },
    }));
  };

  const handleTrainingItemRedirect = (tasklist: PathwayEntity) => {
    if (tasklist._type === 'Email') {
      sessionStorage.setItem('pathway-builder__email', tasklist);
      windowService.redirectTo(`${manageTrainingRoutePrefix}/pathways/${trainingId}/email/${tasklist.id}`);
    } else {
      sessionStorage.setItem('pathway-builder_pathway', data.pathway);
      windowService.redirectTo(
        `${manageTrainingRoutePrefix}/courses/${tasklist.taskListId?.id}?workflowId=${trainingId}`
      );
    }
  };

  const copyPathway = (name: string, ownerId: string) =>
    trainingService
      .cloneTraining('Pathway', trainingId, name, ownerId, user?.userId)
      .then(response => {
        alertService.showOnNextPage('Pathway Copied');
        // This timeout gives time for the service to fully complete all the changes
        // before redirecting. In some cases, the name wasn't updated in time.
        return new Promise<void>(resolve =>
          setTimeout(() => {
            windowService.redirectTo(`${manageTrainingRoutePrefix}/pathways/${response.id}`);
            resolve();
          }, 500)
        );
      })
      .catch(handleError);

  const publishPathway = () =>
    workflowService.publishWorkflow(trainingId, user?.userId).then(() => setPathwayPublishState(true));

  const unpublishPathway = () =>
    workflowService.unpublishWorkflow(trainingId, user?.userId).then(() => setPathwayPublishState(false));

  return {
    ...data,
    redirectToSettings,
    reorder,
    removeEntity,
    handleTrainingItemRedirect,
    copyPathway,
    publishPathway,
    unpublishPathway,
  };
};
