import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { PrimaryButton } from '../../components/Buttons';
import { FadedOverlay } from '../../components/FadedOverlay';
import { Grid, Type } from '../../StyleGuide';
import { isTrialLocked, Session } from '../models/session';
import { Volume } from '../models/volume';
import windowService from '../../services/windowService';

const LockedContentOverlayContent = styled.div`
  padding: ${Grid._4};
  text-align: center;

  h2,
  p,
  i {
    color: ${Type.Color.medium};
  }

  i {
    font-size: 48px;
  }
`;

const LockedContentOverlayTitle = styled.h2`
  margin: 16px 0 24px;
`;

const LockedContentOverlayDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin: 0;

  > p {
    margin: 0;

    &:last-of-type {
      max-width: 480px;
    }
  }

  button {
    margin-top: 24px;
  }
`;

type Data = {
  showOverlay: boolean;
  overlayProps: React.ComponentProps<typeof FadedOverlay>;
};

type Schedule = { volumes: Volume[]; sessions: Session[] };

type Props = {
  brandCode: string;
  curriculumId: string;
  isScheduleLoading: boolean;
  schedule?: Schedule;
};

export const LockedContentOverlay = ({ brandCode, curriculumId, isScheduleLoading, schedule }: Props) => {
  const [data, setData] = useState<Data>({ showOverlay: false, overlayProps: { fadeHeight: 0, top: 0 } });
  const { showOverlay, overlayProps } = data;

  const navigateToSubscription = () => windowService.redirectTo(`/subscribe-bible-study/${brandCode}/${curriculumId}`);

  useEffect(() => {
    if (isScheduleLoading || !schedule || schedule.sessions.length === 0) return;

    const firstLockedSessionId = schedule.sessions.find((s: Session) => isTrialLocked(s))?.sessionId;
    if (!firstLockedSessionId) return;

    setData(prev => ({ ...prev, showOverlay: true }));

    const resizeObserver = new ResizeObserver(() => {
      const lockedSessionElement = document.getElementById(firstLockedSessionId);

      setData(prev => ({
        ...prev,
        overlayProps: {
          fadeHeight: (lockedSessionElement?.offsetHeight || 0) * 1.35,
          top: lockedSessionElement?.offsetTop || 0,
        },
      }));
    });

    resizeObserver.observe(document.body);

    return () => resizeObserver.disconnect();
  }, [isScheduleLoading, schedule]);

  if (!brandCode || !curriculumId || !showOverlay) return null;
  return (
    <FadedOverlay {...overlayProps}>
      <LockedContentOverlayContent>
        <i className="fas fa-lock" />
        <LockedContentOverlayTitle>Unlock more content to reach the next generation</LockedContentOverlayTitle>
        <LockedContentOverlayDescription>
          <p>Your free trial comes to an end soon. Any sessions beyond this point are locked. </p>
          <p>
            Subscribe today for uninterrupted access to media-rich resources geared to engage the next generation with
            the Gospel.
          </p>
          <PrimaryButton onClick={navigateToSubscription}>View Subscription Options</PrimaryButton>
        </LockedContentOverlayDescription>
      </LockedContentOverlayContent>
    </FadedOverlay>
  );
};
