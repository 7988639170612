import React from 'react';
import styled from 'styled-components';
import { Type } from '../../StyleGuide';

const Subtitle = styled.h3`
  margin: 0;
  font-size: ${Type.Scale._4};
  font-weight: ${Type.Weight.light};
  color: ${Type.Color.medium};
`;

const Title = styled.h2`
  margin: 0;
  font-size: ${Type.Scale._5};
  font-weight: ${Type.Weight.semibold};
  color: ${Type.Color.dark};
`;

export default function BibleStudyHeader({
  thumbnail,
  title,
  subtitle,
}: {
  thumbnail: React.ReactNode;
  title: string;
  subtitle: string;
}) {
  return (
    <div className="grid-container grid-sm-col-6 grid-lg-col-5 grid-gap-16">
      <div className="grid-sm-col-span-2 grid-md-col-span-3 grid-lg-col-span-2">{thumbnail}</div>
      <div className="grid-sm-col-span-3">
        <Subtitle data-qa-hook="bibleStudySubtitle">{subtitle}</Subtitle>
        <Title data-qa-hook="bibleStudyTitle">{title}</Title>
      </div>
    </div>
  );
}
