import React from 'react';
import styled from 'styled-components';
import { Grid, Breakpoints, Color, Transition, Type } from '../StyleGuide';

import { useSteps } from './StepsContext';

import { PrimaryButton } from '../components/Buttons';
import Section from './Section';
import { purchaseLockedItem } from '../utils/purchaseUtils';
import alertService from '../services/AlertService';

const Content = styled.article`
  flex: 1;
  overflow: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${Color.Secondary._90};
  background-color: ${Color.Gray._05};
  margin-top: ${Grid._11};
  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    height: calc(100vh - ${props => props.marginTop + 'px' || '64px'});
  }
  .locked-step {
    color: ${Type.Color.dark};
    text-align: center;
    i {
      color: ${Color.Secondary._50};
      font-size: ${Grid._7};
    }
  }
`;

const Header = styled.header`
  position: fixed;
  display: flex;
  right: 0;
  top: 0;
  left: 0;
  z-index: 200;
  box-shadow: none;
  align-items: center;
  color: ${Color.white};
  background-color: ${Color.Gray._90};
  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.23);
  }
  h1 {
    order: 1;
    flex-grow: 1;
    font-size: ${Type.Scale._4};
    margin-top: 10px;
    padding: ${Grid._1} ${Grid._3};
    text-shadow: ${Type.Shadow.light};
    span {
      color: ${Color.Gray._30};
      &:after {
        content: ':';
      }
    }
    @media screen and (min-width: ${Breakpoints.screen_sm}) {
      padding: ${Grid._3} ${Grid._7};
    }
  }
  a {
    font-size: ${Type.Scale._4};
    text-decoration: none;
    color: ${Color.white};
    padding: ${Grid._4};
    border: none;
    cursor: pointer;
    transition: ${Transition.fast};
    &:after {
      content: '';
    }
  }
  a:nth-of-type(1) {
    order: 0;
    i {
      margin-right: ${Grid._2};
    }
    @media screen and (min-width: ${Breakpoints.screen_sm}) {
      font-size: ${Type.Scale._2};
      &:after {
        content: 'Back';
      }
      &:hover {
        text-decoration: underline;
        background: ${Color.Secondary._80};
      }
      padding: ${Grid._5};
      border-right: 1px solid ${Color.Secondary._80};
    }
  }
  a:nth-of-type(2) {
    display: block;
    order: 2;
    i {
      margin-right: ${Grid._2};
    }
    @media screen and (min-width: ${Breakpoints.screen_sm}) {
      display: none;
    }
  }
`;

export default function LockedCourse() {
  const [{ currentCourse }] = useSteps();

  const purchaseLockedCourse = itemNumber => purchaseLockedItem(itemNumber) || alertService.showError();

  const handleBackButton = () => {
    let nextRoute = '/dashboard';
    let prevRoute = localStorage.getItem('routeBeforeSteps');

    if (prevRoute === '/dashboard/completed') {
      nextRoute = '/dashboard/completed';
    }

    if (prevRoute) localStorage.removeItem('routeBeforeSteps');

    window.location.replace('#' + nextRoute);
  };

  return (
    <Content>
      <Header className="step-header">
        <h1></h1>
        <a data-qa-hook="backToMyTraining" onClick={handleBackButton}>
          <i className="fas fa-chevron-left"></i>
        </a>
      </Header>
      <Section className="locked-step">
        <p>
          <i className="fa fa-lock"></i>
        </p>
        <h3 data-qa-hook="lockedCourseName">&ldquo;{currentCourse.name}&rdquo; is locked</h3>
        <p>
          <PrimaryButton
            data-qa-hook="purchaseToUnlockButton"
            onClick={() => purchaseLockedCourse(currentCourse.purchaseRequired.value || currentCourse.purchaseRequired)}
          >
            Purchase to Unlock
          </PrimaryButton>
        </p>
        <p className="text-color-medium">
          If you have any questions or need assistance, please chat us using the box in the lower right corner.
        </p>
      </Section>
    </Content>
  );
}
