import React, { useState } from 'react';
import styled from 'styled-components';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { Grid } from '../StyleGuide';
import { Container, FixedHeaderContainer, FixedHeaderContent } from './Layout';
import { FormFieldContainer, FormFieldMaxCharCountStatus } from './FormElements';
import { ModalContainer, ModalBody, ModalHeader, ModalFooter } from './ModalElements';
import { PrimaryButton, RoundedIconButton, MediumPrimaryButton, MediumButton } from './Buttons';
import ActionableTitleBar from './ActionableTitleBar';
import HelpAndInfoBox from './HelpAndInfoBox';
import windowService from '../services/windowService';
import { ImageCropUtils } from './ImageCrop';
import uuid from '../services/uuid';
import alertService from '../services/AlertService';
import orgService from '../services/orgService';
import uploadService from '../services/uploadService';
import { useUser } from '../authentication';
import useAngularScope from '../hooks/useAngularScope';
import useQueryParams from '../hooks/useQueryParams';
import WhiteLabel from '../filters/WhiteLabel';
import capitalizeFilter from '../filters/capitalize-filter';
import OrgLogoSelector from './OrgLogoSelector';

const ContentContainer = styled(Container)`
  max-width: 550px;
  padding: ${Grid._8} ${Grid._4};
`;

const ButtonContainer = styled.div`
  text-align: center;
  margin-top: ${Grid._8};
`;

export default function CreateOrg() {
  const user = useUser();
  const [, updateAngularScope] = useAngularScope();
  const [logo, setLogo] = useState();
  const { redirect } = useQueryParams();

  const orgLabel = WhiteLabel.labels.org;
  const capitalizedOrgLabel = capitalizeFilter(orgLabel);

  const shouldShowImageUploader = () => {
    return windowService.getPreviousRoute().includes('pairing');
  };

  const getSuccessRedirectRoute = () => {
    if (redirect) return redirect;

    if (windowService.getPreviousRoute().includes('pairing')) return '/pairing/purchases';

    return '/org';
  };

  const getButtonText = () => {
    if (windowService.getPreviousRoute() === '/pairing') {
      return 'Connect Purchases';
    }

    return `Create ${capitalizedOrgLabel}`;
  };

  const submit = values => {
    const id = uuid.generate();

    return orgService
      .createOrg(id, values.name, user.userId)
      .then(() => {
        if (logo) {
          return ImageCropUtils.convertCroppedImageToBlob(logo)
            .then(blob => uploadService.uploadOrgLogo(id, blob))
            .then(() => orgService.setOrgLogo(id, user.userId))
            .then(response =>
              updateAngularScope(scope => {
                scope.orgLogo = `${getApiUrl()}/v1/images/org/${response.logo}?key=${uuid.generate()}`;
                scope.org.logo = response.logo;
              })
            )
            .catch(() => Promise.resolve());
        } else {
          return Promise.resolve();
        }
      })
      .then(() => {
        alertService.showOnNextPage(`${capitalizedOrgLabel} Created`);
        windowService.redirectTo(getSuccessRedirectRoute());
      })
      .catch(reason => {
        console.error(reason);
        alertService.show('An error occurred. Please try again.', 'error');
      });
  };

  const redirectBack = () => windowService.redirectBack();

  return (
    <FixedHeaderContainer data-qa-hook="unpairedCreateView" fullViewport>
      <ActionableTitleBar
        title={`Create Your ${capitalizedOrgLabel}`}
        subtitle={`What is the name of your ${orgLabel}?`}
        leftButton={
          <RoundedIconButton data-qa-hook="back" onClick={redirectBack}>
            <i className="fas fa-arrow-left fa-fw"></i>
            Back
          </RoundedIconButton>
        }
      />
      <FixedHeaderContent>
        <ContentContainer>
          <HelpAndInfoBox
            description={`Please make sure that your ${orgLabel} hasn’t already been created on ${WhiteLabel.name}. If it has, contact a ministry leader in your ${orgLabel} so that you can connect your subscriptions to the existing ${orgLabel}.`}
          />
          <Formik
            initialValues={{ name: '' }}
            validationSchema={Yup.object({
              name: Yup.string().required('A name is required'),
            })}
            onSubmit={submit}
          >
            {({ values, isSubmitting, status, setStatus, submitForm }) => (
              <Form>
                <FormFieldContainer style={{ margin: `${Grid._7} 0` }}>
                  <FormFieldMaxCharCountStatus
                    label={`${capitalizedOrgLabel} Name`}
                    name="name"
                    type="text"
                    placeholder={`Enter ${capitalizedOrgLabel} Name`}
                    maxLength={64}
                  />
                </FormFieldContainer>
                {shouldShowImageUploader() && <OrgLogoSelector onLogoUpdate={setLogo} />}
                <ButtonContainer>
                  <PrimaryButton
                    data-qa-hook="connectPurchases"
                    disabled={!values.name}
                    onClick={() => setStatus('confirm')}
                  >
                    {getButtonText()}
                  </PrimaryButton>
                </ButtonContainer>
                {status === 'confirm' && (
                  <ModalContainer dismissHandler={() => setStatus('')}>
                    <ModalHeader>
                      <h3>Create Your {capitalizedOrgLabel}</h3>
                    </ModalHeader>
                    <ModalBody data-qa-hook="createOrgModalMessage">
                      <h4>Please make sure that your {orgLabel}'s name is spelled correctly:</h4>
                      <h3 data-qa-hook="orgName">{values.name}</h3>
                    </ModalBody>
                    <ModalFooter>
                      <MediumPrimaryButton
                        aria-label="create organization confirm"
                        disabled={isSubmitting}
                        operating={isSubmitting}
                        onClick={submitForm}
                      >
                        Create
                      </MediumPrimaryButton>
                      <MediumButton data-qa-hook="createOrgModalCancel" onClick={() => setStatus('')}>
                        Cancel
                      </MediumButton>
                    </ModalFooter>
                  </ModalContainer>
                )}
              </Form>
            )}
          </Formik>
        </ContentContainer>
      </FixedHeaderContent>
    </FixedHeaderContainer>
  );
}
