import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Grid } from '../../StyleGuide';
import { PrimaryButton } from '../../components/Buttons';
import EmptyState from '../../components/EmptyState';
import ErrorMessage from '../../components/ErrorMessage';
import { CircleCheck } from '../../components/FormElements';
import LoadingState from '../../components/LoadingState';
import useCurriculum from '../../hooks/useCurriculum';
import useQueryParams from '../../hooks/useQueryParams';
import windowService from '../../services/windowService';
import { getCurriculumPricing } from '../api/getCurriculumPricing';
import { ContentTitle, ContinueButton, ListItem, ViewContainer, ViewPropTypes } from './OnboardingElements';

const EmptyStateContainer = styled.div`
  text-align: center;

  .empty-state-ui {
    margin: 0;
  }

  button {
    margin-top: ${Grid._4};
  }
`;

const AgeGroupsContainer = styled(ViewContainer)`
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: ${Grid._4};
`;

const hasItemNumber = (arr, itemNumber) => arr.some(x => x.itemNumber === itemNumber);

// Students included if needed in the future.
const trialCodeMap = {
  HYFISUMMER: [
    { name: 'Preschool', itemNumber: '005853248' },
    { name: 'Kids', itemNumber: '005853247' },
    // { name: 'Students', itemNumber: '005853249' },
  ],
};

const AgeGroupsView = ({ data, handler }) => {
  const { brand } = useCurriculum();
  const [status, setStatus] = useState({ isLoading: true, isError: false });
  const [selected, setSelected] = useState(data.items ?? []);
  const [curriculum, setCurriculum] = useState([]);
  const { code: trialCode } = useQueryParams({ preserveParams: true }) || {};

  const onItemClick = item =>
    setSelected(prev => {
      const items = hasItemNumber(selected, item.itemNumber)
        ? prev.filter(i => i.itemNumber !== item.itemNumber)
        : [...prev, item];
      return items.filter(i => !!i);
    });

  const onContinueClick = () => handler(selected);

  useEffect(() => {
    if (trialCodeMap[trialCode]) {
      setCurriculum(trialCodeMap[trialCode]);
      setStatus({ isLoading: false, isError: false });
    } else {
      getCurriculumPricing(brand.code)
        .then(curriculumPricingEntity => {
          const { curriculum } = curriculumPricingEntity;
          if (brand.previewOptions) {
            setCurriculum(
              curriculum
                .filter(c => !!c.license[0]?.itemNumber)
                .map(c => ({ name: c.name, itemNumber: c.license[0].itemNumber }))
            );
          } else {
            setCurriculum(
              curriculum.filter(c => !!c.trialItemNumber).map(c => ({ name: c.name, itemNumber: c.trialItemNumber }))
            );
          }
          setStatus({ isLoading: false, isError: false });
        })
        .catch(error => {
          console.error(error);
          setStatus({ isLoading: false, isError: true });
        });
    }
  }, []);
  return status.isLoading ? (
    <LoadingState />
  ) : status.isError ? (
    <ErrorMessage />
  ) : curriculum.length === 0 ? (
    <EmptyStateContainer>
      <EmptyState title="No Trials Available" description="View our full line of curriculum to get started today" />
      <PrimaryButton onClick={() => windowService.redirectTo('/bible-studies')}>View Curriculum</PrimaryButton>
    </EmptyStateContainer>
  ) : (
    <>
      <ContentTitle>
        <h2>Select Age Group</h2>
        <p>Select one or more curriculum age groups to try out</p>
      </ContentTitle>
      <AgeGroupsContainer data-qa-hook="ageGroupContainer">
        {curriculum.map(({ name, itemNumber }) => (
          <ListItem data-qa-hook="ageGroupItem" key={itemNumber} onClick={() => onItemClick({ itemNumber, name })}>
            <h2 data-qa-hook="ageGroupName">{name}</h2>
            <CircleCheck checked={hasItemNumber(selected, itemNumber)} />
          </ListItem>
        ))}
      </AgeGroupsContainer>
      <ContinueButton data-qa-hook="continueButton" disabled={selected.length === 0} onClick={onContinueClick}>
        Continue
      </ContinueButton>
    </>
  );
};

AgeGroupsView.propTypes = ViewPropTypes;

export default AgeGroupsView;
