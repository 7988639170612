import React from 'react';
import styled from 'styled-components';
import { Grid, Color, Type, Breakpoints } from '../../StyleGuide';
import { Card, InlineList } from '../../components/Layout';
import { SmallTransparentRoundedIconButton } from '../../components/Buttons';
import PropTypes from 'prop-types';
import { Material } from '../models/material';

const AudioIcon = styled.div`
  margin-left: ${Grid._4};
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${Color.Gray._10};
  color: ${Color.Secondary._80};
  font-size: ${Type.Scale._5};
  border-radius: 50%;
  padding: ${Grid._3};
  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    padding: 20px;
  }
`;

const Details = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${Grid._5} ${Grid._4} ${Grid._5} ${Grid._5};
  text-transform: capitalize;
  h4 {
    margin: 0 ${Grid._6} ${Grid._4} 0;
  }
`;

export default function BibleStudyAudioCard({
  audio,
  redirectToMaterial,
}: {
  audio: Material;
  redirectToMaterial: (value: string) => void;
}) {
  const downloadFile = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    const downloadUrl = `${window.getApiUrl()}/curriculum-storage/downloadvideo/${audio.mediaId}`;
    window.open(downloadUrl);
  };

  return (
    <Card
      style={{ justifyContent: 'center', alignItems: 'center' }}
      onClick={() => redirectToMaterial(audio.materialId)}
    >
      <div>
        <AudioIcon>
          <i className="fas fa-volume-up"></i>
        </AudioIcon>
      </div>
      <Details>
        <h4>{audio.name}</h4>
        <InlineList content={'•'}>
          {audio?.series?.map(c => (
            <li key={c.collectionId}>{c.name}</li>
          ))}
        </InlineList>
        <SmallTransparentRoundedIconButton onClick={e => downloadFile(e)}>
          <i className="fas fa-download fa-fw"></i>
        </SmallTransparentRoundedIconButton>
      </Details>
    </Card>
  );
}

BibleStudyAudioCard.propTypes = {
  audio: PropTypes.object.isRequired,
  redirectToMaterial: PropTypes.func.isRequired,
};
