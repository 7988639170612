import React from 'react';
import { Form, Formik, FormikHelpers } from 'formik';
import { useParams } from 'react-router-dom';
import { useUser } from '../../../authentication';
import windowService from '../../../services/windowService';
import workflowService from '../../../services/workflowService';
import trainingService from '../../../services/trainingService';
import { MediumButton, PrimaryButton } from '../../../components/Buttons';
import { FormField, Radio, RadioListWithDescription } from '../../../components/FormElements';
import { ModalBody, ModalContainer, ModalFooter, ModalHeader } from '../../../components/ModalElements';
import { handleError } from '../../../utils/apiUtils';
import { sessionStorage } from '../../../utils/storageUtils';
import useManageTrainingRoute from '../../../hooks/useManageTrainingRoute';
import { UrlParams } from '../../models/url';

type StepType = 'email' | 'course' | 'newCourse';

type StepTypeValues = {
  label: string;
  text: string;
};

type Props = {
  previousId: string;
  nextId: string;
  dismissHandler: () => void;
};

type FormValues = {
  name: string;
  stepType?: StepType;
};

export const AddPathwayStepModal = ({ previousId, nextId, dismissHandler }: Props) => {
  const user = useUser();
  const { trainingId } = useParams<UrlParams>();
  const { manageTrainingRoutePrefix } = useManageTrainingRoute();

  const stepTypes: Record<StepType, StepTypeValues> = {
    email: {
      label: 'Email',
      text: 'Create an email that will be sent to the user',
    },
    course: {
      label: 'Existing Course',
      text: 'Select an existing course to add to the pathway',
    },
    newCourse: {
      label: 'New Course',
      text: 'Provide a name to begin creating a new course',
    },
  };

  const handleStep = ({ name, stepType }: FormValues, { setErrors }: FormikHelpers<FormValues>) => {
    if (!user || !stepType) return;

    if (stepType === 'course') {
      sessionStorage.setItem('pathway-builder__select-course', { previousId, nextId });
      return windowService.redirectTo(`${manageTrainingRoutePrefix}/pathways/${trainingId}/select`);
    }

    if (stepType === 'email') {
      sessionStorage.setItem('pathway-builder__email', { previousId, nextId });
      return windowService.redirectTo(`${manageTrainingRoutePrefix}/pathways/${trainingId}/email`);
    }

    return trainingService
      .createCourse(user.lastSelectedAccount, name, user.userId, [])
      .then(response =>
        workflowService.addCoursesToPathway(trainingId, [response.id], previousId, nextId, user.userId).then(() => {
          windowService.redirectTo(`${manageTrainingRoutePrefix}/courses/${response.id}?workflowId=${trainingId}`);
        })
      )
      .catch(error => {
        if (error?.eventType === 'TaskListNameDuplicateError') {
          return setErrors({ name: 'Sorry, that course name is taken' });
        }

        handleError(error);
        dismissHandler();
      });
  };

  return (
    <ModalContainer dismissHandler={dismissHandler}>
      <ModalHeader>
        <h3>Add Training Item</h3>
      </ModalHeader>

      <Formik
        initialValues={
          {
            name: '',
          } as FormValues
        }
        onSubmit={handleStep}
      >
        {({ values, handleChange, isSubmitting }) => (
          <Form>
            <ModalBody>
              <h3>Select the type of training item to add:</h3>
              <RadioListWithDescription>
                {Object.entries(stepTypes).map(([type, { text, label }]) => (
                  <li key={type}>
                    <label>
                      <Radio id={type} name="stepType" value={type} onChange={handleChange} />
                      {label}
                    </label>
                    <p>{text}</p>

                    {type === 'newCourse' && (
                      <FormField
                        label="Name"
                        name="name"
                        onChange={handleChange}
                        placeholder="Enter Name"
                        disabled={!(values.stepType === type)}
                      />
                    )}
                  </li>
                ))}
              </RadioListWithDescription>
            </ModalBody>
            <ModalFooter>
              <PrimaryButton type="submit" operating={isSubmitting}>
                Continue
              </PrimaryButton>
              <MediumButton onClick={dismissHandler}>Cancel</MediumButton>
            </ModalFooter>
          </Form>
        )}
      </Formik>
    </ModalContainer>
  );
};
