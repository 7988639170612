import { useState } from 'react';
import apiClient from '../../services/apiClient';

type BuzzSproutResponse = {
  id: number;
  title: string;
  audio_url: string;
  artwork_url: string;
  description: string;
  summary: null;
  artist: string;
  tags: string;
  published_at: string;
  duration: number;
  hq: boolean;
  magic_mastering: boolean;
  guid: string;
  inactive_at: null;
  custom_url: string;
  episode_number: number;
  season_number: number;
  episode_type: string;
  explicit: boolean;
  private: boolean;
  total_plays: number;
};

export const useAddPodcast = () => {
  const [episodeData, setEpisodeData] = useState<{ error?: boolean; loading?: boolean; id?: string; url?: string }>();
  const PodcastValidation = {
    buzzsprout: /(?<=\/episodes\/)\d+/,
  };

  function extractPodcastEpisodeId(url: string, validation: RegExp) {
    if (!url && !validation) return;
    // const validation = type === 'buzzsprout' && PodcastValidation.buzzsprout;
    if (!validation) return;

    const match = url.match(validation);

    if (match && match[0]) {
      return match[0];
    }
    return;
  }

  function getBuzzsproutEpisode(episodeId: string): Promise<BuzzSproutResponse> {
    return apiClient
      .get(`https://www.buzzsprout.com/api/episodes/${episodeId}.json`, {
        headers: {
          Authorization: `token=${window.config.BUZZSPROUT_API_KEY}`,
        },
        withCredentials: false,
      })
      .then(({ data }) => data);
  }

  return { episodeData, setEpisodeData, getBuzzsproutEpisode, extractPodcastEpisodeId, PodcastValidation };
};
