import React, { useState, useEffect, useRef } from 'react';
import { Grid } from '../StyleGuide';
import { ContainerFluid, Container, PageTitle } from '../components/Layout';
import {
  PrimaryButton,
  ActionRowButton,
  ImageButtonGroup,
  MediumPrimaryButton,
  SmallRoundedIconButton,
} from '../components/Buttons';
import Breadcrumbs from '../components/Breadcrumbs';
import LoadingState from '../components/LoadingState';
import AccessDenied from '../components/AccessDenied';
import ErrorMessage from '../components/ErrorMessage';
import FeatureCarousel from './FeatureCarousel';
import TrainingCompilationGrid from './TrainingCompilationGrid';
import TrainingCompilationCarousel from './TrainingCompilationCarousel';
import {
  AddCarouselItemPlaceholder,
  AddTrainingCompilationPlaceholder,
  EditCatalogButtons,
  getButtonLinkType,
} from '../components/CatalogElements';
import { CarouselItem } from '../components/CarouselElements';
import AddTrainingButtonModal from './AddTrainingButtonModal';
import ConfirmationModal from '../components/ConfirmationModal';
import SectionTitle from '../components/SectionTitle';
import HelpAndInfoBox from '../components/HelpAndInfoBox';
import alertService from '../services/AlertService';
import catalogService from '../services/catalogService';
import windowService from '../services/windowService';
import { OrderingDragContainer, OrderingDragItem, OrderingButtonGroup } from '../components/OrderingElements';
import EditCatalogSectionModal from './EditCatalogSectionModal';
import ViewAllTraining from './ViewAllTraining';
import { fnOrUndef } from '../utils/funcUtils';
import { useUser } from '../authentication';

export default function ManageTrainingCatalog() {
  const user = useUser();
  const ref = useRef();
  const [data, setData] = useState({
    catalog: {},
    isLoading: true,
  });
  const [carouselData, setCarouselData] = useState({ isOrdering: false, hasOrderChanged: false, isSaving: false });
  const [compilationsData, setCompilationsData] = useState({
    isOrdering: false,
    hasOrderChanged: false,
    isSaving: false,
  });
  const [trainingButtonsData, setTrainingButtonsData] = useState({ hasOrderChanged: false, isSaving: false });
  const [removeModal, setRemoveModal] = useState({ show: false, title: '', name: '', id: '', action: '' });
  const [showAddTrainingButtonModal, setShowAddTrainingButtonModal] = useState(false);
  const [editSectionKey, setEditSectionKey] = useState();
  const crumbs = [{ name: 'Organization', route: '#/org' }, { name: 'Manage Catalog' }];

  const atIndex = windowService.getRoutePart(3);

  useEffect(() => {
    if (!user.isAuthor) {
      setData({ isAccessDenied: true, isLoading: false });
    } else {
      catalogService
        .getCatalogHomePage()
        .then(catalog => {
          setData({ catalog: catalog, isLoading: false });
        })
        .catch(error => {
          console.error(error);
          setData({ isError: true, isLoading: false });
        });
    }
  }, []);

  const updateSection = (sectionKey, value) => {
    const dataCopy = { ...data, catalog: { ...data.catalog } };
    dataCopy.catalog[sectionKey] = Object.assign({}, { ...dataCopy.catalog[sectionKey] }, value);
    setData(dataCopy);
  };

  const updateItemIndex = (list, fromIndex, toIndex) => {
    const listCopy = [...list];
    listCopy.splice(toIndex, 0, listCopy.splice(fromIndex, 1)[0]);
    return listCopy;
  };

  const updateCarouselItemIndex = (from, to) => {
    const reordered = updateItemIndex(data.catalog.featureCarouselSection.items, from, to);
    setData(prev => ({
      ...prev,
      catalog: {
        ...data.catalog,
        featureCarouselSection: { ...data.catalog.featureCarouselSection, items: reordered },
      },
    }));
    setCarouselData(prev => ({ ...prev, hasOrderChanged: true }));
    return to + 1;
  };

  const updateCompilationIndex = (from, to) => {
    const reordered = updateItemIndex(data.catalog.compilationsSection.compilations, from, to);
    setData(prev => ({
      ...prev,
      catalog: {
        ...data.catalog,
        compilationsSection: { ...data.catalog.compilationsSection, compilations: reordered },
      },
    }));
    setCompilationsData(prev => ({ ...prev, hasOrderChanged: true }));
  };

  const updateTrainingButtonIndex = (from, to) => {
    const reordered = updateItemIndex(data.catalog.buttonsSection.buttons, from, to);
    setData(prev => ({
      ...prev,
      catalog: { ...data.catalog, buttonsSection: { ...data.catalog.buttonsSection, buttons: reordered } },
    }));
    setTrainingButtonsData(prev => ({ ...prev, hasOrderChanged: true }));
  };

  const saveSectionOrder = (sectionData, setSectionData, serviceCall) => {
    setSectionData(prev => ({ ...prev, isSaving: true }));
    return (sectionData.hasOrderChanged ? serviceCall() : Promise.resolve())
      .then(() => alertService.show('Training Catalog Updated'))
      .catch(reason => {
        console.error(reason);
        alertService.show('An error occurred. Please try again.', 'error');
      })
      .finally(() => setSectionData(prev => ({ ...prev, hasOrderChanged: false, isOrdering: false, isSaving: false })));
  };

  const saveCarouselOrder = () =>
    saveSectionOrder(carouselData, setCarouselData, () =>
      catalogService.reorderFeatureCarouselItems(
        data.catalog.id,
        data.catalog.featureCarouselSection.sectionId,
        data.catalog.featureCarouselSection.items.map(item => item.id),
        user.userId
      )
    );
  const saveCompilationsOrder = () =>
    saveSectionOrder(compilationsData, setCompilationsData, () =>
      catalogService.setCatalogCompilations(
        data.catalog.id,
        data.catalog.compilationsSection.sectionId,
        data.catalog.compilationsSection.compilations.map(c => c._id),
        user.userId
      )
    );
  const saveTrainingButtonsOrder = () =>
    saveSectionOrder(trainingButtonsData, setTrainingButtonsData, () =>
      catalogService.reorderTrainingButtons(
        data.catalog.id,
        data.catalog.buttonsSection.sectionId,
        data.catalog.buttonsSection.buttons.map(b => b.id),
        user.userId
      )
    );

  const removeCarouselItem = carouselItemId => {
    catalogService
      .removeCarouselItem(data.catalog.id, data.catalog.featureCarouselSection.sectionId, carouselItemId, user.userId)
      .then(() => {
        alertService.show('Training Catalog Updated');
        setData({
          catalog: {
            ...data.catalog,
            featureCarouselSection: {
              ...data.catalog.featureCarouselSection,
              items: [
                ...data.catalog.featureCarouselSection.items.filter(carouselItem => carouselItem.id !== carouselItemId),
              ],
            },
          },
        });
      })
      .catch(reason => {
        console.error(reason);
        alertService.show('An error occurred. Please try again.', 'error');
      })
      .finally(() => setRemoveModal({ show: false, title: '', name: '', id: '', action: '' }));
  };

  const removeCompilation = compilationId => {
    const compilationIds = data.catalog.compilationsSection.compilations
      .map(comp => comp._id)
      .filter(id => id !== compilationId);
    catalogService
      .setCatalogCompilations(data.catalog.id, data.catalog.compilationsSection.sectionId, compilationIds, user.userId)
      .then(() => {
        alertService.show('Training Catalog Updated');
        setData({
          catalog: {
            ...data.catalog,
            compilationsSection: {
              ...data.catalog.compilationsSection,
              compilations: [
                ...data.catalog.compilationsSection.compilations.filter(comp => comp._id !== compilationId),
              ],
            },
          },
        });
      })
      .catch(reason => {
        console.error(reason);
        alertService.show('An error occurred. Please try again.', 'error');
      })
      .finally(() => setRemoveModal({ show: false, title: '', name: '', id: '', action: '' }));
  };

  const removeTrainingButton = buttonId => {
    catalogService
      .removeTrainingButton(data.catalog.id, data.catalog.buttonsSection.sectionId, buttonId, user.userId)
      .then(() => {
        alertService.show('Training Catalog Updated');
        setData({
          catalog: {
            ...data.catalog,
            buttonsSection: {
              ...data.catalog.buttonsSection,
              buttons: [...data.catalog.buttonsSection.buttons.filter(button => button.id !== buttonId)],
            },
          },
        });
      })
      .catch(reason => {
        console.error(reason);
        alertService.show('An error occurred. Please try again.', 'error');
      })
      .finally(() => setRemoveModal({ show: false, title: '', name: '', id: '', action: '' }));
  };

  return (
    <>
      <Breadcrumbs crumbs={crumbs} />
      {data.isLoading ? (
        <Container>
          <LoadingState />
        </Container>
      ) : data.isError ? (
        <Container>
          <ErrorMessage>
            A problem occurred showing this page. Please refresh the page to try again. <a href="#/help">Contact Us</a>
          </ErrorMessage>
        </Container>
      ) : data.isAccessDenied ? (
        <Container>
          <AccessDenied />
        </Container>
      ) : (
        <>
          <Container data-qa-hook="manageCatalogView">
            <PageTitle className="mt-20">
              <h2>Manage Catalog</h2>
              <p>Add, edit and remove training catalog items.</p>
            </PageTitle>
          </Container>
          <ContainerFluid style={{ margin: `${Grid._6} auto ${Grid._12}` }}>
            <FeatureCarousel ref={ref} initialFirstItem={atIndex ? parseInt(atIndex) + 1 : 1} enableAutoPlay={false}>
              <AddCarouselItemPlaceholder index={0} />
              {data.catalog.featureCarouselSection.items.length > 0 &&
                data.catalog.featureCarouselSection.items.map((carouselItem, index, carouselItems) => (
                  <CarouselItem key={carouselItem.id} item={carouselItem}>
                    <ImageButtonGroup>
                      {!carouselData.isOrdering && (
                        <EditCatalogButtons
                          edit={() =>
                            windowService.redirectTo(`#/manage-catalog/carousel-item/${index}/${carouselItem.id}`)
                          }
                          reorder={() => setCarouselData(prev => ({ ...prev, isOrdering: true }))}
                          remove={() =>
                            setRemoveModal({
                              show: true,
                              title: 'Carousel Item',
                              name: carouselItem.text.title,
                              id: carouselItem.id,
                              action: removeCarouselItem,
                            })
                          }
                        />
                      )}
                      {carouselData.isOrdering && (
                        <OrderingButtonGroup
                          save={saveCarouselOrder}
                          isSaving={carouselData.isSaving}
                          decrement={fnOrUndef(index > 0, () =>
                            ref.current.goTo(updateCarouselItemIndex(index, index - 1))
                          )}
                          increment={fnOrUndef(index < carouselItems.length - 1, () =>
                            ref.current.goTo(updateCarouselItemIndex(index, index + 1))
                          )}
                        />
                      )}
                    </ImageButtonGroup>
                  </CarouselItem>
                ))}
              {data.catalog.featureCarouselSection.items.length > 0 && (
                <AddCarouselItemPlaceholder index={data.catalog.featureCarouselSection.items.length} />
              )}
            </FeatureCarousel>
          </ContainerFluid>
          <Container style={{ margin: `${Grid._12} auto` }}>
            <div
              className="grid-container grid-col-1 grid-sm-col-2 grid-md-col-3 grid-gap-24"
              style={{ position: 'relative' }}
            >
              <AddTrainingCompilationPlaceholder index={'first'} />
            </div>
          </Container>
          {data.catalog.compilationsSection.compilations.length > 0 &&
            data.catalog.compilationsSection.compilations.map((compilation, index, compilations) => (
              <Container
                data-qa-hook="compilationContainer"
                style={{ margin: `${Grid._12} auto` }}
                key={compilation._id}
              >
                <SectionTitle title={compilation.name} subtitle={compilation.shortDescription}>
                  {!compilationsData.isOrdering && (
                    <EditCatalogButtons
                      reorder={() => setCompilationsData(prev => ({ ...prev, isOrdering: true }))}
                      remove={() =>
                        setRemoveModal({
                          show: true,
                          title: 'Training Compilation',
                          name: compilation.name,
                          id: compilation._id,
                          action: removeCompilation,
                        })
                      }
                    />
                  )}
                  {compilationsData.isOrdering && (
                    <OrderingButtonGroup
                      horizontal={false}
                      save={saveCompilationsOrder}
                      isSaving={compilationsData.isSaving}
                      decrement={fnOrUndef(index > 0, () => updateCompilationIndex(index, index - 1))}
                      increment={fnOrUndef(index < compilations.length - 1, () =>
                        updateCompilationIndex(index, index + 1)
                      )}
                    />
                  )}
                </SectionTitle>
                {compilation.trainingItems.length ? (
                  <>
                    {compilation.catalogComponentStyle === 'Grid' && (
                      <TrainingCompilationGrid
                        compilationId={compilation._id}
                        trainingItems={compilation.trainingItems}
                        canRedirect={false}
                      />
                    )}
                    {compilation.catalogComponentStyle === 'Carousel' && (
                      <TrainingCompilationCarousel
                        compilationId={compilation._id}
                        trainingItems={compilation.trainingItems}
                        canRedirect={false}
                      />
                    )}
                  </>
                ) : (
                  <HelpAndInfoBox
                    title="Training is on the way"
                    description="Authors are curating content for this compilation and it will be visible here soon."
                  />
                )}
              </Container>
            ))}
          {data.catalog.compilationsSection.compilations.length > 0 && (
            <Container style={{ margin: `${Grid._12} auto` }}>
              <div
                className="grid-container grid-col-1 grid-sm-col-2 grid-md-col-3 grid-gap-24"
                style={{ position: 'relative' }}
              >
                <AddTrainingCompilationPlaceholder index={'last'} />
              </div>
            </Container>
          )}
          <Container style={{ marginBottom: `${Grid._6}` }}>
            <SectionTitle title={data.catalog.buttonsSection.title} subtitle={data.catalog.buttonsSection.description}>
              {trainingButtonsData.hasOrderChanged && (
                <div>
                  <MediumPrimaryButton
                    onClick={saveTrainingButtonsOrder}
                    disabled={trainingButtonsData.isSaving}
                    operating={trainingButtonsData.isSaving}
                  >
                    Save Order
                  </MediumPrimaryButton>
                </div>
              )}
              <SmallRoundedIconButton onClick={() => setEditSectionKey('buttonsSection')}>
                <i className="fas fa-pen" />
              </SmallRoundedIconButton>
            </SectionTitle>
            <OrderingDragContainer
              data-qa-hook="trainingButtonContainer"
              className="grid-container grid-col-1 grid-sm-col-2 grid-md-col-3 grid-gap-24"
              axis="xy"
              onSortEnd={({ oldIndex, newIndex }) => updateTrainingButtonIndex(oldIndex, newIndex)}
              useDragHandle
            >
              {data.catalog.buttonsSection.buttons &&
                data.catalog.buttonsSection.buttons.map((button, index) => {
                  const link = button.link;
                  const buttonLinkType = getButtonLinkType(link);
                  return (
                    <OrderingDragItem data-qa-hook="trainingButtonItem" key={`training-button-${index}`} index={index}>
                      <ActionRowButton data-qa-hook="trainingButtonName" className="disabled">
                        {button.text}{' '}
                        <EditCatalogButtons
                          edit={() =>
                            windowService.redirectTo(`#/manage-catalog/training-button/${buttonLinkType}/${button.id}`)
                          }
                          reorder={() => {}}
                          remove={() =>
                            setRemoveModal({
                              show: true,
                              title: 'Training Button',
                              name: button.text,
                              id: button.id,
                              action: removeTrainingButton,
                            })
                          }
                        />
                      </ActionRowButton>
                    </OrderingDragItem>
                  );
                })}
            </OrderingDragContainer>
            <div className="grid-container grid-col-1 grid-sm-col-2 grid-gap-24" style={{ marginTop: Grid._5 }}>
              <PrimaryButton
                data-qa-hook="trainingButtonAdd"
                style={{ padding: '16px 20px' }}
                onClick={() => setShowAddTrainingButtonModal(true)}
              >
                <i className="fas fa-plus" />
                Add Training Button
              </PrimaryButton>
            </div>
          </Container>
          <ViewAllTraining
            description={data.catalog.viewAllSection.description}
            onEditClick={() => setEditSectionKey('viewAllSection')}
          />
        </>
      )}
      {showAddTrainingButtonModal && (
        <AddTrainingButtonModal handleDismiss={() => setShowAddTrainingButtonModal(false)} />
      )}
      {editSectionKey && (
        <EditCatalogSectionModal
          catalogId={data.catalog.id}
          section={data.catalog[editSectionKey]}
          userId={user.userId}
          hideName={editSectionKey === 'viewAllSection'}
          handleDismiss={() => setEditSectionKey()}
          onSave={(name, shortDescription) => {
            updateSection(editSectionKey, { title: name, description: shortDescription });
            alertService.show('Training Catalog Updated');
            setEditSectionKey();
          }}
          onError={error => {
            console.error(error);
            alertService.show('An error occurred. Please try again.', 'error');
            setEditSectionKey();
          }}
        />
      )}
      {removeModal.show && (
        <ConfirmationModal
          buttonActionText="Remove"
          buttonType="danger"
          title={`Remove ${removeModal.title}`}
          prompt={
            <span>
              Are you sure you want to remove this {removeModal.title.toLowerCase()} from the training catalog?
            </span>
          }
          subtext={removeModal.name}
          handleSubmit={() => {
            removeModal.action(removeModal.id);
          }}
          handleDismiss={() => setRemoveModal({ show: false, title: '', name: '', id: '', action: '' })}
        />
      )}
    </>
  );
}
