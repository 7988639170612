import React from 'react';
import styled from 'styled-components';
import AssignmentCard from './components/AssignmentCard';
import useTraining from './hooks/useTraining';
import LoadingState from '../components/LoadingState';
import EmptyState from '../components/EmptyState';
import ErrorMessage from '../components/ErrorMessage';
import { Grid, Type } from '../StyleGuide';
import { useUser } from '../authentication';
import { SmallRoundedIconButton } from '../components/Buttons';
import useModal from '../hooks/useModal';
import ShareResultsModal from './components/ShareResultsModal';
import { Assignment } from './models/assignment';

const AssignmentContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${Grid._4};
`;

export default function CompletedTraining() {
  const user = useUser();
  const userId = user?.userId || '';
  const { data } = useTraining(true);

  const [modal, openModal] = useModal((type: string, payload: Assignment, dismissModal: () => void) => {
    switch (type) {
      case 'shareResults':
        return <ShareResultsModal assignment={payload} dismissModal={dismissModal} />;
      default:
        return null;
    }
  });

  return (
    <>
      {data.isLoading ? (
        <LoadingState />
      ) : data.isError ? (
        <ErrorMessage />
      ) : data.training && data.training.length > 0 ? (
        <AssignmentContainer>
          <div className="grid-container grid-gap-16" style={{ flex: 1 }}>
            {data.training?.map(assignment => (
              <AssignmentCard
                key={assignment.instanceId}
                data-tracking-component="completed-training-assignment"
                data-tracking-id={assignment.name}
                assignment={assignment}
                isActiveAssignment={false}
                assigneeUserId={userId}
                actionButtons={
                  <>
                    {assignment._type !== 'CompleteWorkflowOverview' && (
                      <SmallRoundedIconButton
                        data-tracking-component="button"
                        data-tracking-id="share-results"
                        onClick={() => openModal('shareResults', assignment)}
                        aria-label="share training results"
                      >
                        <i className="icon ion-share" style={{ fontSize: Type.Scale._4 }} />
                      </SmallRoundedIconButton>
                    )}
                  </>
                }
              />
            ))}
          </div>
        </AssignmentContainer>
      ) : (
        <EmptyState title="No Training Completed" description="When you complete training, it will show up here." />
      )}
      {modal}
    </>
  );
}
