import { useState, useEffect, useRef } from 'react';
import { scroller } from 'react-scroll';
import { addRemovableEventListener, requestFullscreen } from '../../utils/domUtils';
import useDebounceCallback from '../../hooks/useDebounceCallback';
import { MediaItem } from '../models/timeline';

const isIOS = () => {
  return /iPad|iPhone|iPod/.test(window.navigator.userAgent);
};

export const useMediaViewerControls = (
  mediaItems: MediaItem[],
  startingMediaIndex: number,
  handleDismiss: () => void
) => {
  const ref = useRef<HTMLDivElement>(null);
  const idleDebounce = useDebounceCallback(3000);
  const [data, setData] = useState(() => ({
    isLoading: true,
    position: startingMediaIndex,
    hideControls: false,
    videoRefreshKey: '',
  }));

  const { position, hideControls } = data;
  const media = mediaItems[position];
  const isVideo = media._type === 'video';
  const decrementLimit = 0;
  const incrementLimit = mediaItems.length - 1;

  const setIsLoading = (value: boolean) => setData(prev => ({ ...prev, isLoading: value }));

  const updatePosition = (limit: number, amount: number) =>
    setData(prev => {
      if (prev.position === limit) return prev;

      return {
        ...prev,
        isLoading: true,
        position: prev.position + amount,
      };
    });

  const decrement = () => updatePosition(decrementLimit, -1);
  const increment = () => updatePosition(incrementLimit, 1);

  const onMouseMove = () => {
    if (hideControls) return setData(prev => ({ ...prev, hideControls: false }));
    idleDebounce(() => setData(prev => ({ ...prev, hideControls: true })));
  };

  const onVideoEnd = () => {
    // in iOS, after the video is complete, we need to show the navigation controls and
    // change the key of the video element to force it to re-initialize in order to play again.
    if (isIOS()) {
      // video-js has necessary cleanup logic that follows the triggering of the 'fullscreenchange'
      // event, this timeout allows that code run before we re-initialize the player
      setTimeout(() => {
        setData(prev => ({ ...prev, hideControls: false, videoRefreshKey: Date.now().toString() }));
      }, 100);
    }
  };

  useEffect(() => {
    requestFullscreen(ref.current);
    idleDebounce(() => setData(prev => ({ ...prev, hideControls: true })));

    ref?.current?.addEventListener('fullscreenchange', () => !document.fullscreenElement && handleDismiss());

    return addRemovableEventListener(document, 'keydown', (e: KeyboardEvent) => {
      if (e.key === 'ArrowLeft') return decrement();
      if (e.key === 'ArrowRight') return increment();
    });
  }, []);

  useEffect(() => {
    const clearListener = addRemovableEventListener(ref.current, 'mousemove', onMouseMove);

    return () => {
      idleDebounce(null);
      clearListener();
    };
  }, [hideControls]);

  useEffect(() => {
    scroller.scrollTo(`contents-${mediaItems[position].segmentId}`, {
      smooth: true,
      duration: 500,
      containerId: 'session-timeline-container',
    });
  }, [position]);

  return {
    ...data,
    decrement,
    increment,
    decrementLimit,
    incrementLimit,
    setIsLoading,
    media,
    ref,
    onVideoEnd,
    isVideo,
  };
};
