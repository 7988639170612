import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { format } from 'date-fns';
import { Grid, Border, Breakpoints, Color, Shadow, Type } from '../StyleGuide';
import { SmallRoundedIconButton } from '../components/Buttons';
import { TrainingPoster } from '../components/TrainingElements';
import TrainingPosterPlaceholder from '../training/TrainingPosterPlaceholder';
import ProgressBarContainer from '../components/ProgressBarContainer';
import ProgressBar from '../components/ProgressBar';
import { IconBadge } from '../components/Badge';
import capitalizeFilter from '../filters/capitalize-filter';
import pluralFilter from '../filters/plural-filter';
import WhiteLabel from '../filters/WhiteLabel';

const DetailsList = styled.ul`
  margin: 0 ${Grid._4} ${Grid._5} 0;
`;

const ProgressList = styled.ul`
  margin: 0 ${Grid._4} ${Grid._3} 0;
  li {
    display: inline-block;
    &:not(:last-of-type)::after {
      content: '•';
      margin: 0 ${Grid._2};
    }
  }
`;

const AssignmentCardContainer = styled.div`
  display: flex;
  padding: ${Grid._4} ${Grid._4} ${Grid._5} ${Grid._4};
  box-shadow: ${Shadow.small};
  border: 1px solid rgba(0, 0, 0, 0.075);
  border-radius: ${Border.radius};
  background: white;
  h4 {
    line-height: ${Type.Leading.normal};
    margin: 0 ${Grid._4} 0 0;
    font-weight: ${Type.Weight.bold};
    word-break: break-word;
    a {
      color: ${Color.Gray._90};
      display: block;
    }
    a:hover {
      text-decoration: underline;
    }
    @media screen and (min-width: ${Breakpoints.screen_sm}) {
      line-height: ${Type.Leading.tall};
    }
  }
  ul {
    list-style: none;
    padding: 0;
  }
  li {
    color: ${Color.Gray._70};
  }
`;

const AssignmentDetailsContainer = styled.div`
  flex: auto;
`;

const UnassignTrainingButton = styled(SmallRoundedIconButton)`
  align-self: start;
  margin-top: -${Grid._3};
  margin-right: -${Grid._3};
  i {
    background-color: ${Color.Gray._10};
  }
  &:hover i {
    background: ${Color.Gray._20};
  }
`;

const PosterContainer = styled.div`
  display: none;

  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    display: flex;
    min-width: 300px;
    padding-right: ${Grid._5};
    text-align: center;
    align-items: start;
  }
`;

const TinyInlineIconBadge = styled(IconBadge)`
  display: inline-flex;
  height: ${Grid._5};
  width: ${Grid._5};
  font-size: ${Type.Scale._2};
  margin-right: 0;
`;

const formatDate = date => format(new Date(date), 'MMM d, yyyy');

const PathwayIcon = () => (
  <TinyInlineIconBadge>
    <i className="fas fa-route"></i>
  </TinyInlineIconBadge>
);

const AssignmentDetails = ({ user, assignmentType, assignment }) => {
  const progresses = user.isPending
    ? []
    : assignment._type === 'course'
    ? [assignment.progress]
    : assignment.assignable.tasklists.map(tl => tl.progress);
  const assignmentLabel = capitalizeFilter(
    assignmentType === 'course' ? WhiteLabel.labels.course : WhiteLabel.labels.pathway
  );
  const hasSteps =
    (assignment.progress && assignment.progress.steps) || (assignment.assignable && assignment.assignable.tasklists);
  const stepsTotal = hasSteps && (assignmentType === 'course' ? progresses[0].steps.total : progresses.length);
  const stepsCompleted =
    hasSteps &&
    (assignmentType === 'course'
      ? progresses[0].steps.completed
      : progresses.filter(p => p._type === 'Finished').length);
  const stepType = assignmentType === 'course' ? WhiteLabel.labels.step : WhiteLabel.labels.course;
  const stepLabel = hasSteps && (stepsTotal > 1 ? pluralFilter(stepType) : stepType);
  const dueDate = assignment.dueDate && formatDate(assignment.dueDate);
  const isComplete = assignment.completedOn;
  const completedOn = isComplete && formatDate(assignment.completedOn);
  const isWorkflow = assignmentType === 'workflow';
  const isSelfAssigned = assignment.assignable && assignment.assignable.selfAssigned;
  const isShared = assignment.shared;
  const sharedOn = assignment.sharedOn && formatDate(assignment.sharedOn);
  const hasStarted = assignment.progress && assignment.progress._type !== 'NotStarted';
  const assignedOn = assignment.assignedOn && formatDate(assignment.assignedOn);

  return (
    <AssignmentDetailsContainer>
      <h4>
        {user.isPending ? (
          <div data-qa-hook="assignedTaskListPanelName">{assignment.trainingName}</div>
        ) : (
          <a data-qa-hook="assignedTaskListPanelName" href={`#/manage-assignments/${assignment._id}`}>
            {assignment.trainingName}
          </a>
        )}
      </h4>
      <DetailsList>
        {isShared ? (
          <li>Shared on {sharedOn}</li>
        ) : (
          (assignment.assignor || assignedOn) && (
            <li>
              {isSelfAssigned ? 'Self-assigned' : 'Assigned'}{' '}
              {!isSelfAssigned && (assignment.assignor ? `by ${assignment.assignor}` : '')}{' '}
              {assignedOn ? `on ${assignedOn}` : ''}
            </li>
          )
        )}
        {dueDate && <li data-qa-hook="assignedTaskListPanelDueDate">Finish by {dueDate}</li>}
      </DetailsList>
      <ProgressList>
        {user.isPending ? (
          <li>Will be assigned upon login</li>
        ) : isComplete ? (
          <>
            <li>
              {assignmentType === 'workflow' && <PathwayIcon />} {assignmentLabel}
            </li>
            <li>Completed on {formatDate(completedOn)}</li>
          </>
        ) : (
          <>
            <li>
              {assignmentType === 'workflow' && <PathwayIcon />} {assignmentLabel}
            </li>
            {hasSteps && (
              <li>
                {stepsCompleted} of {stepsTotal} {stepLabel} complete
              </li>
            )}
          </>
        )}
      </ProgressList>

      <ProgressBarContainer>
        {progresses.map((e, i) => (
          <ProgressBar
            key={`${assignment._id}_${i}`}
            isPathway={isWorkflow}
            progress={e.percent}
            className={isWorkflow ? 'wf-progress-bar' : 'tl-progress-bar'}
          />
        ))}
      </ProgressBarContainer>
    </AssignmentDetailsContainer>
  );
};

AssignmentDetails.propTypes = {
  user: PropTypes.object.isRequired,
  assignment: PropTypes.object.isRequired,
  assignmentType: PropTypes.string.isRequired,
};

const AssignmentPoster = () => {
  return (
    <PosterContainer>
      {/* TODO: add training poster and make it wrap to top of card on small screens */}
      <TrainingPosterPlaceholder />
    </PosterContainer>
  );
};

export default function AssignmentCard({ user, assignment, unassignTraining }) {
  const assignmentType = assignment._type;
  return (
    <AssignmentCardContainer>
      <AssignmentPoster />
      <AssignmentDetails user={user} assignmentType={assignmentType} assignment={assignment} />
      <UnassignTrainingButton data-qa-hook="assignmentRemoveTraining" onClick={() => unassignTraining(assignment)}>
        <i className="far fa-trash-alt"></i>
      </UnassignTrainingButton>
    </AssignmentCardContainer>
  );
}

AssignmentCard.propTypes = {
  user: PropTypes.object.isRequired,
  assignment: PropTypes.object.isRequired,
  unassignTraining: PropTypes.func.isRequired,
};
