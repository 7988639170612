import { Form, Formik, FormikHelpers } from 'formik';
import React, { useRef } from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
// @ts-ignore
import { EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { stateFromHTML } from 'draft-js-import-html';
import { Grid } from '../StyleGuide';
import Breadcrumbs from '../components/Breadcrumbs';
import { PrimaryButton } from '../components/Buttons';
import ErrorMessage from '../components/ErrorMessage';
import {
  FormField,
  FormFieldContainer,
  FormFieldMaxCharCountStatus,
  FormFieldSet,
  FormGroupContainer,
  Label,
  Radio,
} from '../components/FormElements';
import { Container, PageTitle } from '../components/Layout';
import RichTextEditor from '../containers/RichTextEditor';
import { PreviewEmailTemplate } from '../email';
import alertService from '../services/AlertService';
import windowService from '../services/windowService';
import { handleError } from '../utils/apiUtils';
import { ContentVariableFormField } from './components/ContentVariableFormField';
import { EmailFormValues, usePathwayEmail } from './hooks/usePathwayEmail';

const PageContainer = styled(Container)`
  margin: ${Grid._6} auto ${Grid._10} auto;
`;

const SectionsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${Grid._7};
  margin-top: ${Grid._7};
`;

const SectionContainer = styled(FormFieldSet)`
  flex: 1;
  min-width: 280px;
  height: 100%;

  input[name='buttonText'] {
    width: 100%;
    max-width: 320px;
  }
`;

const Preview = styled.div`
  h5 {
    margin: 0 0 ${Grid._2} 0;
  }

  div {
    margin-top: ${Grid._6};
  }
`;

export const ManagePathwayEmail = () => {
  const bodyRef = useRef<any>(null);
  const { crumbs, initialFormValues, saveEmail, isEditing, isError } = usePathwayEmail();

  const submit = (values: EmailFormValues, helpers: FormikHelpers<EmailFormValues>) => {
    const { subject, body, buttonText, showButton } = values;
    const { setSubmitting } = helpers;

    saveEmail(subject, body, showButton ? buttonText : '')
      .then(redirectLocation => {
        alertService.showOnNextPage(`Email ${isEditing ? 'Saved' : 'Added'}`);
        windowService.redirectTo(redirectLocation);
      })
      .catch(error => {
        handleError(error);
        setSubmitting(false);
      });
  };

  if (isError) {
    return <ErrorMessage />;
  }

  return (
    <>
      <Breadcrumbs crumbs={[...crumbs, { name: `${isEditing ? 'Edit' : 'Add'} Email` }]} />

      <PageContainer>
        <PageTitle>
          <h1>{isEditing ? 'Edit' : 'Add'} Email Notification</h1>
          <p>Create an email to be sent when the user reaches this point in the pathway</p>
        </PageTitle>
        <Formik
          initialValues={initialFormValues}
          initialStatus={{ editorState: EditorState.createWithContent(stateFromHTML(initialFormValues.body)) }}
          enableReinitialize={true}
          validationSchema={Yup.object({
            subject: Yup.string().required('Subject is required'),
          })}
          onSubmit={submit}
        >
          {({ values, status, setFieldValue, setStatus, isSubmitting }) => (
            <Form>
              <SectionsContainer>
                <SectionContainer name="Email Contents">
                  <FormFieldMaxCharCountStatus
                    label="Subject"
                    id="subject"
                    name="subject"
                    placeholder="Enter Subject"
                    maxLength={64}
                  />

                  <FormFieldContainer>
                    <Label>Message (Optional)</Label>
                    <RichTextEditor
                      ref={bodyRef}
                      editorState={status.editorState}
                      setEditorState={(es: EditorState) => {
                        setStatus({ editorState: es });
                        setFieldValue('body', stateToHTML(es.getCurrentContent()));
                      }}
                      config={{
                        placeholder: 'Enter Message',
                        height: '200px',
                      }}
                    />
                    <ContentVariableFormField />
                  </FormFieldContainer>

                  <FormFieldContainer>
                    <Label>Include Action Button</Label>
                    <FormGroupContainer>
                      <p>Add an actionable button to the end of the email</p>
                      <div className="controls">
                        <Radio
                          id="action-button-yes"
                          name="showButton"
                          onChange={() => setFieldValue('showButton', true)}
                          checked={values.showButton}
                        />
                        <label htmlFor="action-button-yes">Yes</label>
                        <Radio
                          id="action-button-no"
                          name="showButton"
                          onChange={() => setFieldValue('showButton', false)}
                          checked={!values.showButton}
                        />
                        <label htmlFor="action-button-no">No</label>
                      </div>
                    </FormGroupContainer>

                    <FormField name="buttonText" placeholder="Enter Button Text" disabled={!values.showButton} />
                  </FormFieldContainer>

                  <PrimaryButton type="submit" disabled={isSubmitting} operating={isSubmitting}>
                    {`${isEditing ? 'Save' : 'Add'} Email`}
                  </PrimaryButton>
                </SectionContainer>

                <SectionContainer name="Email Preview">
                  <Preview>
                    <h5>{values.subject}</h5>
                    <PreviewEmailTemplate
                      templateValues={{
                        bodyText: values.body,
                        buttonText: values.buttonText ?? '',
                      }}
                      hideButton={!values.showButton}
                    />
                  </Preview>
                </SectionContainer>
              </SectionsContainer>
            </Form>
          )}
        </Formik>
      </PageContainer>
    </>
  );
};
