import { useParams } from 'react-router-dom';
import useManageTrainingRoute from '../../hooks/useManageTrainingRoute';
import useQueryParams from '../../hooks/useQueryParams';
import windowService from '../../services/windowService';
import { sessionStorage } from '../../utils/storageUtils';
import { useCourseBuilder } from './useCourseBuilder';
import { UrlParams } from '../models/url';
import { CourseStep } from '../models/course';

export const useCourseBuilderNavigation = () => {
  const { manageTrainingRoutePrefix } = useManageTrainingRoute();
  const { buildType, trainingId } = useParams<UrlParams>();

  const { course } = useCourseBuilder();
  const { workflowId } = useQueryParams({ preserveParams: true }) || {};

  const storedWorkflow = sessionStorage.getItem('pathway-builder_pathway');
  const isCurrentWorkflow = storedWorkflow?.id === workflowId;
  const workflowName = isCurrentWorkflow && storedWorkflow?.name;

  const workflowCrumbs = [
    { name: 'Organization', route: '#/org' },
    { name: 'Manage Pathways', route: `#${manageTrainingRoutePrefix}/pathways` },
    {
      name: workflowName || 'Edit Pathway',
      route: `#${manageTrainingRoutePrefix}/pathways/${workflowId}`,
    },
    { name: course?.name || '' },
  ];

  const courseCrumbs = [
    { name: 'Organization', route: '#/org' },
    { name: 'Manage Courses', route: `#${manageTrainingRoutePrefix}/courses` },
    { name: course?.name || '' },
  ];

  const crumbs = workflowId ? workflowCrumbs : courseCrumbs;

  const redirectToSettings = () => {
    windowService.redirectTo(
      `${manageTrainingRoutePrefix}/courses/${trainingId}/settings${workflowId ? `?workflowId=${workflowId}` : ''}`
    );
  };

  const redirectToStep = (step: CourseStep) => {
    windowService.redirectTo(
      `#/${buildType}/courses/${trainingId}/tasks/${step._id.id}?taskType=${step._type}${
        workflowId ? `&workflowId=${workflowId}` : ''
      }`
    );
  };

  return {
    crumbs,
    redirectToSettings,
    redirectToStep,
  };
};
