import { AxiosError } from 'axios';
import { useEffect, useState } from 'react';
import { getInvite } from '../api/getInvites';
import { Invite } from '../models/invite';
import { getInviteSettings } from '../api/getInviteSettings';
import { InviteSettings } from '../models/inviteSettings';

type State<T> = {
  isLoading: boolean;
  invite?: T;
  inviteSettings?: InviteSettings;
  error?: AxiosError;
};

export function useInvite<T = Invite>(inviteId: string, orgId: string, fetchInviteSettings: boolean = true) {
  const [data, setData] = useState<State<T>>({
    isLoading: true,
  });

  useEffect(() => {
    if (!inviteId) {
      return setData({ isLoading: false, error: new AxiosError('Invite ID not provided') });
    }

    getInvite(orgId, inviteId)
      .then((invite: Invite) => {
        if (!fetchInviteSettings || !invite.workflowId) return { invite };
        return getInviteSettings(invite.workflowId).then(inviteSettings => ({ invite, inviteSettings }));
      })
      .then(({ invite, inviteSettings }: { invite: Invite; inviteSettings?: InviteSettings }) =>
        setData({ invite: invite as T, inviteSettings, isLoading: false })
      )
      .catch(error => {
        console.error(error);
        setData({ isLoading: false, error });
      });
  }, []);

  return {
    ...data,
  };
}
