import cacheableRequest from '../../services/cacheableRequest';
import { Pathway, transformPathway } from '../models/pathway';

type GetPathwayResponse = {
  data: Pathway & { _id: string; itemNumber?: string };
};

export const getPathway = (pathwayId: string) => {
  return cacheableRequest(`/v1/workflows/${pathwayId}`).then((response: GetPathwayResponse) =>
    transformPathway(response.data)
  );
};
