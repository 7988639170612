import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Breakpoints, Grid, Type } from '../StyleGuide';
import { useUser } from '../authentication';
import { PrimaryButton } from '../components/Buttons';
import ConfirmationModal from '../components/ConfirmationModal';
import EasyLinkModal from '../components/EasyLinkModal';
import ErrorMessage from '../components/ErrorMessage';
import GroupMembersList from '../components/GroupMembersList';
import {
  CollectionImgContainer,
  CollectionImgPlaceholder,
  Container,
  FullWidthCard,
  Image,
} from '../components/Layout';
import LoadingState from '../components/LoadingState';
import { PreviewRestrictionModal } from '../components/PreviewRestrictionModal';
import SectionHeader from '../components/SectionHeader';
import useCurriculum from '../hooks/useCurriculum';
import useModal from '../hooks/useModal';
import alertService from '../services/AlertService';
import curriculumService from '../services/curriculumService';
import { groupService } from '../services/groupService';
import { handleError } from '../utils/apiUtils';
import BibleStudyNavigation from './components/BibleStudyNavigation';

const CollectionContainer = styled.div`
  padding: ${Grid._5} 0;
`;

const CollectionInfo = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${Grid._5} 0;
  color: ${Type.Color.medium};
  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    margin: 0 ${Grid._5};
  }
  h3,
  .button-group-label {
    margin-top: 0;
    font-weight: ${Type.Weight.semibold};
    color: ${Type.Color.dark};
  }
`;

const AddPeopleButton = styled(PrimaryButton)`
  padding: ${Grid._4};
`;

const NoPeople = () => (
  <div className="empty-state-ui">
    <h4>No People Added</h4>
    <p>Use an Easy Link or Email an invitation to give people access to this collection</p>
  </div>
);

export default function ManageBibleStudyCollection() {
  const user = useUser();
  const [data, setData] = useState({ isLoading: true });
  const { brand, ageCategory } = useCurriculum();
  const { curriculumId, collectionId } = useParams();

  const orgId = user.lastSelectedAccount;
  const [crumbs, setCrumbs] = useState([
    { name: 'Curriculum', route: '#/bible-studies' },
    {
      name: `${brand.name}: ${ageCategory}`,
      route: `#/manage-bible-study/people/${brand.code}/${ageCategory}/${curriculumId}`,
    },
  ]);

  const interceptRestriction = fn =>
    brand.previewOptions?.restrictions.distribute ? openModal('previewRestriction') : fn();

  const [modal, openModal, dismissModal] = useModal((type, payload, dismissModal) => {
    if (type === 'easyLink')
      return (
        <EasyLinkModal
          link={{ orgPrefix: data.group.groupLink.orgPrefix, groupCode: data.group.groupLink.groupCode }}
          description={`<h4>Anyone who uses this link will automatically have the <b>${brand.name}: ${ageCategory} &dash; ${data.collection.name}</b> collection on their dashboard when they log in to Ministry Grid. They will be able to access current, past, and upcoming scheduled sessions in this curriculum.</h4>`}
          handleDismiss={dismissModal}
        />
      );

    if (type === 'removePerson') {
      const { person } = payload;
      return (
        <ConfirmationModal
          buttonActionText="Remove"
          buttonType="danger"
          title="Remove Person"
          prompt={
            <span>
              Are you sure you want to remove this person from the <b>{data.collection.name}</b> collection?
            </span>
          }
          subtext={person.displayName}
          handleSubmit={() => removePerson(data.groupId, person)}
          handleDismiss={dismissModal}
        >
          <p>
            <b>{person.emailAddress ?? person.email}</b>
          </p>
        </ConfirmationModal>
      );
    }

    if (type === 'previewRestriction') return <PreviewRestrictionModal handleDismiss={dismissModal} />;
  });

  useEffect(() => {
    curriculumService
      .getIndividualCollection(curriculumId, collectionId)
      .then(({ collection, group }) => {
        setData(prevData => ({ ...prevData, collection, groupId: group.groupId.id }));
        setCrumbs([...crumbs, { name: collection.name }]);
        return groupService.getSingleGroup(group.groupId.id, orgId);
      })
      .then(group => {
        setData(prevData => ({
          ...prevData,
          group,
          numPeople: group.people.length,
          isLoading: false,
        }));
      })
      .catch(reason => {
        setData({ isLoading: false, isError: true });
        console.error(reason);
      });
  }, []);

  const redirectToAddPeople = () => {
    window.location = `#/manage-bible-study/people/collection/add-people/${brand.code}/${ageCategory}/${curriculumId}/${collectionId}`;
  };

  const removePerson = (groupId, personToUpdate) => {
    const personToUpdateId = personToUpdate.emailAddress ?? personToUpdate.userId.id;

    const isPerson = (person, id) => person.emailAddress === id || person.userId?.id === id;

    const removePersonFromList = (people, id) => people.filter(person => !isPerson(person, id));
    groupService
      .removePerson(groupId, orgId, personToUpdate, user.userId)
      .then(() => {
        setData(prevData => ({
          ...prevData,
          numPeople: prevData.numPeople - 1,
          group: { people: removePersonFromList(prevData.group.people, personToUpdateId) },
        }));
        alertService.show('Person Removed');
      })
      .catch(handleError)
      .finally(dismissModal);
  };

  return (
    <>
      <BibleStudyNavigation isLoading={data.isLoading} crumbs={crumbs} curriculumId={curriculumId} activeTab="people" />
      <FullWidthCard>
        <Container data-qa-hook="collectionViewContainer">
          {data.isLoading ? (
            <LoadingState />
          ) : data.isError ? (
            <ErrorMessage>
              A problem occurred showing this page. Please refresh the page to try again.{' '}
              <a href="#/help">Contact Us</a>
            </ErrorMessage>
          ) : (
            <>
              <CollectionContainer className="grid-container grid-col-1 grid-sm-col-2">
                <CollectionImgContainer>
                  {data.collection.image ? (
                    <Image src={data.collection.image} alt={`${brand.name}: ${data.collection.name}`} />
                  ) : (
                    <CollectionImgPlaceholder />
                  )}
                </CollectionImgContainer>
                <CollectionInfo>
                  <h3 data-qa-hook="collectionName">{data.collection.name}</h3>
                  <p>
                    Share access to this curriculum with your team, giving them the ability to prepare and teach from
                    their own devices. Sessions will automatically appear on their personal dashboard upon login.
                  </p>
                  <p className="button-group-label">Add People By:</p>
                  <div className="grid-container grid-col-2 grid-gap-16">
                    <AddPeopleButton onClick={() => interceptRestriction(() => openModal('easyLink'))}>
                      <i className="fas fa-link"></i> Easy Link
                    </AddPeopleButton>
                    <AddPeopleButton onClick={() => interceptRestriction(redirectToAddPeople)}>
                      <i className="fas fa-paper-plane"></i> Email
                    </AddPeopleButton>
                  </div>
                </CollectionInfo>
              </CollectionContainer>
              <CollectionContainer>
                <SectionHeader title={`${data.numPeople} ${data.numPeople === 1 ? 'Person' : 'People'}`} margin="0" />
                {data.group.people.length ? (
                  <GroupMembersList
                    data-qa-hook="collectionMembersContainer"
                    userEmail={user.logInEmail}
                    members={data.group.people}
                    removePerson={person => openModal('removePerson', { person })}
                  />
                ) : (
                  <NoPeople />
                )}
              </CollectionContainer>
            </>
          )}
        </Container>
      </FullWidthCard>
      {modal}
    </>
  );
}
