import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AccessDenied from '../components/AccessDenied';
import { ErrorMessage } from '../components/AlertMessages';
import Breadcrumbs from '../components/Breadcrumbs';
import HelpAndInfoBox from '../components/HelpAndInfoBox';
import { Container } from '../components/Layout';
import LoadingState from '../components/LoadingState';
import SettingsHeader from '../components/SettingsHeader';
import GroupContainer from '../groups/GroupContainer';
import Groups from '../groups/Groups';
import People from './People';
import useOrg from '../hooks/useOrg';
import { useUser } from '../authentication';
import orgService from '../services/orgService';
import useTextFilter from '../hooks/useTextFilter';
import windowService from '../services/windowService';

const Tabs = ({ activeTab, setActiveTab, showGroups, showPeople }) => {
  return (
    <ul className="group-page-tabs">
      {showPeople && (
        <li className={activeTab === 'people' ? 'active-tab' : ''}>
          <button
            data-qa-hook="peopleTab"
            onClick={() => {
              setActiveTab('people');
              windowService.redirectTo(`/people`);
            }}
          >
            <span className="fas fa-user-friends fa-fw"></span> People
          </button>
        </li>
      )}

      {showGroups && (
        <li className={activeTab === 'groups' ? 'active-tab' : ''}>
          <button
            data-qa-hook="groupsTab"
            onClick={() => {
              setActiveTab('groups');
              windowService.redirectTo(`/groups`);
            }}
          >
            <span className="fas fa-cog fa-fw"></span> Groups
          </button>
        </li>
      )}
    </ul>
  );
};

Tabs.propTypes = {
  activeTab: PropTypes.string,
  setActiveTab: PropTypes.func,
  showPeople: PropTypes.bool,
  showGroups: PropTypes.bool,
};

export const ManagePeople = () => {
  const user = useUser();
  const orgId = user.lastSelectedAccount;
  const org = useOrg();
  const [activeTab, setActiveTab] = useState(windowService.getRoutePart(1));
  const canManagePeople = user.permissions.includes('Roles View');
  const canManageGroups = user.permissions.includes('Groups View');
  const isGroupLeader = user.permissions.includes('Group Leader');

  const [data, setData] = useState({
    people: [],
    isLoading: true,
    isError: false,
    crumbs: [{ name: 'Organization', route: '#/org' }, { name: activeTab === 'people' ? 'People' : 'Groups' }],
  });
  const { people, crumbs, isLoading, isError } = data;

  const { results, query, setQuery } = useTextFilter(people, [
    'displayName',
    'emailAddress',
    'firstName',
    'lastName',
    'email',
  ]);

  useEffect(() => {
    const getOrg = orgService.getOrgUsers(orgId);
    const getRoles = orgService.getRolesOverview(orgId, true);

    Promise.all([getOrg, getRoles])
      .then(response => {
        setData(prevState => ({
          ...prevState,
          roles: response[1],
          people: response[0].users,
          groups: response[0].groups,
          isLoading: false,
        }));
      })
      .catch(() => setData({ isError: true, isLoading: false }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {(canManagePeople || canManageGroups) && <Breadcrumbs crumbs={crumbs} />}
      <GroupContainer>
        <HelpAndInfoBox
          title={'Did You Know?'}
          description={`View everyone in ${org?.name} below. Choose a person to view their profile.`}
        />

        <Container>
          {!isLoading && (
            <SettingsHeader
              data-qa-hook="SettingsHeader"
              title={org?.name}
              subtitle="People"
              icon={<i className="fas fa-user-friends fa-fw" />}
            />
          )}
        </Container>
      </GroupContainer>
      {canManagePeople || canManageGroups || isGroupLeader ? (
        <GroupContainer>
          {isLoading ? (
            <LoadingState />
          ) : isError ? (
            <ErrorMessage>
              A problem occurred showing this page. Please refresh the page to try again.{' '}
              <a href="#/help">Contact Us</a>
            </ErrorMessage>
          ) : (
            <GroupContainer>
              <Tabs
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                showGroups={canManageGroups || isGroupLeader}
                showPeople={canManagePeople}
              />
              {activeTab === 'people' && (
                <section>
                  <People
                    user={user}
                    filteredMembers={results}
                    unfilteredMembers={people}
                    setPeople={setData}
                    query={query}
                    setQuery={setQuery}
                    filters={{
                      roles: { displayName: 'Roles', items: data.roles },
                      groups: { displayName: 'Groups', items: data.groups },
                    }}
                  />
                </section>
              )}
              {activeTab === 'groups' && (
                <section>
                  <Groups user={user} />
                </section>
              )}
            </GroupContainer>
          )}
        </GroupContainer>
      ) : (
        <AccessDenied />
      )}
    </>
  );
};
