import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { isSunday, nextSunday, subDays, isAfter, format, differenceInCalendarDays } from 'date-fns';
import { Breakpoints, Grid, Color, Type, Border, Transition } from '../StyleGuide';
import { Container, Image, InlineList, BulletedList } from '../components/Layout';
import curriculumService from '../services/curriculumService';
import LoadingState from '../components/LoadingState';
import ErrorMessage from '../components/ErrorMessage';
import BreadcrumbsSubMenu from '../components/BreadcrumbsSubMenu';
import { PrimaryButton } from '../components/Buttons';
import { Steps } from '../components/FormElements';
import Carousel from 'react-elastic-carousel';
import { carouselControls } from '../components/CarouselElements';
import DatePicker from 'react-datepicker';
import CalendarDateIcon from '../components/CalendarDateIcon';
import ResetScheduleConfirmationModal from '../curriculum/ResetScheduleConfirmationModal';
import BibleStudyHeaderImg from './components/BibleStudyHeaderImg';
import BibleStudyHeader from './components/BibleStudyHeader';
import HelpAndInfoBox from '../components/HelpAndInfoBox';
import alertService from '../services/AlertService';
import windowService from '../services/windowService';
import { useUser } from '../authentication';
import useCurriculum from '../hooks/useCurriculum';

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: ${Grid._5} auto;
  h1 {
    font-size: ${Type.Scale._4};
    font-weight: ${Type.Weight.semibold};
    color: ${Type.Color.dark};
    margin: 0;
  }
`;

const HelpBoxContainer = styled.div`
  @media screen and (min-width: ${Breakpoints.screen_md}) {
    > div {
      margin: 0;
    }
  }
`;

const VolumePoster = styled(Image)`
  cursor: pointer;
  border: ${Grid._1} solid transparent;
  transition: ${Transition.normal};
  &.isSelected {
    border-color: ${Color.Secondary._50};
  }
`;

const Details = styled.div`
  h3 {
    margin: 0 auto ${Grid._3};
    color: ${Type.Color.dark};
  }
  h4 {
    color: ${Type.Color.mediumDark};
  }
`;

const SessionsList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  li {
    color: ${Type.Color.dark};
    display: flex;
    align-items: center;
    padding: ${Grid._3} ${Grid._5};
    border-top: 1px solid ${Color.Blue._15};
    &:last-child {
      border-bottom: 1px solid ${Color.Blue._15};
    }
    div:nth-child(1) {
      flex: 1;
      p {
        color: ${Type.Color.medium};
      }
    }
    div:nth-child(2) {
      text-align: center;
      font-size: ${Type.Scale._5};
      color: ${Type.Color.medium};
    }
    &:hover {
      background: ${Color.white};
      cursor: pointer;
      h4 {
        text-decoration: underline;
      }
    }
    &.isSelected {
      background: white;
      div:nth-child(2) {
        color: ${Color.Primary._50};
      }
      @media screen and (min-width: ${Breakpoints.screen_sm}) {
        position: relative;
        z-index: 1;
        margin-right: -1px;
      }
    }
  }
`;

const StepTwoContent = styled.div`
  color: ${Type.Color.dark};
  border-radius: ${Border.radius};
  padding: ${Grid._5};
  &.active {
    background: ${Color.white};
    border: 1px solid ${Color.Blue._15};
    @media screen and (min-width: ${Breakpoints.screen_sm}) {
      margin-left: -${Grid._5};
    }
  }
`;

const DateContainer = styled.div`
  margin-right: ${Grid._4};
`;

const getMinScheduleDate = useDate => {
  const today = new Date();
  const minUseDate = subDays(new Date(useDate), 28);
  return isAfter(minUseDate, today) ? minUseDate : today;
};

const getNextAvailableSunday = useDate => {
  const minDate = getMinScheduleDate(useDate);
  return isSunday(minDate) ? minDate : nextSunday(minDate);
};

export default function ManageBibleStudyResetSchedule() {
  const user = useUser();
  const [data, setData] = useState({ curriculum: {}, isLoading: true });
  const [activeTab, setActiveTab] = useState('step-one');
  const [selectedVolume, setSelectedVolume] = useState();
  const [selectedSession, setSelectedSession] = useState();
  const [scheduleStartDate, setScheduleStartDate] = useState();
  const [showModal, setShowModal] = useState(false);

  const { brand, ageCategory } = useCurriculum();
  const { curriculumId } = useParams();
  const crumbs = [{ name: 'Curriculum', route: '#/bible-studies' }, { name: `${brand.name}: ${ageCategory}` }];
  const curriculumScheduleRoute = `#/manage-bible-study/schedule/${brand.code}/${ageCategory}/${curriculumId}`;

  const breakPoints = [
    { width: 1, itemsToShow: 2, itemsToScroll: 1 },
    { width: 577, itemsToShow: 3, itemsToScroll: 1 },
    { width: 767, itemsToShow: 4, itemsToScroll: 1 },
    { width: 992, itemsToShow: 5, itemsToScroll: 1 },
  ];
  useEffect(() => {
    curriculumService
      .getAvailableVolumes(curriculumId)
      .then(volumes => setData({ volumes: volumes, isLoading: false }))
      .catch(reason => {
        console.error(reason);
        setData({ isLoading: false, isError: true });
      });
  }, []);

  const selectVolume = volume => {
    if (!isSelectedVolume(volume)) {
      setActiveTab('step-one');
      setSelectedVolume(volume);
      setSelectedSession(null);
      setScheduleStartDate(null);
    }
  };

  const selectSession = session => {
    if (!isSelectedSession(session)) {
      setActiveTab('step-two');
      setSelectedSession(session);
      setScheduleStartDate(getNextAvailableSunday(session.useDate));
    }
  };

  const isSelectedVolume = volume => selectedVolume && volume._id === selectedVolume._id;
  const isSelectedSession = session => selectedSession && session.sessionId === selectedSession.sessionId;

  const resetSchedule = () =>
    curriculumService
      .resetSchedule(
        curriculumId,
        differenceInCalendarDays(scheduleStartDate, new Date(selectedSession.useDate)),
        user.userId
      )
      .then(() => {
        alertService.showOnNextPage('Curriculum Schedule Reset');
        windowService.redirectTo(curriculumScheduleRoute);
      })
      .catch(reason => {
        console.error(reason);
        alertService.show('An error occurred. Please refresh and try again.', 'error');
      })
      .finally(() => {
        setShowModal(false);
      });

  return (
    <>
      <div className="hidden-xs hidden-sm">
        <BreadcrumbsSubMenu crumbs={crumbs} />
      </div>
      <Container data-qa-hook="resetScheduleViewContainer">
        <Header>
          <h1>Reset Curriculum Schedule:</h1>
          <a data-qa-hook="cancel" href={curriculumScheduleRoute}>
            Cancel
          </a>
        </Header>

        {data.isLoading ? (
          <LoadingState />
        ) : data.isError ? (
          <ErrorMessage>
            A problem occurred showing this page. Please refresh the page to try again. <a href="#/help">Contact Us</a>
          </ErrorMessage>
        ) : (
          <>
            <div className="grid-container grid-md-col-2">
              <BibleStudyHeader
                className="grid-sm-col-span-1"
                thumbnail={
                  <BibleStudyHeaderImg src={brand.imgSrc} backgroundColor={brand.background} alt={brand.name} />
                }
                subtitle={brand.name}
                title={ageCategory}
              />
              <HelpBoxContainer>
                <HelpAndInfoBox
                  className="grid-sm-col-span-1"
                  title="Curriculum Schedule"
                  description="Your schedule is a rolling window of previous and upcoming sessions based on the current session."
                  iconClass="fas fa-calendar-week"
                />
              </HelpBoxContainer>
            </div>
            <Steps className="grid-container grid-col-2 grid-gap-16">
              <li className={activeTab === 'step-one' ? 'active-tab' : ''}>
                <button disabled={selectedSession}>
                  <span className="step-number">Step 1</span>Select a Volume and Session
                </button>
              </li>
              <li className={activeTab === 'step-two' ? 'active-tab' : ''}>
                <button disabled={!selectedSession}>
                  <span className="step-number">Step 2</span>Set Schedule Start Date
                </button>
              </li>
            </Steps>
            <section>
              <Carousel
                initialFirstItem={data.volumes.length - 1}
                breakPoints={breakPoints}
                renderArrow={carouselControls}
                pagination={false}
                focusOnSelect={true}
                itemPadding={[0, 8, 0, 8]}
              >
                {data.volumes.map(volume => (
                  <VolumePoster
                    key={volume._id}
                    src={volume.imageUrl}
                    alt={volume.name}
                    onClick={() => selectVolume(volume)}
                    className={isSelectedVolume(volume) ? 'isSelected' : ''}
                  />
                ))}
              </Carousel>
              {selectedVolume && (
                <div
                  className="grid-container grid-col-4 grid-sm-col-8 grid-gap-24"
                  style={{ margin: '24px auto 80px' }}
                >
                  <div className="grid-col-span-4 grid-md-col-span-5">
                    <Details>
                      <h3>{selectedVolume.name}</h3>
                      <InlineList content={'•'} fontSize="18px">
                        {selectedVolume.number && <li>Volume {selectedVolume.number}</li>}
                        {selectedVolume.quarter && (
                          <li>
                            {selectedVolume.quarter.season} {selectedVolume.quarter.year}
                          </li>
                        )}
                      </InlineList>
                      <h4>{selectedVolume.sessions.length} Sessions</h4>
                    </Details>
                    <SessionsList>
                      {selectedVolume.sessions.map(session => (
                        <li
                          key={session.sessionId}
                          className={isSelectedSession(session) ? 'isSelected' : ''}
                          onClick={() => selectSession(session)}
                        >
                          <div>
                            <h4>{session.name}</h4>
                            <p>Suggested Date: {format(new Date(session.useDate), 'MMM d, yyyy')}</p>
                          </div>
                          <div>
                            {isSelectedSession(session) ? (
                              <i className="fas fa-check-circle fa-fw"></i>
                            ) : (
                              <i className="far fa-circle fa-fw"></i>
                            )}
                          </div>
                        </li>
                      ))}
                    </SessionsList>
                  </div>
                  <StepTwoContent
                    className={`grid-col-span-4 grid-md-col-span-3 ${activeTab === 'step-two' ? 'active' : ''}`}
                  >
                    {selectedSession && (
                      <>
                        <h4>Selected:</h4>
                        <div style={{ display: 'flex' }}>
                          {scheduleStartDate && (
                            <DateContainer>
                              <CalendarDateIcon
                                month={format(scheduleStartDate, 'MMM')}
                                day={scheduleStartDate.getDate().toString()}
                                primary
                                large
                              />
                            </DateContainer>
                          )}
                          <Details>
                            <h3>{selectedSession.name}</h3>
                            <InlineList content={'•'} fontSize="18px">
                              <li>{selectedVolume.name}</li>
                              {selectedVolume.number && <li>Volume {selectedVolume.number}</li>}
                            </InlineList>
                          </Details>
                        </div>
                        <h4 style={{ margin: '24px auto' }}>What day do you want to use this session?</h4>
                        <DatePicker
                          data-qa-hook="datePicker"
                          minDate={getMinScheduleDate(selectedSession.useDate)}
                          selected={scheduleStartDate}
                          onChange={date => setScheduleStartDate(date)}
                          inline
                        />
                        <PrimaryButton
                          style={{ width: '100%', margin: '24px auto', padding: '16px' }}
                          onClick={() => setShowModal(true)}
                          disabled={!scheduleStartDate}
                        >
                          Set Curriculum Schedule
                        </PrimaryButton>
                        <h4>What happens next:</h4>
                        <BulletedList>
                          <li>
                            Your curriculum schedule window will be set to the selected volume and session on the new
                            schedule start date
                          </li>
                          <li>
                            Your curriculum schedule window will show previous and upcoming sessions based on your
                            selected volume and session
                          </li>
                        </BulletedList>
                      </>
                    )}
                  </StepTwoContent>
                </div>
              )}
            </section>
            {showModal && (
              <ResetScheduleConfirmationModal
                volumeName={selectedVolume.name}
                volumeNum={selectedVolume.number}
                volumeImg={selectedVolume.imageUrl}
                scheduleStartDate={scheduleStartDate}
                sessionName={selectedSession.name}
                brand={brand.name}
                ageGroup={ageCategory}
                resetSchedule={resetSchedule}
                handleDismiss={() => setShowModal(false)}
              ></ResetScheduleConfirmationModal>
            )}
          </>
        )}
      </Container>
    </>
  );
}
