import submitCommand from '../../services/submitCommand';
import uuid from '../../services/uuid';

export const acceptOrgInvite = (
  userId: string,
  orgId: string,
  forEmail: string,
  redeemingEmail: string,
  initiatingUserId: string
) => {
  const id = uuid.generate();

  const cmd = {
    id,
    userId: {
      id: userId,
    },
    orgId: {
      id: orgId,
    },
    forEmail,
    redeemingEmail,
    initiatingUserId,
  };

  return submitCommand(id, cmd, 'AcceptInvite', 'InviteAccepted', 'AcceptInviteError');
};
