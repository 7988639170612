import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { parseISO, nextSunday, isSameMonth, isSunday } from 'date-fns';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { MediumButton, MediumPrimaryButton } from '../../components/Buttons';
import { ModalContainer, ModalBody, ModalHeader, ModalFooter } from '../../components/ModalElements';
import { FormField, DatePickerField, SelectField } from '../../components/FormElements';
import LoadingState from '../../components/LoadingState';
import curriculumService from '../../services/curriculumService';
import sessionTimelineService from '../../services/sessionTimelineService';
import { handleError } from '../../utils/apiUtils';
import { getDateFromDateTime, formatISODate } from '../../utils/dateUtils';

type FromSession = {
  sessionId: string;
  useDate: string;
};

type FromTimeline = {
  id: string;
  collectionId: string;
};

type Unit = {
  id: string;
  firstUseDate: string;
};

export type CopySessionModalProps = {
  fromSession: FromSession;
  fromTimeline: FromTimeline;
  handleSessionAdded: (sessionId: string, timelineId: string) => void;
  handleDismiss: () => void;
};

type UrlParams = {
  curriculumId: string;
};

type FormValues = {
  name: string;
  date: Date;
  collection: string;
};

type RequestStatus = {
  isLoading: boolean;
  error?: Error;
};

export type CopySessionResponse = {
  data: {
    sessionId: string;
    timelineId: string;
  };
};

type Collection = {
  collection: {
    collectionId: string;
    name: string;
  };
};

interface CollectionsRequest extends RequestStatus {
  collections: Collection[];
}

const useFetchCollections = () => {
  const { curriculumId } = useParams<UrlParams>();

  const [data, setData] = useState<CollectionsRequest>({
    isLoading: true,
    collections: [],
  });

  useEffect(() => {
    curriculumService
      .getCollections(curriculumId)
      .then((collections: Collection[]) => setData({ isLoading: false, collections }))
      .catch((error: Error) => {
        setData({ isLoading: false, error, collections: [] });
      });
  }, []);

  return data;
};

const useCopySessionForm = (fromSession: FromSession, fromTimeline: FromTimeline) => {
  const { curriculumId } = useParams<UrlParams>();

  const getUnitForDate = (date: Date) =>
    curriculumService.getUnits(curriculumId).then(
      (units: Unit[]) =>
        units.find(unit => {
          const unitUseDate = parseISO(getDateFromDateTime(unit.firstUseDate));
          return isSameMonth(unitUseDate, date);
        })?.id
    );

  const handleSubmit = ({ name, date, collection }: FormValues) =>
    getUnitForDate(date).then((unitId: string) => {
      const dateISO = formatISODate(date);
      const { publishStartDate, publishEndDate } = curriculumService.getSessionPublishDates(date);

      return sessionTimelineService.copySessionTimeline({
        curriculumId,
        unitId,
        collectionId: collection,
        sessionId: fromSession.sessionId,
        sessionName: name,
        timelineId: fromTimeline.id,
        useDate: dateISO,
        publishStartDate,
        publishEndDate,
      });
    });

  const initialValues = {
    name: '',
    date: nextSunday(parseISO(fromSession.useDate)),
    collection: fromTimeline.collectionId ?? '',
  };

  const validationSchema = Yup.object({
    name: Yup.string().max(64, 'Name is too long').required('A name is required'),
    date: Yup.date()
      .test('isSunday', 'Use date must be a Sunday', value => isSunday(value as Date))
      .typeError('A use date is required')
      .required('A use date is required'),
    collection: Yup.string().required('A collection is required'),
  });

  return { initialValues, validationSchema, handleSubmit };
};

const CopySessionModal = ({ fromSession, fromTimeline, handleSessionAdded, handleDismiss }: CopySessionModalProps) => {
  const { initialValues, validationSchema, handleSubmit } = useCopySessionForm(fromSession, fromTimeline);
  const { collections, isLoading, error } = useFetchCollections();

  const onCopySuccess = (response: CopySessionResponse) => {
    const { sessionId, timelineId } = response.data;

    if (!sessionId || !timelineId) {
      throw new Error('Invalid response from copy session API');
    }

    handleSessionAdded(sessionId, timelineId);
  };

  const handleApiError = (error: Error) => {
    handleError(error);
    handleDismiss();
  };

  const onSubmit = (values: FormValues) => handleSubmit(values).then(onCopySuccess).catch(handleApiError);

  useEffect(() => (error ? handleApiError(error) : undefined), [error]);

  return (
    <ModalContainer dismissHandler={handleDismiss}>
      <ModalHeader>
        <h3>Copy Session</h3>
      </ModalHeader>
      {isLoading ? (
        <LoadingState />
      ) : (
        <Formik
          initialValues={initialValues}
          enableReinitialize={true}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <ModalBody>
                <FormField label="Session Name" name="name" type="text" placeholder="Enter Session Name" />
                <SelectField label="Collection" name="collection" options={collections} placeholder="Select Collection">
                  {({ collection }: Collection) => (
                    <option key={collection.collectionId} value={collection.collectionId}>
                      {collection.name}
                    </option>
                  )}
                </SelectField>
                <DatePickerField label="Use Date" name="date" filterDate={isSunday} />
              </ModalBody>
              <ModalFooter>
                <MediumPrimaryButton type="submit" disabled={isSubmitting} operating={isSubmitting}>
                  Copy Session
                </MediumPrimaryButton>
                <MediumButton data-qa-hook="addSessionCancel" onClick={handleDismiss}>
                  Cancel
                </MediumButton>
              </ModalFooter>
            </Form>
          )}
        </Formik>
      )}
    </ModalContainer>
  );
};

export default CopySessionModal;
