angular.module('lwNamb').controller(
  'TrainingViewerCtrl',

  [
    '$rootScope',
    '$scope',
    '$routeParams',
    'windowService',
    function ($rootScope, $scope, $routeParams, windowService) {
      // Save the current route before going to training
      localStorage.setItem('routeBeforeSteps', window.location.hash);
      if ($routeParams.type === 'workflow') {
        windowService.redirectHash('/training-steps/' + $routeParams.id + '?isWorkflow=true');
      } else {
        windowService.redirectHash('/training-steps/' + $routeParams.id);
      }
    },
  ]
);
