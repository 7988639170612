import cacheService from '../../services/cacheService';
import submitCommand from '../../services/submitCommand';
import { generateUUID } from '../../services/uuid';
import { InviteSettings } from '../models/inviteSettings';
import { Recipient } from '../models/recipient';

type Email = {
  subject: string;
  body: string;
};

export const createEmailInvite = (
  orgId: string,
  inviteName: string,
  inviteSettings: InviteSettings,
  email: Email,
  recipients: Recipient[],
  invitorUserId: string,
  initiatingUserId: string
) => {
  cacheService.remove(`/v1/invites/${orgId}`);
  return generateUUID((id: string) =>
    submitCommand(
      id,
      {
        id,
        name: inviteName,
        orgId: {
          id: orgId,
        },
        invitees: recipients,
        email: email,
        inviteOptions: inviteSettings,
        invitor: { id: invitorUserId },
        initiatingUserId: initiatingUserId,
      },
      'CreateEmailInvite',
      'EmailInviteCreated',
      'InviteError'
    )
  );
};
