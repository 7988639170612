import { AxiosResponse } from 'axios';
import cacheableRequest from '../../services/cacheableRequest';

export type TrainingResponse = {
  _type: string;
  assignedOn: number;
  assignorId: { id: string };
  completedCourses?: CompletedCourse[];
  completedOn?: number;
  instanceId: string;
  name: string;
  originalTrainingId: string;
  progress: { steps: { total: number; completed: number }; _type: string; percent: number };
  requiredLicense: { value: string };
  selfAssigned: boolean;
  upNext: { _type: 'Download'; assigneeId: { id: string }; instanceId: string; name: string; task: Task };
};

type Task = {
  assigneeId: { id: string };
  assignorId: { id: string };
  id: { id: string };
  startDate: number;
  status: string;
  task: {
    _id: { id: string };
    _type: string;
    assetUrl: string;
    order: number;
    taskName: string;
  };
};

type CompletedCourse = {
  assignedOn: number;
  assignorId: { id: string };
  completedOn: number;
  instanceId: string;
  name: string;
  progress: { steps: { total: number; completed: number }; _type: string; percent: number };
  reports: [];
};

export function getTraining(userId: string, shouldGetCompleted: boolean) {
  return cacheableRequest('/v1/trainingOverview?userId=' + userId + (shouldGetCompleted ? '&completed=true' : '')).then(
    ({ data }: AxiosResponse<TrainingResponse[]>) => {
      return data;
    }
  );
}
