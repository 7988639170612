/* eslint-disable no-undef */
/* istanbul ignore file */
angular.module('lwNamb', [
  'ngResource',
  'ngRoute',
  'ngSanitize',
  'ngAnimate',
  'mgcrea.ngStrap',
  'angular-progress-arc',
  'selectionModel',
  'blockUI',
  'ngFileUpload',
  'as.sortable',
  'textAngular',
  'ngStorage',
  'angular-cache',
  'ngclipboard',
  'infinite-scroll',
  'rzModule',
  'angular-google-analytics',
]);

// eslint-disable-next-line no-undef
angular.module('lwNamb').config([
  '$httpProvider',
  function ($httpProvider) {
    //initialize get if not there
    if (!$httpProvider.defaults.headers.get) {
      $httpProvider.defaults.headers.get = {};
    }

    //disable IE ajax request caching
    // removing this for a test
    //$httpProvider.defaults.headers.get['If-Modified-Since'] = 'Thu, 02 Feb 1984 05:00:00 GMT';
    //$httpProvider.defaults.headers.get['Cache-Control'] = 'no-cache';
    //$httpProvider.defaults.headers.get.Pragma = 'no-cache';

    //send cookie to api even though on different port/url
    $httpProvider.defaults.withCredentials = true;

    //setup response interceptor
    $httpProvider.interceptors.push('Interceptor');
  },
]);

angular.module('lwNamb').config([
  '$modalProvider',
  '$tooltipProvider',
  '$popoverProvider',
  function ($modalProvider, $tooltipProvider, $popoverProvider) {
    angular.extend($modalProvider.defaults, $tooltipProvider.defaults, $popoverProvider.defaults, {
      html: true,
    });
  },
]);

angular.module('lwNamb').config([
  '$provide',
  function ($provide) {
    $provide.decorator('taOptions', [
      '$delegate',
      function (taOptions) {
        taOptions.forceTextAngularSanitize = false;
        taOptions.toolbar = [
          ['bold', 'italics', 'underline', 'strikeThrough', 'ul', 'ol', 'clear'],
          ['undo', 'redo', 'justifyLeft', 'justifyCenter', 'justifyRight', 'indent', 'outdent', 'insertLink'],
        ];
        taOptions.defaultTagAttributes.a.target = '_blank';
        taOptions.classes = {
          focussed: 'focussed',
          toolbar: 'btn-toolbar',
          toolbarGroup: 'btn-group',
          toolbarButton: 'btn btn-default',
          toolbarButtonActive: 'active',
          disabled: 'disabled',
          textEditor: 'form-control',
          htmlEditor: 'form-control',
        };
        return taOptions;
      },
    ]);
  },
]);

angular.module('lwNamb').run([
  '$rootScope',
  'apiUrlService',
  function ($rootScope, apiUrlService) {
    $rootScope.initialize = function () {
      $rootScope.titleDomain = 'LifeWay';
      $rootScope.titleDefaultSection = 'Training';
      $rootScope.origin = apiUrlService.getOrigin();
      $rootScope.uniquePageName = 'home';
      $rootScope.orgIconClass = 'fas fa-church fa-fw';

      if ($rootScope.origin === 'GRID') {
        $rootScope.titleDomain = 'Ministry Grid';
      } else if ($rootScope.origin === 'TRANSFORMATION') {
        $rootScope.titleDomain = 'Transformation Church';
      } else if ($rootScope.origin === 'IDLEWILD') {
        $rootScope.titleDomain = 'Idlewild Church Training';
      } else if ($rootScope.origin === 'BELLEVUE') {
        $rootScope.titleDomain = 'Bellevue Training';
      } else if ($rootScope.origin === 'MBCB') {
        $rootScope.titleDomain = 'MBCB Training';
      } else if ($rootScope.origin === 'CONNEXUS') {
        $rootScope.titleDomain = 'Connexus Church Training';
      } else if ($rootScope.origin === 'CENTRIKID') {
        $rootScope.titleDomain = 'CentriKid Training Online';
      } else if ($rootScope.origin === 'FUGE') {
        $rootScope.titleDomain = 'FUGE Training Online';
      } else if ($rootScope.origin === 'STUDENTLIFE') {
        $rootScope.titleDomain = 'Student Life Camp Training Online';
      } else if ($rootScope.origin === 'SBC') {
        $rootScope.titleDomain = 'SBC Training & Orientation Portal';
      } else if ($rootScope.origin === 'A21') {
        $rootScope.titleDomain = 'A21 Training Center';
        $rootScope.orgIconClass = 'fas fa-building fa-fw';
      }

      $rootScope.pageTitle = $rootScope.titleDomain;
    };
    // eslint-disable-next-line no-unused-vars
    $rootScope.$on('$routeChangeSuccess', function (event, current, previous) {
      $rootScope.hideNavbar = current.$$route === undefined ? false : current.$$route.hideNavbar === true;

      $rootScope.activeTab = '';
      var titleSection = $rootScope.titleDefaultSection;

      if (current.$$route) {
        if (current.$$route.tab !== undefined) {
          $rootScope.activeTab = current.$$route.tab;
          titleSection = current.$$route.tab;
        }
        if (current.$$route.section) {
          $rootScope.activeTab = current.$$route.section.tab;
          titleSection = $rootScope.activeTab;
        }
        if ($rootScope.origin === 'NAMB' && current.$$route.nambTab !== undefined) {
          titleSection = current.$$route.nambTab;
        }

        if (current.$$route.templateUrl !== undefined) {
          $rootScope.isCurriculumPage = current.$$route.templateUrl.indexOf('curriculum') > -1;
          $rootScope.uniquePageName = current.$$route.templateUrl.replace('partials/views/', '').replace('.html', '');
        }
      }
      $rootScope.pageTitle = $rootScope.titleDomain + ' - ' + titleSection;
    });
  },
]);
