import uploadService from '../../services/uploadService';
import submitCommand from '../../services/submitCommand';
import cacheService from '../../services/cacheService';

const submitPosterImageCommand = (trainingId: string, key?: string | undefined) => {
  cacheService.remove(`/v1/workflows/${trainingId}`);

  return submitCommand(
    trainingId,
    {
      id: trainingId,
      image: key,
    },
    'SetWorkflowPosterImage',
    'WorkflowPosterImageSet'
  );
};

export const setPathwayPosterImage = (trainingId: string, blob: Blob, key: string) =>
  uploadService.uploadImage(blob, key, '/training').then(() => submitPosterImageCommand(trainingId, key));

export const removePathwayPosterImage = (trainingId: string) => submitPosterImageCommand(trainingId);
