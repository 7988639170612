import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useUser } from '../../authentication';
import { ImageCropUtils } from '../../components/ImageCrop';
import useManageTrainingRoute from '../../hooks/useManageTrainingRoute';
import { getPathway } from '../api/getPathway';
import { removePathwayPosterImage, setPathwayPosterImage } from '../api/setPathwayPosterImage';
import { updatePathwayDescription } from '../api/updatePathwayDescription';
import { updatePathwayName } from '../api/updatePathwayName';
import { Pathway } from '../models/pathway';
import { PathwaySettingsFormValues } from '../models/pathwaySettings';

type State = {
  isLoading: boolean;
  isError?: boolean;
  pathway?: Pathway;
};

export const usePathwaySettings = () => {
  const user = useUser();
  const { trainingId } = useParams<{ trainingId: string }>();
  const { manageTrainingRoutePrefix } = useManageTrainingRoute();
  const [data, setData] = useState<State>({ isLoading: true });

  const crumbs = [
    { name: 'Organization', route: '#/org' },
    { name: 'Manage Pathways', route: `#${manageTrainingRoutePrefix}/pathways` },
    ...(data.pathway?.name
      ? [
          { name: data.pathway.name, route: `#${manageTrainingRoutePrefix}/pathways/${trainingId}` },
          { name: 'Settings' },
        ]
      : []),
  ];

  useEffect(() => {
    if (!user) return setData({ isLoading: false, isError: true });

    getPathway(trainingId)
      .then((pathway: Pathway) => {
        setData({
          pathway,
          isLoading: false,
        });
      })
      .catch(() => setData(data => ({ ...data, isLoading: false, isError: true })));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const buildUpdatePromises = (values: PathwaySettingsFormValues) => {
    if (!user) return [Promise.reject('Invalid user')];
    if (!data.pathway) return [Promise.reject('Invalid pathway')];

    const name = values.name.trim();
    const description = values.description ? values.description.trim() : '';
    const promises: Promise<unknown>[] = [];

    if (name !== data.pathway.name) {
      promises.push(updatePathwayName(data.pathway.id, name, data.pathway.owner, user.userId));
    }

    if (description !== data.pathway.description) {
      promises.push(updatePathwayDescription(data.pathway.id, description, user.userId));
    }

    if (values.posterImage !== data.pathway.posterImage) {
      if (!values.posterImage && !!data.pathway.posterImage) {
        promises.push(removePathwayPosterImage(data.pathway.id));
      } else if (ImageCropUtils.isCroppedImage(values.posterImage)) {
        const pathwayId = data.pathway.id;
        promises.push(
          ImageCropUtils.convertCroppedImageToBlob(values.posterImage).then((blob: Blob) =>
            setPathwayPosterImage(pathwayId, blob, ImageCropUtils.generateFilename())
          )
        );
      }
    }

    return promises;
  };

  const savePathwaySettings = (values: PathwaySettingsFormValues) => Promise.all(buildUpdatePromises(values));

  return { ...data, crumbs, savePathwaySettings };
};
