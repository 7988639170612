import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Grid, Type } from '../../StyleGuide';
import Breadcrumbs from '../../components/Breadcrumbs';
import { Container, PageTitle } from '../../components/Layout';
import LoadingState from '../../components/LoadingState';
import ErrorMessage from '../../components/ErrorMessage';
import curriculumService from '../../services/curriculumService';
import SectionHeader from '../../components/SectionHeader';
import windowService from '../../services/windowService';
import { SmallOutlineButton } from '../../components/Buttons';
import VolumeCarousel from '../volume/VolumeCarousel';
import UnitCarousel from '../UnitCarousel';
import { SecondaryBadge } from '../../components/Badge';
import EmptyState from '../../components/EmptyState';
import BibleStudyBanner from '../components/BibleStudyBanner';
import CollectionCard from './CollectionCard';
import ManageCurriculumUnitModal from './ManageCurriculumUnitModal';
import useModal from '../../hooks/useModal';
import useCurriculum from '../../hooks/useCurriculum';

const CurriculumPageTitle = styled(PageTitle)`
  display: flex;
  align-items: center;
  grid-gap: ${Grid._4};
  margin: ${Grid._6} 0 ${Grid._5} 0;

  h1 span {
    font-weight: ${Type.Weight.normal};
    color: ${Type.Color.medium};
  }
`;

const SectionsContainer = styled.div`
  &:last-child {
    margin-bottom: ${Grid._10};
  }

  > div {
    margin-top: ${Grid._8};

    &:last-child {
      margin-top: ${Grid._10};
    }
  }
`;

const CollectionsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));
  grid-gap: ${Grid._5};
`;

const ManageCurriculum = () => {
  const { brand, ageCategory } = useCurriculum();
  const { curriculumId } = useParams();
  const [data, setData] = useState({
    isLoading: true,
    isError: false,
    units: [],
    collections: [],
    volumes: [],
  });

  const { isLoading, isError, units, collections, volumes } = data;

  const crumbs = [
    { name: 'Organization', route: '#/org' },
    { name: 'Manage Curriculum', route: '#/manage-curriculum' },
    { name: `${brand.name}: ${ageCategory}` },
  ];

  const [modal, openModal] = useModal((type, payload, dismissModal) => {
    if (!type) return;
    return <ManageCurriculumUnitModal curriculumId={curriculumId} handleDismiss={dismissModal} />;
  });

  useEffect(() => {
    Promise.all([
      curriculumService.getIndividualCurriculum(curriculumId),
      curriculumService.getUnits(curriculumId),
      curriculumService.getCurriculumCollections(curriculumId),
      curriculumService.getVolumes(curriculumId),
    ])
      .then(([curriculum, units, collections, volumes]) => {
        const sortedUnits = units.sort((a, b) => new Date(b.firstUseDate) - new Date(a.firstUseDate));
        setData(prev => ({ ...prev, isLoading: false, curriculum, units: sortedUnits, collections, volumes }));
      })
      .catch(error => {
        console.error(error);
        setData(prev => ({ ...prev, isError: true, isLoading: false }));
      });
  }, []);

  return (
    <>
      <Breadcrumbs crumbs={crumbs}></Breadcrumbs>
      <BibleStudyBanner />
      {isLoading ? (
        <LoadingState />
      ) : isError ? (
        <ErrorMessage />
      ) : (
        <Container>
          <CurriculumPageTitle>
            <h1>
              {brand.name} <span>{ageCategory}</span>
            </h1>
            {data.curriculum.offCycleEnabled && <SecondaryBadge>Off-cycle Volume Support</SecondaryBadge>}
          </CurriculumPageTitle>

          <SectionsContainer>
            <div>
              <SectionHeader title="Units">
                <div>
                  <SmallOutlineButton onClick={() => openModal('addUnit')} data-qa-hook="unitAdd">
                    Add Unit
                  </SmallOutlineButton>
                </div>
              </SectionHeader>
              {units.length > 0 ? (
                <UnitCarousel units={units} />
              ) : (
                <EmptyState title="There are no units" description="Use the Add Unit button to begin creating units" />
              )}
            </div>

            <div>
              <SectionHeader title="Volumes">
                <div>
                  <SmallOutlineButton
                    onClick={() => {
                      windowService.redirectTo(
                        `/manage-curriculum/${brand.code}/${ageCategory}/${curriculumId}/volume`
                      );
                    }}
                  >
                    Add Volume
                  </SmallOutlineButton>
                </div>
              </SectionHeader>
              {volumes.length > 0 ? (
                <VolumeCarousel volumes={volumes} />
              ) : (
                <EmptyState
                  title="There are no volumes"
                  description="Use the Add Volume button to begin creating volumes"
                />
              )}
            </div>

            <div>
              <SectionHeader title="Collections">
                <div>
                  <SmallOutlineButton
                    onClick={() => {
                      windowService.redirectTo(
                        `/manage-curriculum/${brand.code}/${ageCategory}/${curriculumId}/collection`
                      );
                    }}
                  >
                    Add Collection
                  </SmallOutlineButton>
                </div>
              </SectionHeader>
              {collections.length > 0 ? (
                <CollectionsContainer>
                  {collections.map(collection => (
                    <CollectionCard key={collection.collectionId} collection={collection} />
                  ))}
                </CollectionsContainer>
              ) : (
                <EmptyState
                  title="There are no collections"
                  description="Use the Add Collection button to begin creating collections"
                />
              )}
            </div>
          </SectionsContainer>
        </Container>
      )}
      {modal}
    </>
  );
};

export default ManageCurriculum;
