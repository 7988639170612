import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import sessionTimelineService from '../../services/sessionTimelineService';
import windowService from '../../services/windowService';
import { PrimaryButton } from '../../components/Buttons';
import { handleError } from '../../utils/apiUtils';
import useSessionTimeline from '../../hooks/useSessionTimeline';

export const getPresentationMediaArchiveName = (
  curriculumName,
  curriculumAgeCategory,
  sessionScheduledDate,
  timelineName
) =>
  curriculumName && sessionScheduledDate && timelineName
    ? `${curriculumName}_${curriculumAgeCategory}_${sessionScheduledDate}_${timelineName}_Media`
    : null;

const DownloadPresentationMediaButton = ({ archiveName, ButtonComponent }) => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [hasMedia, setHasMedia] = useState();
  const { timeline } = useSessionTimeline();

  const timelineSegments = timeline.segments;
  const archiveId = timeline.archiveId;

  useEffect(() => {
    if (timelineSegments?.length > 0) {
      const segments = Object.values(timelineSegments);
      const sections = segments.flatMap(segment => segment.sections);
      const hasMedia = sections.filter(section => section?.media);

      setHasMedia(hasMedia.length > 0);
    }
  }, [timelineSegments]);

  const generatingDownload = hasMedia && (!archiveId || !archiveName);

  const download = () => {
    setIsProcessing(true);

    sessionTimelineService
      .getArchiveUrl(archiveId, archiveName)
      .then(windowService.openUrl)
      .catch(handleError)
      .finally(() => setIsProcessing(false));
  };

  return (
    <ButtonComponent
      disabled={generatingDownload || !archiveId || !archiveName}
      operating={isProcessing}
      onClick={download}
      data-tracking-id="download-presentation-media"
    >
      {generatingDownload ? 'Generating Download' : 'Download Presentation Media'}
    </ButtonComponent>
  );
};

DownloadPresentationMediaButton.defaultProps = {
  ButtonComponent: PrimaryButton,
};

DownloadPresentationMediaButton.propTypes = {
  archiveId: PropTypes.string,
  archiveName: PropTypes.string,
  timelineSegments: PropTypes.array,
  ButtonComponent: PropTypes.elementType,
};

export default DownloadPresentationMediaButton;
