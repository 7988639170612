import React from 'react';
import { Grid, Color, Type, Breakpoints } from '../StyleGuide';
import styled from 'styled-components';

const Tabs = styled.ul<{ fontSize?: string }>`
  position: relative;
  z-index: 1;
  display: flex;
  list-style: none;
  margin: ${Grid._5} 0 0;
  padding: 0;
  li {
    display: flex;
    border-bottom: 3px solid transparent;
    &:hover {
      border-color: ${Color.Gray._30};
    }
  }
  li.active-tab {
    border-color: ${Color.Orange._50};
    > button {
      i {
        color: ${Color.Orange._50};
      }
      color: ${Type.Color.dark};
      font-weight: ${Type.Weight.semibold};
    }
  }
  li:not(.active-tab) > button {
    cursor: pointer;
  }
  button {
    border: 0;
    background: none;
    padding: ${Grid._4} ${Grid._3};
    text-align: center;
    font-size: ${props => (props.fontSize ? props.fontSize : Type.Scale._3)};
    color: ${Type.Color.medium};
    span,
    i {
      color: ${Color.Secondary._50};
      margin-right: ${Grid._3};
    }
    &:focus {
      outline: none;
    }
    @media screen and (min-width: ${Breakpoints.screen_sm}) {
      padding: ${Grid._4} ${Grid._5};
      text-align: left;
    }
  }
`;

export default function SubMenuTabs({
  items,
  handleTabClick,
  activeTab,
}: {
  items: { id: string; label: string; icon?: React.ReactNode }[];
  handleTabClick: (id: string) => void;
  activeTab: string;
}) {
  return (
    <Tabs style={{ margin: 0 }}>
      {items.map(item => (
        <li key={item.id} className={activeTab === item.id ? 'active-tab' : ''}>
          <button data-qa-hook={`subMenuTab${item.label}`} onClick={() => handleTabClick(item.id)}>
            {item.icon}
            {item.label}
          </button>
        </li>
      ))}
    </Tabs>
  );
}
