import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Breakpoints } from '../../StyleGuide';
import { SessionCard } from '../../components/SessionElements';
import { CustomizationIndicator } from '../../components/SessionElements';
import { Badge } from '../../components/Badge';
import CalendarDateIcon from '../../components/CalendarDateIcon';

const DateIconContainer = styled.div`
  position: relative;
  top: 0;
  left: 0;

  ${Badge} {
    display: none;

    position: absolute;
    bottom: 0;
    left: 60px;
  }

  > ${CustomizationIndicator} {
    display: initial;

    position: absolute;
    bottom: -4px;
    left: 43px;
    background: white;
    border-radius: 50%;
  }

  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    ${Badge} {
      display: initial;
    }

    > ${CustomizationIndicator} {
      display: none;
    }
  }
`;

export default function CurriculumCarouselItem({ session, onClick }) {
  const hasCustomizedTimeline = session.timelines?.some(tl => !!tl.timelineCustomizationId);

  return (
    <SessionCard
      data-qa-hook="sessionContainer"
      onClick={onClick}
      session={session}
      CalendarDateComponent={props => (
        <DateIconContainer>
          <CalendarDateIcon {...props} />
          {hasCustomizedTimeline && (
            <>
              <Badge>
                <CustomizationIndicator /> Customized
              </Badge>
              <CustomizationIndicator />
            </>
          )}
        </DateIconContainer>
      )}
    ></SessionCard>
  );
}

CurriculumCarouselItem.propTypes = {
  session: PropTypes.shape({
    sessionId: PropTypes.string.isRequired,
    issueId: PropTypes.string.isRequired,
    timelines: PropTypes.array,
  }),
  timelineEnabled: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};
