import React from 'react';
import styled from 'styled-components';
import { Grid } from '../../StyleGuide';
import { Assignment, Report } from '../models/assignment';
import windowService from '../../services/windowService';
import { SmallPrimaryButton } from '../../components/Buttons';

const AssignmentCardDownloadsContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${Grid._4};
  margin-top: ${Grid._4};
`;

const DownloadButton = styled(SmallPrimaryButton)`
  width: fit-content;
`;

export default function AssignmentCardDownloads({
  assignment,
  assigneeUserId,
}: {
  assignment: Assignment;
  assigneeUserId: string;
}) {
  const reportVisibility = window.location.hash.includes('completed') ? 'PUBLIC' : 'PRIVATE';

  const certificates: { instanceId: string }[] = (() => {
    if (assignment.certificateInfo) {
      return [{ instanceId: assignment.instanceId }];
    }
    if (assignment.completedCourses) {
      return assignment.completedCourses
        .filter(course => course.certificateInfo)
        .map(course => ({
          instanceId: course.instanceId,
        }));
    }

    return [];
  })();

  const downloadCertificate = (id: string | undefined) => {
    if (!id) return;
    windowService.openUrl(window.getApiUrl() + '/v1/files/certificates/' + id + '/' + assigneeUserId);
  };

  const downloadFile = (tasklist: Assignment, report: Report) => {
    const lookupFileName = encodeURIComponent(tasklist.name.replace(/ /gi, '_'));
    const lookupFilePath = encodeURIComponent(report.reportId);
    window.open(window.getApiUrl() + '/v1/files?fileName=' + lookupFileName + '&filePath=' + lookupFilePath);
  };

  return (
    <AssignmentCardDownloadsContainer>
      {certificates.length > 0 && (
        <DownloadButton
          onClick={() => certificates.map(c => downloadCertificate(c.instanceId))}
          data-tracking-id="download-certificate"
          aria-label="download certificate"
        >
          <i className="fa fa-award" /> Download Certificate{certificates.length > 1 ? 's' : ''}
        </DownloadButton>
      )}

      {assignment.reports &&
        assignment.reports?.map(report => {
          if (
            report.reportId &&
            (((report.id === 'CPIACandidate' || report.id === 'CPCACandidate') && reportVisibility === 'PUBLIC') ||
              ((report.id === 'CPIA' || report.id === 'CPCA') && reportVisibility === 'PRIVATE'))
          ) {
            return (
              <DownloadButton
                key={report.reportId}
                onClick={() => downloadFile(assignment, report)}
                data-tracking-id="view-reports"
                aria-label="view reports"
              >
                <i className="fa fa-chart-bar" /> View Report
              </DownloadButton>
            );
          }
        })}
    </AssignmentCardDownloadsContainer>
  );
}
