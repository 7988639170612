import StepIcons from '../../StepIcons';
import apiClient from '../../services/apiClient';

// TODO: combine with Task from Step.tsx
type Task = {
  _type: keyof typeof StepIcons;
  icon: (typeof StepIcons)[keyof typeof StepIcons];
  taskIndex: number;
};

export type PathwayEntity = {
  id: string;
  taskListId?: { id: string };
  taskList?: { tasks: Task[] };
  _type: string;
  subject?: string;
  name: string;
};

export type PathwayTransition = {
  id: string;
  from: string;
  to: string;
};

export type Pathway = {
  id: string;
  name: string;
  owner: string;
  published: boolean;
  entities: PathwayEntity[];
  transitions: PathwayTransition[];
  description?: string;
  posterImage?: string;
  itemNumber?: { value: string };
};

const orderEntities = (entities: PathwayEntity[], transitions: PathwayTransition[]) => {
  const addNext = (entityId: string) => {
    const transition = transitions.find(t => t.from == entityId);
    if (transition) {
      const next = entities.find(e => e.id == transition.to);
      if (next) {
        orderedEntities.push(next);
        addNext(next.id);
      }
    }
  };

  const orderedEntities: PathwayEntity[] = [];
  const start: PathwayEntity | undefined = entities.find(e => e._type === 'Start');

  if (start) {
    orderedEntities.push(start);
    addNext(start.id);

    if (orderedEntities.length == 1) {
      const end = entities.find(e => e._type == 'End');
      if (end) orderedEntities.push(end);
    }
  }

  return orderedEntities;
};

const formatTasks = (entities: PathwayEntity[]) => {
  let index = 0;
  entities.forEach(entity => {
    if (entity._type === 'TaskList' && entity.taskList?.tasks?.length) {
      entity.taskList.tasks.forEach(task => {
        task.icon = StepIcons[task._type];
        task.taskIndex = index;
        index++;
      });
    }
  });

  return entities;
};

type TransformablePathway = Pathway & {
  _id?: string;
  itemNumber?: string;
};

export const transformPathway = (data: TransformablePathway): Pathway => {
  const { _id, itemNumber, ...pathway } = data;

  return {
    ...pathway,
    id: pathway.id ?? _id,
    entities: pathway.entities ? formatTasks(orderEntities(pathway.entities, pathway.transitions || [])) : [],
    itemNumber: itemNumber ? { value: itemNumber.split(',')[0] } : undefined,
    posterImage: pathway.posterImage && `${apiClient.getConfig().baseURL}/v1/images/training/${pathway.posterImage}`,
  };
};
