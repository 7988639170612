import alertService from '../services/AlertService';

interface ApiError extends Error {
  reason: {
    id: string;
  };
}

export const handleError = (error: Error) => {
  console.error(error);
  alertService.showError();
};

export const isNoEffect = (error: ApiError): boolean => {
  return error?.reason?.id === 'NoEffect';
};

export const pollTimes = (
  promise: () => Promise<void>,
  onComplete: Function,
  times: number = 5,
  interval: number = 1000
): Function => {
  let timeoutId: ReturnType<typeof setTimeout> | undefined;
  let stopProcessing = false;

  const cancel = () => {
    stopProcessing = true;
    if (timeoutId) clearTimeout(timeoutId);
  };

  const call = (remaining: number) => {
    Promise.resolve(promise()).then(() => {
      // If cancel is called, we don't want to poll again but we
      // also don't want to execute anything within this resolver.
      if (stopProcessing) return;

      if (remaining > 1) {
        timeoutId = setTimeout(call, interval, remaining - 1);
      } else {
        onComplete();
      }
    });
  };

  call(times);

  return cancel;
};
