import cacheService from '../../services/cacheService';
import submitCommand from '../../services/submitCommand';
import uuid from '../../services/uuid';
import { Assignment } from '../models/assignment';

export const removeTraining = (assignment: Assignment, initiatingUserId: string) => {
  cacheService.removeAllInPath(`/v1/trainingOverview`);
  const id = uuid.generate();
  const assignables = [];
  const trainingType = assignment._type === 'ActiveWorkflowOverview' ? 'Workflow' : 'TaskList';
  if (trainingType === 'TaskList') {
    assignables.push({
      taskListInstanceId: { id: assignment.instanceId },
      _type: trainingType,
    });
  } else if (trainingType === 'Workflow') {
    assignables.push({
      workflowInstanceId: assignment.instanceId,
      _type: trainingType,
    });
  }
  const cmd = { id: id, assignables: assignables, initiatingUserId: initiatingUserId };

  return submitCommand(id, cmd, 'UnassignTraining', 'TrainingUnassigned', 'TrainingUnassignedError');
};
