import {
  addDays,
  addMonths,
  format,
  isBefore,
  isSunday,
  lastDayOfMonth,
  nextSunday,
  parseISO,
  previousSunday,
  startOfMonth,
  subDays,
  subMonths,
  subWeeks,
} from 'date-fns';
import { isWithinOneWeek, toLocalMidnight } from '../utils/dateUtils';
import apiClient from './apiClient';
import cacheableRequest from './cacheableRequest';
import cacheService from './cacheService';
import licenseService from './licenseService';
import orgService from './orgService';
import { transformSessionTimeline } from './sessionTimelineService';
import submitCommand from './submitCommand';
import uploadService from './uploadService';
import { generateUUID } from './uuid';
import windowService from './windowService';

export const CurriculumBrandMap = {
  'explore the bible': 'ETB',
  'bible studies for life': 'BSFL',
  'the gospel project': 'TGP',
  'gospel project': 'TGP', // TODO: Remove when there are no longer active subscriptions using this title
  hyfi: 'HYFI',
  you: 'YOU',
};

export const CurriculumStorageLocations = Object.freeze({
  AUDIO: 'curriculum-storage/videos', // stored as video
  VIDEO: 'curriculum-storage/videos',
  IMAGE: 'curriculum-storage/files', // stored with other materials
  FILE: 'curriculum-storage/files',
});

export const CurriculumPricingVersion = 3;

const queryCollections = id => {
  return cacheableRequest(`v1/org/curriculum/${id}/collections`).then(response => {
    if (!response.data || !Array.isArray(response.data)) throw new Error('Invalid collection response');

    return response.data.map(item => ({
      ...item,
      collection: transformCollection(item.collection),
    }));
  });
};

const getCurriculumVolume = volumeId =>
  cacheableRequest(`/author/curriculum/volumes/${volumeId}`).then(response => response.data);

const clearVolumeCacheForRequest = (volumeId, request) => {
  cacheService.remove(`/author/curriculum/volumes/${volumeId}`);
  return request;
};

const clearVolumesCacheForRequest = request => {
  cacheService.removeAllInPath(`/author/curriculum/volumes`);
  return request;
};

const getCurriculumSchedule = curriculumId =>
  // TODO: Remove once this is no longer being defaulted to true in the BE
  cacheableRequest(`/v2/org/curriculum/${curriculumId}/sessions?enableHyfiPreview=false`)
    .then(response => response.data)
    .then(({ volumes, sessions }) => ({ volumes, sessions: sessions?.map(transformSession) }));

const transformUnit = unit => ({
  ...unit,
  firstUseDate: unit.firstUseDay && toLocalMidnight(unit.firstUseDay),
  publishStartDate: unit.publishStartDay && toLocalMidnight(unit.publishStartDay),
  publishEndDate: unit.publishEndDay && toLocalMidnight(unit.publishEndDay),
});

const transformCollection = collection => ({
  ...collection,
  collectionId: collection.collectionId ?? collection.seriesId,
  package: collection.package ?? 'Basic',
});

export const transformSession = session => transformPreparedSession(preparePreviewSession(session));

const transformDate = date => date && toLocalMidnight(date);
const toDate = date => date && (typeof date === 'string' ? parseISO(date) : date);

const transformPreparedSession = session => {
  const publishStartDate = transformDate(session.publishStartDate);
  const publishEndDate = transformDate(session.publishEndDate);
  const availableThroughDate = publishEndDate && subDays(toDate(publishEndDate), 1);

  const scheduledDate = toDate(transformDate(session.scheduledDate));
  const isThisWeek = !!(scheduledDate && isWithinOneWeek(scheduledDate));

  const isPreview = isPreviewSession(session);

  const getAvailableThroughMessage = formatStr =>
    isPreview
      ? 'Available through the end of the preview period'
      : `Available through ${format(availableThroughDate, formatStr)}`;

  return {
    ...session,
    useDate: transformDate(session.useDate),
    publishStartDate,
    publishEndDate,
    scheduledDate,
    recommendedDate: toDate(transformDate(session.recommendedDate)),
    availableStartDate: toDate(publishStartDate),
    availableThroughDate,
    isThisWeek,
    isPreview,
    getAvailableThroughMessage,
    ...(session.timelines ? { timelines: session.timelines.map(transformSessionTimeline) } : {}),
  };
};

const isPreviewSession = session =>
  curriculumService.isPreviewCurriculum(session.curriculumId) && session.issueName === 'Preview';

const preparePreviewSession = session => {
  if (!isPreviewSession(session)) return session;

  const previewDate = `${format(
    nextSunday(addDays(new Date(), (session.sessionNumber - 1) * 7)),
    'yyyy-MM-dd'
  )}T00:00:00`;

  return {
    ...session,
    isScheduled: true,
    useDate: previewDate,
    scheduledDate: previewDate,
    recommendedDate: previewDate,
    publishEndDate: undefined,
  };
};

const updateCollection = collection => {
  cacheService.removeAllInPath(`/curriculum-api/curriculums/${collection.curriculumId}/series`);
  return apiClient.put(
    `/curriculum-api/curriculums/${collection.curriculumId}/series/${collection.collectionId}`,
    collection
  );
};

const curriculumService = {
  compareAgeCategory: (a, b) => {
    const order = ['adult', 'students', 'kids', 'preschool'];

    const indexA = order.indexOf(a.toLowerCase());
    const indexB = order.indexOf(b.toLowerCase());

    if (indexA < 0 || indexB < 0) throw new Error('Invalid age category.');
    else return indexA - indexB;
  },

  compareBrandName: (a, b) => a.localeCompare(b),

  filterSessionMaterials: (collectionId, sessionMaterials) =>
    sessionMaterials.reduce((materials, material) => {
      if (material.series.find(({ collectionId: materialCollectionId }) => collectionId === materialCollectionId))
        materials.push(material);

      return materials;
    }, []),

  createCollection: (curriculumId, name, plan, image, startDate, endDate) => {
    cacheService.removeAllInPath(`/curriculum-api/curriculums/${curriculumId}/series`);
    return apiClient
      .post(`/curriculum-api/curriculums/${curriculumId}/series`, {
        name,
      })
      .then(response =>
        updateCollection(
          transformCollection({
            curriculumId,
            seriesId: response.data.id,
            name,
            image,
            package: plan,
            startDate,
            endDate,
          })
        )
      );
  },

  getCurriculumCollections: curriculumId =>
    cacheableRequest(`/curriculum-api/curriculums/${curriculumId}/series`).then(response =>
      response.data?.map(transformCollection)
    ),

  getCurriculumCollection: (curriculumId, collectionId) =>
    cacheableRequest(`/curriculum-api/curriculums/${curriculumId}/series/${collectionId}`).then(
      response => response.data && transformCollection(response.data)
    ),

  deleteCollection: (curriculumId, collectionId) => {
    cacheService.removeAllInPath(`/curriculum-api/curriculums/${curriculumId}/series`);
    return apiClient.delete(`/curriculum-api/curriculums/${curriculumId}/series/${collectionId}`);
  },

  getCollections: id => queryCollections(id),

  getCollectionGroups: orgId =>
    orgService.getOrg(orgId).then(org =>
      org.groups
        .filter(g => Boolean(g.curriculum))
        .sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1))
        .map(collection => ({ ...collection, id: collection.id.id }))
    ),

  getIndividualCollection: (id, collectionId) =>
    queryCollections(id).then(data => data.find(({ collection }) => collection.collectionId === collectionId)),

  updateCollection: collection => updateCollection(collection),

  getPairedCurriculumItemNumbers: orgId =>
    apiClient
      .get(`/v1/licenses?detail=true&orgId=${orgId}`)
      .then(response =>
        response.data.licenses
          .filter(license => license.item.mediumFormat === 'Online Curriculum' && !licenseService.hasExpired(license))
          .map(license => license.item.number)
      ),

  getAllCurriculum: () => cacheableRequest('/curriculum-api/curriculums').then(response => response.data),

  getIndividualCurriculum: curriculumId =>
    cacheableRequest(`/curriculum-api/curriculums/${curriculumId}`, 60).then(response => response.data),

  getDistributedCurriculum: () =>
    apiClient.get('/v1/distributed-curriculum').then(response =>
      response?.data?.map(({ curriculum, sessions }) => ({
        curriculum,
        sessions: sessions.map(transformSession),
      }))
    ),

  createUnit: (curriculumId, name, firstUseDate) =>
    apiClient
      .post(`/curriculum-api/curriculums/${curriculumId}/issues`, {
        name,
        firstUseDate,
        sessionCount: 1,
      })
      .then(response => {
        cacheService.remove(`/curriculum-api/curriculums/${curriculumId}/issues`);
        return response.data;
      }),

  getUnits: curriculumId =>
    cacheableRequest(`/curriculum-api/curriculums/${curriculumId}/issues`).then(response =>
      response.data?.map(transformUnit)
    ),

  getIndividualUnit: (unitId, curriculumId) =>
    cacheableRequest(`/curriculum-api/curriculums/${curriculumId}/issues/${unitId}`).then(response =>
      transformUnit(response.data)
    ),

  updateUnitName: (curriculumId, issueId, newName) => {
    cacheService.removeAllInPath(`/curriculum-api/curriculums/${curriculumId}/issues`);
    return apiClient
      .post(`/curriculum-api/curriculums/${curriculumId}/issues/${issueId}/rename`, { name: newName })
      .then(response => response.data);
  },

  removeUnit: (curriculumId, issueId) => {
    cacheService.removeAllInPath(`/curriculum-api/curriculums/${curriculumId}/issues`);
    return apiClient.delete(`/curriculum-api/curriculums/${curriculumId}/issues/${issueId}`);
  },

  getSessionFromCurriculums: (curriculums, sessionId, bsBrand, ageCategory) => {
    const matchLowerCase = (a, b) => a.toLowerCase() === b.toLowerCase();
    const curriculum = curriculums.find(
      c => matchLowerCase(c.curriculum.brandCode, bsBrand) && matchLowerCase(c.curriculum.name, ageCategory)
    );

    if (!curriculum.sessions) return {};

    return curriculum.sessions.find(s => s.sessionId === sessionId) || {};
  },

  getCurriculumSchedule: getCurriculumSchedule,

  getSessionFromCurriculumSchedule: (curriculumId, sessionId) =>
    getCurriculumSchedule(curriculumId).then(({ sessions }) =>
      sessions.find(session => session.sessionId === sessionId)
    ),

  getSessionPublishDates: sessionDate => {
    const publishStartTimezoneOffset = '-05:00';
    const publishEndTimezoneOffset = '-12:00';

    const setToMidnightWithOffset = (date, offset) => new Date(`${date.toISOString().split('T')[0]}T00:00:00${offset}`);

    const publishStartDate = setToMidnightWithOffset(
      subMonths(startOfMonth(sessionDate), 2),
      publishStartTimezoneOffset
    );

    const lastDay = lastDayOfMonth(sessionDate);
    const secondToLastSunday = previousSunday(subWeeks(lastDay, isSunday(lastDay) ? 0 : 1));
    const publishEndDate = setToMidnightWithOffset(
      startOfMonth(addMonths(sessionDate, isBefore(sessionDate, secondToLastSunday) ? 1 : 2)),
      publishEndTimezoneOffset
    );

    return { publishStartDate, publishEndDate };
  },

  createSession: (curriculumId, issueId, name, useDate, publishStartDate, publishEndDate) => {
    cacheService.remove(`/curriculum-api/issues/${issueId}/sessions`);
    cacheService.removeAllInPath(`/author/curriculum/sessions-and-timelines`);

    return apiClient
      .post(`/curriculum-api/issues/${issueId}/sessions`, {
        curriculumId,
        name,
        useDate,
        publishStartDate,
        publishEndDate,
      })
      .then(response => response.data);
  },

  getAllSessions: issueId =>
    cacheableRequest(`/curriculum-api/issues/${issueId}/sessions`).then(response =>
      response.data?.map(transformSession)
    ),

  getSession: (issueId, sessionId) =>
    cacheableRequest(`/curriculum-api/issues/${issueId}/sessions/${sessionId}`)
      .then(response => response.data || Promise.reject('Invalid response'))
      .then(transformSession),

  getSessionById: (sessionId, bypassPreviewPrep = false) =>
    cacheableRequest(`/curriculum-api/sessions/${sessionId}`)
      .then(response => response.data || Promise.reject('Invalid response'))
      .then(bypassPreviewPrep ? transformPreparedSession : transformSession),

  updateSessionName: (issueId, sessionId, name) => {
    cacheService.remove(`/curriculum-api/sessions/${sessionId}`);
    cacheService.removeAllInPath(`/curriculum-api/issues/${issueId}/sessions`);
    cacheService.removeAllInPath(`/author/curriculum/sessions-and-timelines`);

    return apiClient.post(`/curriculum-api/issues/${issueId}/sessions/${sessionId}/rename`, { name });
  },

  updateSessionDescription: (issueId, sessionId, description) => {
    cacheService.remove(`/curriculum-api/sessions/${sessionId}`);
    cacheService.remove(`/curriculum-api/issues/${issueId}/sessions/${sessionId}`);
    cacheService.removeAllInPath(`/author/curriculum/sessions-and-timelines`);

    return apiClient.post(`/curriculum-api/issues/${issueId}/sessions/${sessionId}/description`, { description });
  },

  updateSessionImage: (issueId, sessionId, image) => {
    cacheService.remove(`/curriculum-api/sessions/${sessionId}`);
    cacheService.remove(`/curriculum-api/issues/${issueId}/sessions/${sessionId}`);
    cacheService.removeAllInPath(`/author/curriculum/sessions-and-timelines`);

    return apiClient.post(`/curriculum-api/issues/${issueId}/sessions/${sessionId}/image`, { image });
  },

  uploadSessionMaterialFile: (mediaId, curriculumId, fileName, initiatingUserId) => {
    const cmd = {
      id: mediaId,
      orgAccountId: curriculumId,
      fileName,
      forTask: false,
      initiatingUserId,
    };
    return submitCommand(cmd.id, cmd, 'CreateFileMetadata', 'FileMetadataCreated', 'FileMetadataCreationFailed');
  },

  uploadSessionMaterialMedia: (mediaId, curriculumId, fileName, initiatingUserId) => {
    const cmd = {
      id: mediaId,
      orgId: curriculumId, // not sure why, but this is what the Angular code was doing
      fileName,
      title: fileName,
      description: '',
      initiatingUserId,
    };
    return submitCommand(cmd.id, cmd, 'SaveCurriculumVideo', 'UploadedVideoProcessed');
  },

  createSessionMaterial: (sessionId, payload) => {
    cacheService.remove(`/curriculum-api/sessions/${sessionId}/materials`);
    return apiClient.post(`/curriculum-api/sessions/${sessionId}/materials`, payload).then(response => response.data);
  },

  updateSessionMaterial: (sessionId, materialId, seriesIds, packageType) => {
    cacheService.remove(`/curriculum-api/sessions/${sessionId}/materials`);
    return apiClient.post(`/curriculum-api/sessions/${sessionId}/materials/${materialId}/update`, {
      seriesIds,
      package: packageType,
    });
  },

  updateSessionMaterialName: (sessionId, materialId, newName) => {
    cacheService.remove(`/curriculum-api/sessions/${sessionId}/materials`);
    return apiClient.post(`/curriculum-api/sessions/${sessionId}/materials/${materialId}/rename`, { name: newName });
  },

  deleteSessionMaterial: (sessionId, materialId) => {
    cacheService.remove(`/curriculum-api/sessions/${sessionId}/materials`);
    return apiClient.delete(`/curriculum-api/sessions/${sessionId}/materials/${materialId}`);
  },

  updateSessionOrderForIssue: (sessions, issueId) => {
    cacheService.removeAllInPath(`/curriculum-api/issues/${issueId}/sessions`);
    const sessionIdUseDates = sessions.map(session => ({
      sessionId: session.sessionId,
      useDate: session.useDate,
    }));
    return apiClient
      .post(`/curriculum-api/issues/${issueId}/sessions/reorder`, {
        useDates: sessionIdUseDates,
      })
      .then(response => response.data);
  },

  updateMaterialOrderForSession: (sessionId, materials) => {
    cacheService.remove(`/curriculum-api/sessions/${sessionId}`);

    const materialIdAndOrder = materials.map(material => ({
      materialId: material.materialId,
      newOrder: material.order,
    }));

    return apiClient.post(`/curriculum-api/sessions/${sessionId}/materials/reorder`, {
      newOrder: materialIdAndOrder,
    });
  },

  getSessionsAndTimelines: (curriculumId, fromDate, toDate) =>
    cacheableRequest(
      `/author/curriculum/sessions-and-timelines?curriculumId=${curriculumId}&fromDate=${fromDate}&toDate=${toDate}`,
      1
    ).then(response => response.data?.map(transformPreparedSession)),

  getVolumes: curriculumId =>
    cacheableRequest(`/author/curriculum/volumes?curriculumId=${curriculumId}`).then(response => response.data),

  getVolume: getCurriculumVolume,

  saveVolume: (
    curriculumId,
    volumeId,
    name,
    volumeNumber,
    quarterSeason,
    quarterYear,
    startDate,
    endDate,
    image,
    availabilityEndDate,
    initiatingUserId
  ) => {
    const cmd = { curriculumId, name, image, startDate, endDate, initiatingUserId };

    // optional values
    if (volumeNumber) cmd['number'] = volumeNumber;
    if (quarterSeason && quarterYear) cmd['quarter'] = { season: quarterSeason, year: quarterYear };
    if (availabilityEndDate) cmd['availabilityEndDate'] = availabilityEndDate;

    if (volumeId) {
      return clearVolumesCacheForRequest(
        submitCommand(
          volumeId,
          { ...cmd, id: volumeId },
          'CvSetVolumeProperties',
          'CvVolumePropertiesSet',
          'CvCommandFailed'
        )
      );
    } else {
      return clearVolumesCacheForRequest(
        generateUUID(id => submitCommand(id, { ...cmd, id }, 'CvCreateVolume', 'CvVolumeCreated', 'CvCommandFailed'))
      );
    }
  },

  removeVolume: (volumeId, initiatingUserId) =>
    clearVolumesCacheForRequest(
      submitCommand(
        volumeId,
        { id: volumeId, initiatingUserId },
        'CvDeleteVolume',
        'CvVolumeDeleted',
        'CvCommandFailed'
      )
    ),

  createVolumeHeader: (volumeId, name, atIndex, initiatingUserId) =>
    clearVolumeCacheForRequest(
      volumeId,
      generateUUID(sectionId =>
        submitCommand(
          volumeId,
          { id: volumeId, sectionId, header: name, atIndex, initiatingUserId },
          'CvCreateMaterialSection',
          'CvMaterialSectionCreated',
          'CvCommandFailed'
        )
      )
    ),

  removeVolumeHeader: (volumeId, sectionId, initiatingUserId) =>
    clearVolumeCacheForRequest(
      volumeId,
      submitCommand(
        volumeId,
        { id: volumeId, sectionId, initiatingUserId },
        'CvDeleteMaterialSection',
        'CvMaterialSectionDeleted',
        'CvCommandFailed'
      )
    ),

  updateVolumeHeader: (volumeId, sectionId, name, initiatingUserId) =>
    clearVolumeCacheForRequest(
      volumeId,
      submitCommand(
        volumeId,
        { id: volumeId, sectionId, header: name, initiatingUserId },
        'CvSetMaterialSectionHeader',
        'CvMaterialSectionHeaderSet',
        'CvCommandFailed'
      )
    ),

  copyVolumeHeaders: (volumeId, initiatingUserId) =>
    clearVolumeCacheForRequest(
      volumeId,
      submitCommand(
        volumeId,
        { id: volumeId, initiatingUserId },
        'CvCopyMaterialSections',
        'CvMaterialSectionsCopied',
        'CvCommandFailed'
      )
    ),

  reorderVolumeHeaders: (volumeId, sectionIds, initiatingUserId) =>
    clearVolumeCacheForRequest(
      volumeId,
      submitCommand(
        volumeId,
        { id: volumeId, sectionIds, initiatingUserId },
        'CvOrderMaterialSections',
        'CvMaterialSectionsOrdered',
        'CvCommandFailed'
      )
    ),

  reorderVolumeHeaderMaterials: (volumeId, sectionId, materialIds, initiatingUserId) =>
    clearVolumeCacheForRequest(
      volumeId,
      submitCommand(
        volumeId,
        { id: volumeId, sectionId, materialIds, initiatingUserId },
        'CvOrderMaterials',
        'CvMaterialsOrdered',
        'CvCommandFailed'
      )
    ),

  getVolumeHeader: (volumeId, headerId) =>
    getCurriculumVolume(volumeId).then(volume => volume.sections.find(header => header.id === headerId)),

  uploadCurriculumImage: (filename, image) => uploadService.upload('curriculum-storage/images', filename, image),

  addVolumeHeaderMaterial: (volumeId, headerId, name, plan, fileId, fileName, initiatingUserId) =>
    clearVolumeCacheForRequest(
      volumeId,
      generateUUID(materialId =>
        submitCommand(
          volumeId,
          {
            id: volumeId,
            sectionId: headerId,
            material: { materialId, name, package: plan, fileId, fileName },
            initiatingUserId,
          },
          'CvCreateMaterial',
          'CvMaterialCreated',
          'CvCommandFailed'
        )
      )
    ),

  removeVolumeHeaderMaterial: (volumeId, sectionId, materialId, initiatingUserId) =>
    clearVolumeCacheForRequest(
      volumeId,
      submitCommand(
        volumeId,
        { id: volumeId, sectionId, materialId, initiatingUserId },
        'CvDeleteMaterial',
        'CvMaterialDeleted',
        'CvCommandFailed'
      )
    ),

  updateVolumeHeaderMaterialName: (volumeId, headerId, materialId, name, initiatingUserId) =>
    clearVolumeCacheForRequest(
      volumeId,
      submitCommand(
        volumeId,
        { id: volumeId, sectionId: headerId, materialId, name, initiatingUserId },
        'CvSetMaterialName',
        'CvMaterialNameSet',
        'CvCommandFailed'
      )
    ),

  getAdvancePlanningMaterials: curriculumId =>
    cacheableRequest(`/v1/org/curriculum/${curriculumId}/adv-plan/volumes`).then(response => response.data),

  downloadVolumeMaterial: (path, name) =>
    windowService.openUrl(
      `${apiClient.getConfig().baseURL}/v1/files?fileName=${name}&filePath=${path}&forceDownload=1`
    ),

  getAvailableVolumes: curriculumId =>
    cacheableRequest(`/v1/org/curriculum/${curriculumId}/volumes-and-sessions`).then(response =>
      response.data.map(obj => ({ ...obj.volume, sessions: obj.sessions?.map(transformSession) }))
    ),

  resetSchedule: (curriculumId, offsetDays, initiatingUserId) => {
    cacheService.removeAllInPath(`/v1/org/curriculum/${curriculumId}`);
    cacheService.removeAllInPath(`/v2/org/curriculum/${curriculumId}`);
    return generateUUID(id =>
      submitCommand(
        id,
        { id, curriculumId, offsetDays, initiatingUserId },
        'SetScheduleOffset',
        'ScheduleOffsetSet',
        'ScheduleOffsetCommandFailed'
      )
    );
  },

  getSessionAndMaterials: (issueId, sessionId) =>
    apiClient
      .get(`/v1/org/curriculum/${issueId}/${sessionId}`)
      .then(response => !!response.data && transformSession(response.data)),

  getAllSessionMaterials: (curriculumId, sessionId) =>
    apiClient.get(`/v1/org/curriculum/${curriculumId}/sessions/${sessionId}/materials`).then(response => response.data),

  getSessionMaterial: (sessionId, materialId) =>
    apiClient.get(`/curriculum-api/sessions/${sessionId}/materials/${materialId}`).then(response => response.data),

  getSessionMaterials: sessionId =>
    cacheableRequest(`/curriculum-api/sessions/${sessionId}/materials`).then(response => response.data),

  getMediaRequestObject: mediaId => {
    const requestConfig = apiClient.getConfig();
    return Object.assign({}, requestConfig, { url: `${requestConfig.baseURL}/curriculum-storage/files/${mediaId}` });
  },

  setSessionSchedule: (orgId, curriculumId, sessionId, scheduledDate, isScheduled, initiatingUserId) => {
    cacheService.removeAllInPath(`/v2/org/curriculum/${curriculumId}/sessions`);
    return generateUUID(id =>
      submitCommand(
        id,
        { id, orgId: { id: orgId }, curriculumId, sessionId, scheduledDate, isScheduled, initiatingUserId },
        'SetSessionCustomization',
        'SessionCustomizationSet',
        'SessionCustomizationFailed'
      )
    );
  },

  saveCurriculumVideo: (id, curriculumId, fileName, description, initiatingUserId) => {
    const cmd = { id, orgId: curriculumId, fileName, title: fileName, description, initiatingUserId };
    return submitCommand(id, cmd, 'SaveCurriculumVideo', 'VideoCreated');
  },

  isPreviewCurriculum: curriculumId => {
    const previewCurriculumIds = [];

    if (window.featureToggles.get('TGP5_PREVIEW')) {
      previewCurriculumIds.push(
        'a81264d5-ccd2-4c15-8c5f-5a0a11bd71f5',
        '04356cff-55eb-4737-b4dc-02c4af22860a',
        'c6d7c38b-75cc-46b9-b70c-583bf3be2c6a',
        'c2526c70-1209-44e4-9daf-cc3bc799647a'
      );
    }

    if (window.featureToggles.get('HYFI_PREVIEW')) {
      previewCurriculumIds.push(
        '5a380d7c-a3d3-406f-95c6-dce61050e531',
        '98ccfe74-f2d3-4c7a-bc27-6a4275bb43f4',
        'eb62f4d4-def6-472c-b360-20992eabcc28'
      );
    }

    if (window.featureToggles.get('ETB2025_PREVIEW')) {
      previewCurriculumIds.push(
        '5cc5bc7b-1d7f-45b5-b3e5-442bc3ef7464',
        'e44ddf01-7754-44c3-84e8-13d7d3c8a798',
        'a9ec57c9-83b2-48b5-9372-e4613f37fe12',
        'c089d120-26ba-4d5e-a60f-0417a677c6cd'
      );
    }

    return previewCurriculumIds.includes(curriculumId);
  },
};

export default curriculumService;
