import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { useUser } from '../../authentication';

import { getEmailTemplatePreview } from '../api/getEmailTemplatePreview';
import ErrorMessage from '../../components/ErrorMessage';
import LoadingIndicator from '../../components/LoadingIndicator';

const EmailPreviewWrapper = styled.div`
  > table {
    margin: 0 !important;
    width: 100%;
  }
`;

const BODY_KEY = '{bodyText}';
const BUTTON_KEY = '{buttonText}';

type Props = {
  templateValues: { [key: string]: string };
  templateConfig?: TemplateConfig;
  hideButton?: boolean;
  className?: string;
};

type Status = {
  isPending: boolean;
  isError: boolean;
  errors: Error[];
};

type TemplateConfig = {
  name: string;
  keys: { [key: string]: string };
};

const BlankTemplateConfig: TemplateConfig = {
  name: 'blank',
  keys: { bodyText: BODY_KEY, buttonText: BUTTON_KEY },
};

export const PreviewEmailTemplate = ({
  templateValues,
  templateConfig = BlankTemplateConfig,
  hideButton = false,
  className = '',
}: Props) => {
  const user = useUser();
  const [status, setStatus] = useState({ isPending: true, isError: false, errors: [] } as Status);
  const [template, setTemplate] = useState('');
  const [email, setEmail] = useState('');

  const removeButtonFromTemplate = (html: string) => {
    const el = document.createElement('template');
    el.innerHTML = html;
    el.content.querySelector('#button')?.remove();
    return el.innerHTML;
  };

  useEffect(() => {
    getEmailTemplatePreview(user?.lastSelectedAccount ?? '', templateConfig.name, templateConfig.keys)
      .then(response => {
        setTemplate(response?.emails[0]?.content?.html || '');
        setStatus({ isPending: false, isError: false, errors: [] });
      })
      .catch((error: Error) => {
        console.error(error);
        setStatus({ isPending: false, isError: true, errors: [error] });
      });
  }, []);

  useEffect(() => {
    setEmail(
      Object.keys(templateConfig.keys).reduce(
        (html, key) => html.replace(templateConfig.keys[key], templateValues[key]),
        hideButton ? removeButtonFromTemplate(template) : template
      )
    );
  }, [template, templateConfig.keys, templateValues, hideButton]);

  return status.isPending ? (
    <LoadingIndicator />
  ) : status.isError ? (
    <ErrorMessage>A problem occurred showing the email preview</ErrorMessage>
  ) : (
    <EmailPreviewWrapper dangerouslySetInnerHTML={{ __html: email }} className={className} />
  );
};
