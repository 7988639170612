angular.module('lwNamb').factory(
  'trainingService',

  [
    '$rootScope',
    'api',
    'qt',
    'uuid4',
    '$localStorage',
    function($rootScope, api, qt, uuid4, $localStorage) {
      return {
        getTraining: function(id) {
          return api.get('/v1/training/' + id).then(function(response) {
            return response.data;
          });
        },

        assign: function(orgId, initiatingUserId, assigneeIds, trainingItem, invitees, sendEmail) {
          var commandId = uuid4.generateId().id,
            training = trainingFrom(trainingItem),
            cmd = {
              id: commandId,
              training: training,
              assigneeIds: assigneeIds,
              orgId: {
                id: orgId
              },
              initiatingUserId: initiatingUserId,
              sendEmail: sendEmail
            };

          if(invitees && invitees.length > 0){
            cmd.invite = {
              invitees: invitees
            };
          }

          return assignTraining("AssignTraining", cmd).then(function (instances) {
            storePending(trainingItem, instances);
            return instances;
          });
        },

        selfAssign: function(userId, trainingItem, orgIdOpt) {
          try {
            window.cacheService.removeAllInPath(`/v1/trainingOverview`);
          } catch(e) { 
            console.error('Unable to clear API cache', e);
          }

          return assignTraining("SelfAssignTraining", {
            id: uuid4.generateId().id,
            userId: userId,
            orgId: orgIdOpt,
            training: trainingFrom(trainingItem)
          });
        },

        assignToGroup: function(orgId, initiatingUserId, groupId, trainingItem, sendEmail) {
          return assignTraining("AssignTrainingToGroup", {
            id: uuid4.generateId().id,
            orgId: {
              id: orgId
            },
            groupId: {
              id: groupId
            },
            training: trainingFrom(trainingItem),
            initiatingUserId: initiatingUserId,
            sendEmail: sendEmail
          });
        }
      };

      function trainingFrom(trainingItem) {
        var training;
        if (trainingItem.trainingType === 'Tasklist') {
          training = {
            id: {
              taskListId: {
                id: trainingItem.trainingId
              }
            }
          };
          if (trainingItem.date !== undefined) {
            training.dueDate = {
              year: trainingItem.date.year,
              month: trainingItem.date.month,
              dayOfMonth: trainingItem.date.dayOfMonth,
              timeZone: trainingItem.date.timeZone
            };
          }
        } else {
          training = {
            id: {
              workflowId: trainingItem.trainingId
            },
            entityOptions: {}
          };
          if (trainingItem.orderedTaskListsForWorkflow !== undefined) {
            trainingItem.orderedTaskListsForWorkflow.forEach(function(tl) {
              if (tl.date !== undefined) {
                tl.date._entityOptionType = 'DueDate';
                training.entityOptions[tl.entityId] = tl.date;
              }
            });
          }
        }
        return training;
      }

      function assignTraining(cmdName, cmd){
        var deferred = qt.defer();

        api.post('/v1/commands/' + cmdName, cmd).then(null, function(reason) {
          deferred.reject(reason);
        });

        $rootScope.$on('AssignSucceeded', function(name, event) {
          if (event.id === cmd.id) {
            deferred.resolve(event.instances);
          }
        });

        $rootScope.$on('AssignFailed', function(name, event) {
          if (event.id === cmd.id) {
            deferred.reject(event);
          }
        });

        return deferred.promise;
      }

      function storePending(trainingItem, instances) {
        if (instances !== undefined) {
          var assignedInstances = {
            name: trainingItem.trainingName,
            _type: trainingItem.trainingType,
            assignmentInstances: instances,
            originalLength: instances.length,
          };
          if ($localStorage.assignedInstances === undefined) {
            $localStorage.assignedInstances = [assignedInstances];
          } else {
            $localStorage.assignedInstances.push(assignedInstances);
          }
        }
      }
    },
  ]
);
