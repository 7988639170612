import { parseISO, addDays, sub } from 'date-fns';
import apiClient from '../services/apiClient';
import cacheableRequest from './cacheableRequest';
import cacheService from './cacheService';
import { CurriculumPricingVersion } from './curriculumService';
import submitCommand from './submitCommand';
import { generateUUID } from './uuid';

const transformLicense = license => {
  license.searchData = [
    license.item.title,
    license.owner.email,
    `${license.owner.firstName} ${license.owner.lastName}`,
  ];

  if (license.item.title) license.name = license.item.title;
  if (license.startDate) license.startDate = license.startDate.split('T')[0];

  if (license.endDate) {
    license.endDate = license.endDate.split('T')[0];
    license.expired = licenseService.hasExpired(license);
  }
  if (license.createdAt) license.createdAt = license.createdAt.split('T')[0];
  if (license.lastRenewalDate) license.lastRenewalDate = license.lastRenewalDate.split('T')[0];
  if (license.nextRenewalDate) license.nextRenewalDate = license.nextRenewalDate.split('T')[0];

  if (license.subscription) {
    if (license.subscription.period)
      license.subscription.period =
        license.subscription.period === 'Months' || license.subscription.period === 'Monthly' ? 'Monthly' : 'Yearly';

    if (license.subscription.lastRenewalDate)
      license.subscription.lastRenewalDate = license.subscription.lastRenewalDate.split('T')[0];
    if (license.subscription.nextRenewalDate)
      license.subscription.nextRenewalDate = license.subscription.nextRenewalDate.split('T')[0];
  }

  if (license.seats && license.seats.occupants)
    license.seats.occupants.forEach(occupant => {
      occupant.profileImageUrl = `${apiClient.getConfig().baseURL}/v1/images/profile/${occupant.id}profile.png`;
      license.searchData.concat([occupant.email, `${occupant.firstName} ${occupant.lastName}`]);
    });

  if (
    license.seats &&
    license.seats.count &&
    license.seats.count.total === undefined &&
    license.seats.isLimited === false
  ) {
    license.seats.count.total = 'Unlimited';
    if (license.subscription) license.subscription.modifiable = false;
  }

  if (license.item) {
    const format = license.item.mediumFormat;
    license.isGridSubscription = format === undefined || format === '' || format === 'Learning Object';
    if (license.isGridSubscription === false && !!license.subscription) {
      license.subscription.modifiable = false;
    }
  }

  return license;
};

const isGroupLicense = license => license.seats?.count.total !== 1;

const categorizeLicenses = (acc, license) => {
  const [groupLicenses, individualLicenses] = acc;
  isGroupLicense(license) ? groupLicenses.push(license) : individualLicenses.push(license);
  return [groupLicenses, individualLicenses];
};

const isHyfiPreviewLicense = license =>
  !!(['005842385', '005842386', '005842387'].includes(license.item?.number) && license.endDate?.includes('2023-07-06'));

const licenseService = {
  getIndividualLicenses: () => {
    const filterLicense = licenses => licenses.filter(licenseService.isIndividualLicense);

    return cacheableRequest('/v1/licenses?unpaired=true&itemChannel=TMG').then(response => {
      const licenses = response.data.licenses.map(transformLicense);
      return filterLicense(licenses);
    });
  },
  hasIndividualLicenseForItem: itemNumber => {
    return licenseService
      .getIndividualLicenses()
      .then(licenses => licenses.some(license => !license?.hasExpired && license?.item?.number === itemNumber));
  },
  getDefaultProductNumbers: () => {
    return apiClient
      .get('/v1/products/defaultProductNumbers')
      .then(response => response.data)
      .catch(err => err);
  },
  isIndividualLicense: license => !isGroupLicense(license),
  hasExpired: license => {
    if (isHyfiPreviewLicense(license)) return true;
    return !!license.endDate && addDays(parseISO(license.endDate), 1).getTime() < Date.now();
  },
  getPairedLicenses: orgId =>
    cacheableRequest(`/v1/licenses?detail=true&orgId=${orgId}`, 10).then(response =>
      response.data.licenses.map(transformLicense)
    ),
  getAllUnpairedLicenses: (bypassCacheLookup = false) =>
    Promise.allSettled([
      cacheableRequest('/v1/licenses?unpaired=true&itemChannel=TMG', 5 / 60, bypassCacheLookup),
      cacheableRequest('/v1/licenses?unpaired=true&itemChannel=CLC', 5 / 60, bypassCacheLookup),
    ]).then(([gridLicenses, curriculumLicenses]) =>
      []
        .concat(
          (gridLicenses.status === 'fulfilled' && gridLicenses.value.data.licenses.map(transformLicense)) || [],
          (curriculumLicenses.status === 'fulfilled' && curriculumLicenses.value.data.licenses.map(transformLicense)) ||
            []
        )
        .reduce(categorizeLicenses, [[], []])
    ),
  pairLicenses: (licenses, orgId, initiatingUserId) => {
    cacheService.removeAllInPath('/v1/licenses');
    return Promise.all(
      licenses.map(license =>
        submitCommand(
          license.id,
          { id: license.id, accountId: { id: orgId }, managerId: initiatingUserId, initiatingUserId: initiatingUserId },
          'PairLicense',
          'LicensePaired',
          'LicenseError'
        )
      )
    );
  },
  grantTrialLicense: (ownerId, orgId, itemNumber, zipCode, initiatingUserId) =>
    generateUUID(id =>
      submitCommand(
        id,
        { id, ownerId, orgId, itemNumber, zipCode, initiatingUserId },
        'GrantTrialLicense',
        'TrialLicenseGranted',
        'GrantTrialLicenseFailed'
      )
    ),

  // Curriculum-related
  isCurriculumLicense: license => !!license.item?.distributionChannelCodes.includes('CLC'),
  getCorrespondingCurriculum: (license, curriculumList) => {
    if (!licenseService.isCurriculumLicense(license)) return;

    return curriculumList.flatMap(crm =>
      crm.curriculum.flatMap(curriculum =>
        curriculum.license.flatMap(curriculumLicense =>
          curriculumLicense.itemNumber === license.item.number
            ? { ...crm, curriculum: { ...curriculum, license: curriculumLicense } }
            : []
        )
      )
    )[0];
  },

  // Subscription-related
  hasSubscription: license => !!license.subscription,
  isSubscriptionResubscribable: license => license.subscription?.modifiable === true,
  isActiveSubscription: license => license.subscription?.status === 'Active',
  isInactiveSubscription: license => license.subscription?.status === 'Inactive',
  isMonthlySubscription: license => license.subscription?.period === 'Monthly',
  canUpgradeDowngrade: license => false, // a placeholder for upgrade/downgrade functionality
  filterUniqueSubscriptions: licenses => {
    const subscriptions = [];
    return licenses.filter(l => {
      if (subscriptions.includes(l.subscription?.id)) return false;

      if (l.subscription?.id) subscriptions.push(l.subscription.id);
      return true;
    });
  },
  cancelSubscription: function (subscriptionId, reason, initiatingUserId) {
    cacheService.removeAllInPath('/v1/licenses');
    return submitCommand(
      subscriptionId,
      { id: subscriptionId, reason, initiatingUserId },
      'CancelSubscription',
      'SubscriptionCancelled',
      'CancelSubscriptionError'
    );
  },
  resumeSubscription: (subscriptionId, initiatingUserId) => {
    cacheService.removeAllInPath('/v1/licenses');
    return submitCommand(
      subscriptionId,
      { id: subscriptionId, initiatingUserId },
      'ResumeSubscription',
      'SubscriptionResumed',
      'ResumeSubscriptionError'
    );
  },
  getAnnualSubscriptionForUpgrade: itemNumber => {
    if (!itemNumber) return Promise.reject('Invalid item number');
    return cacheableRequest(
      `/v1/crm-sub-upgrade/${itemNumber}?version=${window.config.CURRICULUM_PRICING_VERSION}`
    ).then(response => response.data);
  },
  updateSubscriptionPeriod: (subscriptionId, orgId, newItemNumber, initiatingUserId) => {
    cacheService.removeAllInPath('/v1/licenses');
    return generateUUID(id =>
      submitCommand(
        id,
        {
          id,
          subscriptionId,
          orgId: { id: orgId },
          curriculumPricingVersion: window.config.CURRICULUM_PRICING_VERSION,
          newItemNumber,
          initiatingUserId,
        },
        'ChangeSubscriptionPeriod',
        'SubscriptionPeriodChanged',
        'SubscriptionPeriodChangeFailed'
      )
    );
  },
};

export default licenseService;
