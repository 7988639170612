import React from 'react';
import styled from 'styled-components';
import { Grid, Type } from '../../StyleGuide';
import { IconBadge } from '../../components/Badge';
import { Assignment } from '../models/assignment';
import windowService from '../../services/windowService';
import { ButtonGroup } from '../../components/Buttons';
import { InlineList } from '../../components/Layout';

type AssignmentCardDetailsProps = {
  assignment: Assignment;
  isActiveAssignment: boolean;
  actionButtons: React.JSX.Element;
};

const AssignmentName = styled.h4`
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`;

const AssignmentDetailsActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: ${Grid._4};
`;

const DetailsList = styled(InlineList)`
  margin-top: ${Grid._2};
  margin-bottom: ${Grid._2};
`;

const TinyInlineIconBadge = styled(IconBadge)`
  display: inline-flex;
  height: ${Grid._5};
  width: ${Grid._5};
  font-size: ${Type.Scale._2};
  margin-right: 0;
`;

const formatDate = (date: number): string => new Date(date).toLocaleDateString();

export default function AssignmentCardDetails({
  assignment,
  isActiveAssignment,
  actionButtons,
}: AssignmentCardDetailsProps) {
  const isActiveWorkFlow = assignment._type === 'ActiveWorkflowOverview';
  const isCompleteWorkFlow = assignment._type === 'CompleteWorkflowOverview';

  const assignmentLabelUpperCase =
    assignment._type === 'CompleteCourseOverview' || assignment._type === 'ActiveCourseOverview' ? 'Course' : 'Pathway';
  const isComplete = assignment.completedOn;

  const dueDate = assignment.dueDate && formatDate(assignment.dueDate);

  const training = isActiveWorkFlow ? assignment.activeCourse : assignment;
  const upNext = training?.upNext;

  const steps = training?.progress.steps;

  const label = isActiveAssignment ? 'step' : isCompleteWorkFlow ? 'step' : 'course';
  const stepLabel = steps && (steps.total > 1 ? label + 's' : label);

  function redirectToTask(assignment: Assignment) {
    // Save the current route before going to training
    localStorage.setItem('routeBeforeSteps', window.location.hash);

    if (isActiveWorkFlow || isCompleteWorkFlow) {
      windowService.redirectTo(
        '#/training-steps/' + assignment.instanceId + `?stepId=${upNext?.instanceId}&isWorkflow=true`
      );
    } else {
      windowService.redirectTo('#/training-steps/' + assignment.instanceId + `?stepId=${upNext?.instanceId}`);
    }
  }

  return (
    <AssignmentDetailsActionContainer>
      <div>
        <AssignmentName onClick={() => redirectToTask(assignment)}>{training?.name}</AssignmentName>
        <DetailsList>
          <li>
            {isActiveWorkFlow && (
              <TinyInlineIconBadge>
                <i className="fas fa-route" />
              </TinyInlineIconBadge>
            )}{' '}
            {assignmentLabelUpperCase}
          </li>
          {isComplete && assignment.completedOn && <li>Completed on {formatDate(assignment.completedOn)}</li>}
          {!isComplete && (
            <li>
              {steps?.completed} of {steps?.total} {stepLabel} complete
            </li>
          )}
          {dueDate && <li>Finish by {dueDate}</li>}
        </DetailsList>
      </div>

      <ButtonGroup>{actionButtons}</ButtonGroup>
    </AssignmentDetailsActionContainer>
  );
}
