import styled from 'styled-components';

type BibleStudyHeaderImgProps = {
  src: string;
  alt: string;
  background?: string;
};

const BibleStudyHeaderImg = styled.img<BibleStudyHeaderImgProps>`
  width: min(100%, 230px);
  height: 100px;
  object-fit: cover;
  border-radius: 4px;
  align-self: center;
  background: ${props => props.background || 'transparent'};
`;

export default BibleStudyHeaderImg;
