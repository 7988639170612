import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Breakpoints, Color, Grid } from '../StyleGuide';
import { User, useUser } from '../authentication';
import { WhiteButton } from '../components/Buttons';
import { Container } from '../components/Layout';
import LoadingState from '../components/LoadingState';
import alertService from '../services/AlertService';
import AngularServiceProvider from '../services/AngularServiceProvider';
import userService from '../services/userService';
import windowService from '../services/windowService';
import PendingInvitesError from './PendingInvitesError';
import { acceptOrgInvite } from './api/acceptOrgInvite';
import WhiteLabel from '../filters/WhiteLabel';

const Header = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${Grid._4} 0;
  background-color: ${Color.white};
`;

const Logo = styled.img`
  width: 210px;
`;

const InviteContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  @media (min-width: ${Breakpoints.screen_xs}) {
    flex-direction: column;
    padding: ${Grid._10} ${Grid._5} 0;
  }
`;

const InviteAction = styled.div`
  display: flex;
  flex-direction: column;
  align-items: normal;
  text-align: center;
  margin-bottom: ${Grid._7};

  @media (min-width: ${Breakpoints.screen_sm}) {
    align-items: center;
    padding: ${Grid._5};

    &:first-child {
      border-right: 1px solid ${Color.Gray._20};
    }
  }

  @media (min-width: ${Breakpoints.screen_md}) {
    padding: ${Grid._8};
  }

  p {
    margin-bottom: ${Grid._5};
  }

  button {
    min-width: 180px;
  }
`;

const InviteActionsGroup = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${Grid._8};

  @media (min-width: ${Breakpoints.screen_sm}) {
    flex-direction: row;
    margin-top: ${Grid._5};

    ${InviteAction} {
      max-width: 420px;
    }
  }
`;

type UrlParams = {
  forEmail: string;
  orgId: string;
};

type AngularUserService = {
  getRedirectHashAfterLogin: (user: User) => string;
  checkSession: () => Promise<boolean>;
};

export const AcceptInvite = () => {
  const { forEmail, orgId } = useParams<UrlParams>();
  const user = useUser();
  const [data, setData] = useState({
    invites: [],
    isError: false,
    isLoading: true,
    errorType: null,
  });
  useEffect(() => {
    if (forEmail === user?.logInEmail) {
      acceptInviteAndRedirect();
    } else {
      setData(prevData => ({ ...prevData, isLoading: false }));
    }
  }, []);
  //These angular services need to be updated once we are off of angular.
  const angularUserService = AngularServiceProvider.get('userService') as unknown as AngularUserService;

  const redirect = () => {
    windowService.redirectTo(angularUserService.getRedirectHashAfterLogin(user!));
  };

  const checkUserSession = () => {
    return angularUserService.checkSession();
  };

  const acceptInviteAndRedirect = () => {
    setData(prevData => ({ ...prevData, isLoading: true }));
    if (user?.userId && user?.logInEmail) {
      acceptOrgInvite(user?.userId, orgId, forEmail, user?.logInEmail, user?.userId)
        .then(() => {
          alertService.showAngularAlert('Invitation Accepted');
          return checkUserSession().then(redirect);
        })
        .catch(err => {
          console.error(err);
          setData(prevData => ({ ...prevData, isLoading: false, isError: true, errorType: err.code }));
        });
    } else {
      setData(prevData => ({ ...prevData, isLoading: false, isError: true }));
    }
  };

  return (
    <>
      <Header>
        <Logo src={WhiteLabel.logoSrc} alt={WhiteLabel.name} />
      </Header>
      <InviteContainer>
        {data.isLoading ? (
          <LoadingState>
            <span>Accepting Invitation...</span>
          </LoadingState>
        ) : data.isError ? (
          <PendingInvitesError errorType={data.errorType} skipInvite={redirect} />
        ) : (
          <>
            <h2>This invitation was sent to {forEmail}</h2>
            <p>
              You are logged in as <b>{user?.logInEmail}</b>
            </p>
            <InviteActionsGroup>
              <InviteAction>
                <p>
                  To accept this invitation as <b>{forEmail}</b>, log out and log in with that email address.
                </p>
                <WhiteButton onClick={userService.logout}>Log Out</WhiteButton>
              </InviteAction>
              <InviteAction>
                <p>
                  To accept this invitation and use <b>{user?.logInEmail}</b> with Ministry Grid
                </p>
                <WhiteButton onClick={acceptInviteAndRedirect}>Accept Invitation</WhiteButton>
              </InviteAction>
            </InviteActionsGroup>
          </>
        )}
      </InviteContainer>
    </>
  );
};
