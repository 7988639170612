import { Form, Formik, FormikHelpers } from 'formik';
import React from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import { OutlineButton } from '../../components/Buttons';
import { FormField, FormFieldContainer } from '../../components/FormElements';
import { Grid } from '../../StyleGuide';
import { Recipient } from '../models/recipient';

const FormContainer = styled.div`
  display: grid;
  gap: ${Grid._4};
  margin-bottom: ${Grid._4};

  ${FormFieldContainer} {
    margin-bottom: 0;
  }
`;

const FieldsContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: ${Grid._4};

  &:last-child {
    grid-template-columns: 1fr auto;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 28px; /* keep in line with email input */
`;

type RecipientFormProps = {
  recipients: Recipient[];
  addRecipient: (recipient: Recipient) => void;
};
export const AddRecipientForm = ({ recipients, addRecipient }: RecipientFormProps) => {
  const saveRecipient = (values: Recipient, { resetForm, setFieldError }: FormikHelpers<Recipient>) => {
    try {
      addRecipient(values);
      resetForm();
    } catch (e) {
      setFieldError('email', 'Recipient already invited');
    }
  };

  return (
    <Formik
      enableReinitialize={false}
      initialValues={{ firstName: '', lastName: '', email: '' }}
      validationSchema={Yup.object().shape({
        firstName: Yup.string().required('A first name is required'),
        lastName: Yup.string().required('A last name is required'),
        email: Yup.string()
          .email('Invalid email')
          .required('An email address is required')
          .test('isUnique', 'Email already added', email => !recipients.some(r => r.email === email)),
      })}
      onSubmit={saveRecipient}
    >
      <Form>
        <FormContainer>
          <FieldsContainer>
            <FormField name="firstName" id="firstName" label="First Name" placeholder="Enter First Name" />
            <FormField name="lastName" id="lastName" label="Last Name" placeholder="Enter Last Name" />
          </FieldsContainer>
          <FieldsContainer>
            <FormField name="email" id="email" label="Email" placeholder="Enter Email" />
            <ButtonContainer>
              <OutlineButton type="submit">
                <i className="fas fa-user-plus"></i> Add Recipient
              </OutlineButton>
            </ButtonContainer>
          </FieldsContainer>
        </FormContainer>
      </Form>
    </Formik>
  );
};
