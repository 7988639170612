import React from 'react';
import styled from 'styled-components';
import { Color, Grid } from '../StyleGuide';
import { Container } from '../components/Layout';
import windowService from '../services/windowService';
import { useParams } from 'react-router-dom';
import SubMenuTabs from '../components/SubMenuTabs';
import AssignedCurriculum from './AssignedCurriculum';
import CompletedTraining from './CompletedTraining';
import ActiveTraining from './ActiveTraining';
import { useUser } from '../authentication';
import CreateAChurchBanner from './components/CreateAChurchBanner';
import cacheService from '../services/cacheService';

const SubMenuContainerFluid = styled.div`
  z-index: 100;
  position: relative;
  background: ${Color.white};
  box-shadow: inset 0px 1px 2px rgba(70, 72, 84, 0.1);
  border-bottom: 1px solid ${Color.Gray._10};
`;

const TabContainer = styled(Container)`
  margin-top: ${Grid._6};
  margin-bottom: ${Grid._6};
`;

type TabPage = '' | 'training' | 'completed';

const getTabOptions = (isGrid: boolean, showsAssignedCurriculum: boolean) => [
  ...(isGrid && showsAssignedCurriculum
    ? [
        {
          id: '',
          label: 'Your Curriculum',
          icon: <i className="far fa-calendar-alt hidden-xs" />,
        },
      ]
    : []),
  {
    id: isGrid ? 'training' : '',
    label: 'Your Training',
    icon: <i className="fas fa-tasks hidden-xs" />,
  },
  {
    id: 'completed',
    label: 'Completed',
    icon: <i className="fas fa-history hidden-xs" />,
  },
];

interface TabContentProps {
  page: TabPage;
  showAssignedCurriculum: boolean;
}

const TabContent = ({ page, showAssignedCurriculum }: TabContentProps) => {
  switch (page) {
    case 'training':
      return <ActiveTraining />;
    case 'completed':
      return <CompletedTraining />;
    default:
      return showAssignedCurriculum ? <AssignedCurriculum /> : <ActiveTraining />;
  }
};

export function Dashboard() {
  const user = useUser();
  const orgId = user?.lastSelectedAccount || '';
  const { page = '' } = useParams<{ page?: TabPage }>();
  const isGrid = window.getOrigin() === 'GRID';
  const isGridUserWithOrg = isGrid && orgId !== '';

  const handleTabClick = (selectedPage: string) => {
    cacheService.set('redirectingThroughTabs', true);
    windowService.redirectTo(`/dashboard${selectedPage ? `/${selectedPage}` : ''}`);
  };

  const tabOptions = getTabOptions(isGrid, isGridUserWithOrg);

  const activeTab = !isGrid && page === 'training' ? '' : page;

  return (
    <>
      <SubMenuContainerFluid>
        <Container>
          <SubMenuTabs items={tabOptions} handleTabClick={handleTabClick} activeTab={activeTab} />
        </Container>
      </SubMenuContainerFluid>
      {!isGridUserWithOrg && <CreateAChurchBanner />}
      <TabContainer>
        <TabContent page={page} showAssignedCurriculum={isGridUserWithOrg} />
      </TabContainer>
    </>
  );
}
