import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Grid } from '../../StyleGuide';
import {
  TimelinesOverlay,
  CreateTimelineForm,
  CopyTimelineForm,
  RenameTimelineForm,
  DefaultLeaderGuideItem,
  TimelineList,
  EditableTimelineItem,
} from '../timeline/MultiTimelineOverlayElements';
import { ButtonGroup, Button, SmallRoundedIconButton } from '../../components/Buttons';
import EmptyState from '../../components/EmptyState';
import ConfirmationModal from '../../components/ConfirmationModal';
import sessionTimelineService from '../../services/sessionTimelineService';
import windowService from '../../services/windowService';
import alertService from '../../services/AlertService';
import useCurriculum from '../../hooks/useCurriculum';
import { useUser } from '../../authentication';
import { handleError } from '../../utils/apiUtils';
import { stopProp } from '../../utils/domUtils';
import { Timeline } from '../models/timeline';

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: ${Grid._6} 0;
`;

const EmptyStateContainer = styled.div`
  text-align: center;
`;

const getOriginalTimelineId = (timelines: Timeline[]) => {
  if (timelines?.length === 0) return;

  return timelines[0].originalTimelineId ?? timelines[0].id;
};

type LeaderTimelinesOverlayProps = {
  session: {
    sessionId: string;
    timelines: Timeline[];
    curriculumId: string;
  };
  updateSessionTimelines: (sessionId: string, timelines: any[]) => void;
  handleDismiss: () => void;
};

export default function LeaderTimelinesOverlay({
  session,
  updateSessionTimelines,
  handleDismiss,
}: LeaderTimelinesOverlayProps) {
  const user = useUser();
  const [form, setForm] = useState<React.ReactElement | null>(null);
  const [timelineToRemove, setTimelineToRemove] = useState<Timeline | null>(null);
  const [timelines, setTimelines] = useState<Timeline[]>(session.timelines);
  const [originalTimelineId] = useState(getOriginalTimelineId(session.timelines));
  const { brand, ageCategory } = useCurriculum();
  const cancelForm = () => setForm(null);
  const hasCustomization = timelines?.some(tl => !!tl.originalTimelineId);

  useEffect(() => {
    updateSessionTimelines(session.sessionId, timelines);
  }, [session, updateSessionTimelines, timelines]);

  const createNewTimeline = () => {
    setForm(
      <CreateTimelineForm
        originalTimelineId={originalTimelineId ?? ''}
        onSuccess={onTimelineCreated}
        onError={onTimelineActionFailed}
        onCancel={cancelForm}
      />
    );
  };

  const createTimelineCopy = (timeline: Timeline) => {
    setForm(
      <CopyTimelineForm
        timeline={timeline}
        onSuccess={onTimelineCreated}
        onError={onTimelineActionFailed}
        onCancel={cancelForm}
      />
    );
  };

  const renameTimeline = (timeline: Timeline) => {
    setForm(
      <RenameTimelineForm
        timeline={timeline}
        onSuccess={onTimelineRenamed}
        onError={onTimelineActionFailed}
        onCancel={cancelForm}
      />
    );
  };

  const onTimelineRenamed = (timeline: Timeline) => {
    alertService.show('Leader Guide Updated');

    if (timeline) {
      const { id, name } = timeline;
      setTimelines(prev => prev.map(tl => (tl.id === id ? { ...tl, name } : tl)));
    }

    setForm(null);
  };

  const onTimelineCreated = ({ id, timelineId: originalTimelineId }: { id: string; timelineId: string }) => {
    alertService.showOnNextPage('Leader Guide Created');
    redirectToTimeline({ id, originalTimelineId } as Timeline);
  };

  const onTimelineActionFailed = (error: Error) => {
    handleError(error);
    handleDismiss();
  };

  const redirectToTimeline = (timeline: Timeline) => {
    windowService.redirectTo(
      `/manage-bible-study/schedule/${brand.code}/${ageCategory}/${session.curriculumId}/${session.sessionId}/timeline/${timeline.originalTimelineId}/${timeline.id}`
    );
  };

  const shouldShowDefaultLeaderGuide = () => {
    return originalTimelineId && (timelines.length === 0 || !hasCustomization);
  };

  const removeTimeline = (timeline: Timeline) => {
    sessionTimelineService
      .deleteCustomizedTimeline(timeline.originalTimelineId, timeline.id, user?.lastSelectedAccount, user?.userId)
      .then(() => {
        alertService.show('Leader Guide Removed');
        setTimelineToRemove(null);
        setTimelines(prev => {
          const updatedTimelines = prev.filter(tl => tl.id !== timeline.id);

          if (updatedTimelines.length === 0) return [sessionTimelineService.createDefaultTimeline(originalTimelineId)];

          return updatedTimelines;
        });
      })
      .catch(error => {
        handleError(error);
        handleDismiss();
      });
  };

  return (
    <TimelinesOverlay session={session} handleDismiss={handleDismiss}>
      {!form ? (
        <>
          {originalTimelineId && (
            <ButtonContainer>
              <Button onClick={createNewTimeline} data-tracking-id={'add-leader-guide'}>
                Add Leader Guide
              </Button>
            </ButtonContainer>
          )}
          {shouldShowDefaultLeaderGuide() ? (
            <DefaultLeaderGuideItem
              timelineId={originalTimelineId ?? ''}
              onSuccess={redirectToTimeline}
              onError={onTimelineActionFailed}
            />
          ) : timelines?.length ? (
            <TimelineList>
              {timelines.map(timeline => (
                <EditableTimelineItem key={timeline.id} onClick={() => redirectToTimeline(timeline)}>
                  <div>
                    <h5>{timeline.name}</h5>
                  </div>
                  <ButtonGroup>
                    <SmallRoundedIconButton aria-label="copy" onClick={stopProp(() => createTimelineCopy(timeline))}>
                      <i className="fas fa-copy"></i>
                    </SmallRoundedIconButton>
                    <SmallRoundedIconButton aria-label="edit" onClick={stopProp(() => renameTimeline(timeline))}>
                      <i className="fas fa-pencil-alt"></i>
                    </SmallRoundedIconButton>
                    <SmallRoundedIconButton aria-label="remove" onClick={stopProp(() => setTimelineToRemove(timeline))}>
                      <i className="far fa-trash-alt"></i>
                    </SmallRoundedIconButton>
                  </ButtonGroup>
                </EditableTimelineItem>
              ))}
            </TimelineList>
          ) : (
            <EmptyStateContainer>
              <EmptyState
                title="No Leader Guides for This Session"
                description="Don't worry, a leader guide will be added soon"
              />
            </EmptyStateContainer>
          )}
        </>
      ) : (
        form
      )}

      {timelineToRemove && (
        <ConfirmationModal
          buttonActionText="Remove"
          buttonType="danger"
          title="Remove Leader Guide"
          prompt={<span>Are you sure you want to remove this leader guide?</span>}
          subtext={timelineToRemove.name}
          handleSubmit={() => removeTimeline(timelineToRemove)}
          handleDismiss={() => setTimelineToRemove(null)}
        />
      )}
    </TimelinesOverlay>
  );
}
