angular.module('lwNamb').controller(
  'errorCtrl',

  [
    '$rootScope',
    '$scope',
    'api',
    '$interval',
    '$localStorage',
    function($rootScope, $scope, api, $interval, $localStorage) {
      function checkConnection() {
        api.get('/system-info/version').then(function() {
          if ($scope.successThreshold > 4 || $localStorage.currentPage === undefined) {
            $rootScope.$broadcast('siteIsUp');
            $interval.cancel($scope.checkApiStatus);
            $scope.successThreshold = 0;
          } else {
            $scope.successThreshold += 1;
          }
        });
      }
      var init = function() {
        $scope.grid = false;
        $scope.transformation = false;
        $scope.idlewildchurch = false;
        $scope.bellevue = false;
        $scope.mbcb = false;
        $scope.connexus = false;
        $scope.centrikid = false;
        $scope.fuge = false;
        $scope.studentlife = false;
        $scope.sbc = false;
        $scope.a21 = false;

        $scope.successThreshold = 0;

        // Change manually based on scheduled maintenance
        $scope.maintenance = false;

        switch ($scope.origin) {
          case 'IDLEWILD':
            $scope.idlewildchurch = true;
            $scope.siteName = 'Idlewild Church Training';
            break;
          case 'BELLEVUE':
            $scope.bellevue = true;
            $scope.siteName = 'Bellevue Training';
            break;
          case 'MBCB':
            $scope.mbcb = true;
            $scope.siteName = 'MBCB Training';
            break;
          case 'TRANSFORMATION':
            $scope.transformation = true;
            $scope.siteName = 'Transformation Church';
            break;
          case 'CONNEXUS':
            $scope.connexus = true;
            $scope.siteName = 'Connexus Church Training';
            break;
          case 'CENTRIKID':
            $scope.centrikid = true;
            $scope.siteName = 'CentriKid Training Online';
            break;
          case 'FUGE':
            $scope.fuge = true;
            $scope.siteName = 'FUGE Training Online';
            break;
          case 'STUDENTLIFE':
            $scope.studentlife = true;
            $scope.siteName = 'Student Life Camp Training Online';
            break;
          case 'SBC':
            $scope.sbc = true;
            $scope.siteName = 'SBC Training & Orientation Portal';
            break;
          case 'A21':
            $scope.a21 = true;
            $scope.siteName = 'A21 Training Center';
            break;
          default:
            $scope.grid = true;
            $scope.siteName = 'Ministry Grid';
        }

        checkConnection();//check on initial page load to avoid a 2 second wait
        $scope.checkApiStatus = $interval(checkConnection, 2000);

        $scope.$on('$destroy', function() {
          $interval.cancel($scope.checkApiStatus);
        });
      };

      init();
    },
  ]
);
