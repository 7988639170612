angular.module('lwNamb').factory(
  'catalogService',

  [
    'api',
    'CacheFactory',
    'qt',
    '$rootScope',
    function(api, CacheFactory, qt, $rootScope) {
      var timeoutSeconds = 15,
        catalogsCache = CacheFactory('catalogsCache', {
          maxAge: 60 * 1000 * 10, // 10 minute
          deleteOnExpire: 'aggressive',
        }),
        pendingCatalogsPromise;

      return {
        getCatalogs: function() {
          return getAll();
        },
        getCatalogForSite: function(origin) {
          return getForSite(origin !== undefined ? origin : $rootScope.origin);
        },
        getCatalogsForDropdown: function(origin) {
          var deferred = qt.defer({ timeoutSeconds: timeoutSeconds });
          function onlyUnique(value, index, self) {
            return self.indexOf(value) === index;
          }
          getForSite(origin).then(function(siteCatalog) {
            getForSite('GRID').then(function(gridCatalog) {
              deferred.resolve([siteCatalog, gridCatalog].filter(onlyUnique));
            });
          });
          return deferred.promise;
        },
        getShortCode: function(origin) {
          return getShortCodeByOrigin(origin);
        },
      };

      function getAll() {
        if (pendingCatalogsPromise) {
          return pendingCatalogsPromise;
        }
        var deferred = qt.defer({ timeoutSeconds: timeoutSeconds });
        if (catalogsCache.get('catalogs') === undefined) {
          pendingCatalogsPromise = deferred.promise;
          api.get('/catalogs').then(
            function(response) {
              catalogsCache.put('catalogs', response.data);
              deferred.resolve(response.data);
              pendingCatalogsPromise = null;
            },
            function() {
              pendingCatalogsPromise = null;
            }
          );
        } else {
          deferred.resolve(catalogsCache.get('catalogs'));
        }
        return deferred.promise;
      }

      function getShortCodeByOrigin(origin) {
        switch (origin) {
          case 'TRANSFORMATION': return 'TC';
          case 'IDLEWILD': return 'IW';
          case 'BELLEVUE': return 'BT';
          case 'MBCB': return 'MB';
          case 'CONNEXUS': return 'CX';
          case 'CENTRIKID': return 'CK';
          case 'FUGE': return 'FUGE';
          case 'STUDENTLIFE': return 'SL';
          case 'SBC': return 'SBC';
          case 'A21': return 'A21';
          default: return 'MG';
        }
      }

      function getForSite(origin) {
        var deferred = qt.defer({ timeoutSeconds: timeoutSeconds });

        getForShortCode(getShortCodeByOrigin(origin));

        function getForShortCode(code) {
          getAll().then(function(catalogs) {
            var c = catalogs.filter(function(catalog) {
              return catalog.shortCode === code;
            });
            if (c.length > 0) deferred.resolve(c[0]);
            else deferred.resolve({});
          });
        }

        return deferred.promise;
      }
    },
  ]
);
