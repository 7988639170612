import React from 'react';
import styled from 'styled-components';
import { Border, Breakpoints, Color, Grid, Shadow, Type } from '../../StyleGuide';
import ProgressBarContainer from '../../components/ProgressBarContainer';
import ProgressBar from '../../components/ProgressBar';
import TrainingPosterPlaceholder from '../../training/TrainingPosterPlaceholder';
import { Assignment } from '../models/assignment';
import AssignmentFooter from './AssignmentCardFooter';
import windowService from '../../services/windowService';
import AssignmentCardDetails from './AssignmentCardDetails';
import AssignmentCardDownloads from './AssignmentCardDownloads';

type AssignmentCardProps = {
  assignment: Assignment;
  isActiveAssignment: boolean;
  assigneeUserId: string;
  actionButtons: React.JSX.Element;
};

const PosterContainer = styled.div`
  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    display: flex;
    min-width: 300px;
    text-align: center;
    align-items: start;
  }
`;

const AssignmentCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: ${Shadow.small};
  border: 1px solid rgba(0, 0, 0, 0.075);
  border-radius: ${Border.radius};
  background: white;

  h4 {
    line-height: ${Type.Leading.normal};
    margin: 0 ${Grid._4} 0 0;
    font-weight: ${Type.Weight.bold};
    word-break: break-word;

    a {
      color: ${Color.Gray._90};
      display: block;
      &:hover {
        text-decoration: underline;
      }
    }

    @media screen and (min-width: ${Breakpoints.screen_sm}) {
      line-height: ${Type.Leading.tall};
    }
  }

  ul {
    list-style: none;
    padding: 0;
  }

  li {
    color: ${Color.Gray._70};
  }
`;

const AssignmentCardHeader = styled.div`
  padding: ${Grid._4};
  background: ${Color.Gray._01};
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  color: ${Color.Gray._70};
`;

const AssignmentDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const AssignmentCardContent = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${Grid._4};
  gap: ${Grid._4};
  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    flex-direction: row;
  }
`;

const redirectToTask = (assignment: Assignment, isActivePathway: boolean, upNext: any) => {
  localStorage.setItem('routeBeforeSteps', window.location.hash);

  if (isActivePathway) {
    windowService.redirectTo(
      '#/training-steps/' + assignment.instanceId + `?stepId=${upNext?.instanceId}&isWorkflow=true`
    );
  } else {
    windowService.redirectTo('#/training-steps/' + assignment.instanceId + `?stepId=${upNext?.instanceId}`);
  }
};

const AssignmentInfo = ({
  assignment,
  isActiveAssignment,
  actionButtons,
  assigneeUserId,
  trainingProgress,
}: {
  assignment: Assignment;
  isActiveAssignment: boolean;
  actionButtons: React.JSX.Element;
  assigneeUserId: string;
  trainingProgress?: number;
}) => {
  return (
    <AssignmentCardContent>
      <PosterContainer>
        <TrainingPosterPlaceholder />
      </PosterContainer>
      <AssignmentDetailsContainer>
        <div style={{ flex: 1 }}>
          <AssignmentCardDetails
            assignment={assignment}
            isActiveAssignment={isActiveAssignment}
            actionButtons={actionButtons}
          />
          {assignment._type === 'CompleteWorkflowOverview' ? (
            <ProgressBarContainer>
              {assignment.completedCourses?.map((e, i) => (
                <ProgressBar
                  isPathway={true}
                  key={`${assignment.instanceId}_${i}`}
                  progress={100}
                  className="wf-progress-bar"
                />
              ))}
            </ProgressBarContainer>
          ) : (
            <ProgressBar progress={trainingProgress ?? 0} className="tl-progress-bar" />
          )}
        </div>
        <AssignmentCardDownloads assignment={assignment} assigneeUserId={assigneeUserId} />
      </AssignmentDetailsContainer>
    </AssignmentCardContent>
  );
};

export default function AssignmentCard({
  assignment,
  isActiveAssignment,
  assigneeUserId,
  actionButtons,
}: AssignmentCardProps) {
  const isActivePathway = assignment._type === 'ActiveWorkflowOverview';

  const training = isActivePathway ? assignment.activeCourse : assignment;
  const upNext = training?.upNext;
  const trainingProgress = training?.progress.percent;

  const completedCourses = assignment.progress.steps.completed;
  const totalCourses = assignment.progress.steps.total;

  return (
    <AssignmentCardContainer data-qa-hook="assignmentCard">
      {isActivePathway && (
        <AssignmentCardHeader>
          <span>
            Course {completedCourses + 1} of {totalCourses} in {assignment.name}
          </span>
        </AssignmentCardHeader>
      )}
      <AssignmentInfo
        assignment={assignment}
        isActiveAssignment={isActiveAssignment}
        actionButtons={actionButtons}
        assigneeUserId={assigneeUserId}
        trainingProgress={trainingProgress}
      />
      <AssignmentFooter
        assignment={assignment}
        redirectToTask={assignment => redirectToTask(assignment, isActivePathway, upNext)}
        upNext={upNext}
      />
    </AssignmentCardContainer>
  );
}
