import React, { useEffect, useState } from 'react';
import videojs from 'video.js';
import BrightcoveVideoPlayer from '../components/BrightcoveVideoPlayer';
import vs from '../services/VideoService';
import brightcoveTrackingService from '../services/BrightcoveTrackingService';
import uuid from '../services/uuid';
import { handleError } from '../utils/apiUtils';

type VideoPlayerProps = {
  defaultRate?: number;
  defaultAudioOnly?: boolean;
  defaultAutoplay?: boolean;
  autoExitFullscreen?: boolean;
  onExitFullscreen?: () => void;
  onProgress?: (progress: { percentComplete: number; elapsedSeconds: number; totalDuration: number }) => void;
  setIsLoading: (isLoading: boolean) => void;
  videoId: string;
  onEnd?: () => void;
  userId?: string;
};

type VideoResponse = {
  videoId: string;
  accountId: string;
  videoSources: {
    mp4: {
      https: string;
    };
  };
};

export default function VideoPlayer({
  defaultRate = 1,
  defaultAudioOnly = false,
  defaultAutoplay = false,
  autoExitFullscreen = true,
  onExitFullscreen,
  onProgress,
  ...props
}: VideoPlayerProps) {
  const savedVideoPlayerRate = localStorage.getItem('videoPlayerRate');
  const savedVideoPlayerAudioOnly = localStorage.getItem('videoPlayerAudioOnly');

  const init_audioOnly = savedVideoPlayerAudioOnly ? savedVideoPlayerAudioOnly !== 'false' : defaultAudioOnly;
  const init_rate = savedVideoPlayerRate ? Number(savedVideoPlayerRate) : defaultRate;
  const init_autoplay = defaultAutoplay;

  const [audioOnly, setAudioOnly] = useState<boolean>(init_audioOnly);
  const [progress, setProgress] = useState<number>(0);
  const [autoplay, setAutoplay] = useState<boolean>(init_autoplay);
  const [rate, setRate] = useState<number>(init_rate);
  const [fallback, setFallback] = useState<string>();
  const [tracking_videoId, setTracking_VideoId] = useState<string>();
  const [poster, setPoster] = useState<string>();
  const [videoInSeconds, setVideoInSeconds] = useState<number>();
  const [brightcoveVideo, setBrightcoveVideo] = useState<VideoResponse>();

  let tracking_duration = 0,
    tracking_progress = 0,
    isInitialProgressSet = false;
  const bcSessionTrackingId = uuid.generate();

  useEffect(() => {
    const load = () => {
      Promise.all([vs.getProgress(props.videoId), vs.getVideoPlaybackById(props.videoId), vs.getPoster(props.videoId)])
        .then(([videoProgress, video, videoPoster]) => {
          const videoFallback = video.videoSources.mp4.https;

          setPoster(videoPoster);
          setTracking_VideoId(video.videoId);
          brightcoveTrackingService.playerLoad(video.videoId, bcSessionTrackingId);
          setFallback(videoFallback);
          setProgress(videoProgress);
          setBrightcoveVideo(video);
        })
        .catch(error => {
          if (error instanceof Error) {
            handleError(error);
          }
        })
        .finally(() => {
          props.setIsLoading(false);
        });
    };
    load();
  }, [props.videoId, props.setIsLoading]);

  useEffect(() => {
    if (onProgress && videoInSeconds !== undefined) {
      onProgress({
        percentComplete: progress / videoInSeconds,
        elapsedSeconds: progress,
        totalDuration: videoInSeconds,
      });
    }
  }, [progress, onProgress, videoInSeconds]);

  const onTimeUpdated = (currentTime: number) => {
    const t = Math.floor(currentTime);
    setProgress(t);
    if (t !== 0 && t % 15 === 0 && progress !== t) {
      tracking_progress = t;
      vs.saveProgress(props.userId || '', props.videoId, t);
      brightcoveTrackingService.videoEngagement(
        tracking_videoId || '',
        bcSessionTrackingId,
        t - 15,
        t,
        tracking_duration
      );
    }
  };

  const audioOnlyToggle = (ao: boolean) => {
    setAutoplay(true);
    setAudioOnly(prev => !prev);
    localStorage.setItem('videoPlayerAudioOnly', ao.toString());
    brightcoveTrackingService.videoView(tracking_videoId || '', bcSessionTrackingId);
  };

  const rateChange = (r: number) => {
    setRate(r);
    localStorage.setItem('videoPlayerRate', r.toString());
  };

  const setInitialProgress = (player: videojs.Player) => {
    if (!isInitialProgressSet) {
      if (!progress || player.currentTime() >= progress) {
        isInitialProgressSet = true;
      } else {
        player.currentTime(progress);
        player.controls(true);

        isInitialProgressSet = player.currentTime() >= progress;
      }
    }

    return isInitialProgressSet;
  };

  const audioVideoMenu = (player: videojs.Player) => {
    const ButtonComponent = videojs.getComponent('Button');
    const settingsCog = new ButtonComponent(player);

    settingsCog.addClass('vjs-icon-cog');
    settingsCog.addClass('vjs-menu-button-popup');
    settingsCog.addClass('vjs-menu-button');

    player.controlBar.addChild(settingsCog, {}, 12);

    const settingsButton = document.querySelector('.vjs-icon-cog');
    (document.querySelector('.vjs-fullscreen-control') as HTMLElement).style.display = 'block';

    const menuWrapper = document.createElement('div');
    const ul = document.createElement('ul');
    const videoLi = document.createElement('li');
    const audioLi = document.createElement('li');
    videoLi.appendChild(document.createTextNode('Video'));
    audioLi.appendChild(document.createTextNode('Audio Only'));
    ul.append(videoLi, audioLi);

    menuWrapper.classList.add('vjs-menu');
    ul.classList.add('vjs-menu-content');
    videoLi.classList.add('vjs-menu-item');
    audioLi.classList.add('vjs-menu-item');
    if (audioOnly) {
      audioLi.classList.add('vjs-selected');
      player.addClass('vjs-audio');
    } else {
      videoLi.classList.add('vjs-selected');
    }
    menuWrapper.appendChild(ul);
    player.removeClass('bc-player-default_default');

    videoLi.addEventListener('click', () => {
      audioOnlyToggle(false);
      videoLi.classList.add('vjs-selected');
      audioLi.classList.remove('vjs-selected');
      player.audioOnlyMode(false);

      player.removeClass('vjs-audio');
    });
    audioLi.addEventListener('click', () => {
      audioOnlyToggle(true);
      audioLi.classList.add('vjs-selected');
      videoLi.classList.remove('vjs-selected');
      player.audioOnlyMode(true);

      player.addClass('vjs-audio');
    });

    settingsButton!.appendChild(menuWrapper);

    settingsButton!.addEventListener('mouseover', () => {
      settingsButton?.classList.add('vjs-hover');
    });

    settingsButton!.addEventListener('mouseout', () => {
      settingsButton?.classList.remove('vjs-hover');
    });

    settingsButton!.addEventListener('touchstart', () => {
      audioOnlyToggle(!audioOnly);

      if (document.querySelector('.vjs-audio')) {
        player.audioOnlyMode(false);
        player.removeClass('vjs-audio');
      } else {
        player.audioOnlyMode(true);
        player.addClass('vjs-audio');
      }
    });
  };

  const setup = () => {
    let myPlayer!: videojs.Player;
    let seekingEvent = false;

    try {
      const id = `video-${brightcoveVideo?.videoId || ''}`;
      myPlayer = videojs(id);

      audioVideoMenu(myPlayer);

      myPlayer.on('loadedmetadata', () => {
        tracking_duration = parseInt(myPlayer.duration().toString(), 10);
        const minutes = parseInt((myPlayer.duration() / 60).toString(), 10);
        setVideoInSeconds(minutes * 60);
        if (minutes < 2 && myPlayer.src() !== fallback) {
          myPlayer.src(fallback!);
        }
        if (rate !== undefined) {
          myPlayer.playbackRate(rate);
        }
        brightcoveTrackingService.videoImpression(tracking_videoId || '', bcSessionTrackingId);
      });
      myPlayer.on('canplay', () => {
        if (seekingEvent) {
          seekingEvent = false;
        }
      });
      myPlayer.on('seeking', () => {
        seekingEvent = true;
      });
      myPlayer.on('timeupdate', () => {
        onTimeUpdated(myPlayer.currentTime());
      });
      myPlayer.on('canplaythrough', () => {
        if (setInitialProgress(myPlayer)) myPlayer.off('canplaythrough');
      });
      myPlayer.on('play', () => {
        brightcoveTrackingService.playRequest(tracking_videoId || '', bcSessionTrackingId);
        brightcoveTrackingService.videoView(tracking_videoId || '', bcSessionTrackingId);
      });
      myPlayer.on('ended', () => {
        if (myPlayer.isFullscreen()) {
          myPlayer.exitFullscreen();
          myPlayer.exitFullWindow();
        }

        brightcoveTrackingService.videoEngagement(
          tracking_videoId || '',
          bcSessionTrackingId,
          tracking_progress,
          tracking_duration,
          tracking_duration
        );

        vs.saveProgress(props.userId || '', props.videoId, 0);
        setProgress(0);
        if (props.onEnd) props.onEnd();
      });
      myPlayer.on('ratechange', () => {
        rateChange(myPlayer.playbackRate());
      });

      myPlayer.on('fullscreenchange', () => {
        if (!myPlayer.isFullscreen() && onExitFullscreen) {
          onExitFullscreen();
        }
      });
    } catch (e) {
      console.error('Video error: ' + e);
    }
    return myPlayer;
  };

  return (
    <div>
      {brightcoveVideo?.videoId && (
        <BrightcoveVideoPlayer
          poster={poster ? poster : ''}
          videoId={brightcoveVideo.videoId}
          accountId={brightcoveVideo.accountId}
          audioOnly={audioOnly}
          autoPlay={autoplay}
          setup={setup}
        />
      )}
    </div>
  );
}
