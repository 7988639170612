import React, { useState } from 'react';
import styled from 'styled-components';
import { Grid, Color, Type, Breakpoints, Border } from '../../StyleGuide';

import { MediumButton, PrimaryButton } from '../../components/Buttons';
import limitFilter from '../../filters/limit-filter';
import { Person } from '../models/license';
import ProfileImage from '../../components/ProfileImage';
import { TextFilterInput } from '../../components/FormElements';
import windowService from '../../services/windowService';
import { useParams } from 'react-router-dom';
import ConfirmationModal from '../../components/ConfirmationModal';
import { ModalWarningList } from '../../components/ModalElements';

const Table = styled.table`
  width: 100%;
  transition: opacity 0.25s ease-out;
  opacity: 1;
  margin-top: ${Grid._5};
  @media screen and (min-width: ${Breakpoints.screen_md}) {
    margin-top: 0;
  }
  &.isUpdating {
    opacity: 0.8;
  }
  tbody {
    display: grid;
    grid-gap: ${Grid._4};
    @media screen and (min-width: ${Breakpoints.screen_sm}) {
      grid-template-columns: 1fr 1fr;
    }
    @media screen and (min-width: ${Breakpoints.screen_md}) {
      grid-gap: 0;
      grid-template-columns: 1fr;
    }
  }
  tr {
    order: 1;
    display: flex;
    flex-direction: column;
    border: 1px solid ${Color.Blue._15};
    border-radius: ${Border.radius};
    color: ${Type.Color.medium};
    align-items: center;
    padding: ${Grid._4};
    @media screen and (min-width: ${Breakpoints.screen_md}) {
      display: grid;
      grid-template-columns: 48px 1fr 1fr 168px;
      grid-column-gap: ${Grid._5};
      border-radius: 0;
      padding: 0;
      border-top: 0px;
      border-left: 0px;
      border-right: 0px;
    }
  }
  td {
    width: 100%;
    text-align: center;
    padding: ${Grid._2};
    @media screen and (min-width: ${Breakpoints.screen_md}) {
      padding: ${Grid._4} 0;
      text-align: left;
      width: auto;
    }
  }
  .group-person-row__profile-img {
    padding-bottom: ${Grid._1};
    @media screen and (min-width: ${Breakpoints.screen_md}) {
      padding-left: ${Grid._3};
    }
    span,
    img {
      font-size: ${Grid._8};
      position: relative;
      width: ${Grid._8};
      height: ${Grid._8};
      @media screen and (min-width: ${Breakpoints.screen_md}) {
        font-size: ${Grid._6};
        width: ${Grid._6};
        height: ${Grid._6};
      }
    }
  }
  .group-person-row__name {
    color: ${Type.Color.dark};
    font-weight: ${Type.Weight.semibold};
    padding: ${Grid._2} 0;
    @media screen and (min-width: ${Breakpoints.screen_md}) {
      text-align: left;
      padding: 0;
    }
  }
  .group-person-row__email {
    padding: ${Grid._2} 0;
    @media screen and (min-width: ${Breakpoints.screen_md}) {
      padding: 0;
    }
  }

  button {
    width: 100%;
  }
`;

export const ButtonRowWrapper = styled.div`
  display: flex;
  margin-left: auto;

  & > * {
    margin: ${Grid._2};
    padding: ${Grid._2};
  }
`;

type ModalProps = {
  show: boolean;
  person: Person | null;
};

const PeopleOnLicensesList = ({
  allLicenses,
  isUpdating,
  query,
  setQuery,
  handleRemovePerson,
}: {
  allLicenses: Person[];
  isUpdating: boolean;
  query: string;
  setQuery: (value: string) => void;
  handleRemovePerson: (userId: string) => void;
}) => {
  const { licenseId } = useParams<{ licenseId: string }>();
  const [removeModal, setRemoveModal] = useState<ModalProps>({ show: false, person: null });

  return (
    <>
      <div className={`grid-container grid-gap-16 grid-sm-col-5 add-people-controls`}>
        <div className="primary-action">
          <PrimaryButton
            data-qa-hook="addPeopleButton"
            onClick={() => windowService.redirectTo(`#/org/purchases-and-subscriptions/${licenseId}/add`)}
          >
            Add People
          </PrimaryButton>
        </div>

        <div className={`grid-sm-col-span-4`}>
          <TextFilterInput
            id="addPeopleFilter"
            value={query || ''}
            onChangeHandler={(e: any) => setQuery(e.target.value)}
            clearInput={() => {
              setQuery('');
            }}
            placeholder={'Search People'}
          />
        </div>
      </div>
      <Table className={'group-people-list ' + (isUpdating ? 'isUpdating ' : '')}>
        <thead>
          <tr>
            <th></th>
            <th>Name</th>
            <th className="text-center">Email Address</th>
            <th></th>
            <th></th>
          </tr>
        </thead>
        <tbody data-qa-hook="groupDetailResultList">
          {allLicenses.map((person, index) => {
            const rowClassName = 'group-person-row ';
            return (
              <tr key={index} className={rowClassName}>
                <td className="group-person-row__profile-img">
                  <ProfileImage src={person.profile?.imageUrl || ''} size={Grid._7} />
                </td>

                <td data-qa-hook="groupDetailResultName" className="group-person-row__name">
                  {person.displayName}
                </td>
                <td data-qa-hook="groupDetailResultEmail" className="group-person-row__email">
                  {limitFilter(person.email)}
                </td>
                <td data-qa-hook="groupDetailResultRole" className="group-person-row__role">
                  <span></span>
                </td>
                <td className="group-people-list_quick-actions">
                  <MediumButton
                    data-qa-hook="groupDetailResultRemove"
                    onClick={() => setRemoveModal({ show: true, person: person })}
                  >
                    Remove
                  </MediumButton>
                </td>
              </tr>
            );
          })}
        </tbody>
      </Table>
      {removeModal.show && (
        <ConfirmationModal
          buttonActionText="Remove"
          buttonType="danger"
          title={`Remove from License`}
          prompt={<span>Are you sure you want to remove this person from the license?</span>}
          subtext={removeModal.person?.displayName}
          handleSubmit={() => {
            if (removeModal.person) handleRemovePerson(removeModal.person?.id);
            setRemoveModal({ show: false, person: null });
          }}
          handleDismiss={() => setRemoveModal({ show: false, person: null })}
        >
          <ModalWarningList warnings={['This action will cause training associated with this license to be locked']} />
        </ConfirmationModal>
      )}
    </>
  );
};

export default PeopleOnLicensesList;
