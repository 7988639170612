import React, { useState, useEffect } from 'react';
import { react2angular } from 'react2angular';
import { Grid } from '../StyleGuide';
import { ContainerFluid, Container } from '../components/Layout';
import TrainingSubMenu from '../components/TrainingSubMenu';
import LoadingState from '../components/LoadingState';
import ErrorMessage from '../components/ErrorMessage';
import SectionTitle from '../components/SectionTitle';
import SearchBar from './SearchBar';
import ViewAllTraining from './ViewAllTraining';
import FeatureCarousel from './FeatureCarousel';
import { getButtonLinkType, redirectToTrainingLinkUrl, redirectToSearchLinkUrl } from '../components/CatalogElements';
import { CarouselItem } from '../components/CarouselElements';
import TrainingCompilationGrid from './TrainingCompilationGrid';
import TrainingCompilationCarousel from './TrainingCompilationCarousel';
import HelpAndInfoBox from '../components/HelpAndInfoBox';
import { ActionRowButton, LinkStyleButton } from '../components/Buttons';
import catalogService from '../services/catalogService';
import windowService from '../services/windowService';
import NewPurchasesOverlay from '../curriculum/components/NewPurchasesOverlay';

export default function TrainingCatalog() {
  const ref = React.createRef();
  const [data, setData] = useState({
    isLoading: true,
  });

  useEffect(() => {
    catalogService
      .getCatalogHomePage()
      .then(catalog => {
        setData({ catalog: catalog, isLoading: false });
      })
      .catch(error => {
        console.error(error);
        setData(() => ({ isLoading: false, isError: true }));
      });
  }, []);

  return (
    <>
      <div>
        <TrainingSubMenu activeTab={'catalog'} />
      </div>
      {data.isLoading ? (
        <Container>
          <LoadingState />
        </Container>
      ) : data.isError ? (
        <Container>
          <ErrorMessage>
            A problem occurred showing this page. Please refresh the page to try again. <a href="#/help">Contact Us</a>
          </ErrorMessage>
        </Container>
      ) : (
        <>
          <Container style={{ margin: `${Grid._6} auto` }}>
            <SearchBar />
          </Container>
          <ContainerFluid style={{ margin: `${Grid._6} auto` }}>
            <FeatureCarousel
              ref={ref}
              initialFirstItem={data.catalog.featureCarouselSection.items.length > 2 ? 1 : 0}
              focusOnSelect={true}
            >
              {data.catalog.featureCarouselSection.items.length > 0 &&
                data.catalog.featureCarouselSection.items.map(carouselItem => (
                  <CarouselItem key={carouselItem.id} item={carouselItem} canRedirect />
                ))}
            </FeatureCarousel>
          </ContainerFluid>
          {data.catalog.compilationsSection.compilations.length > 0 &&
            data.catalog.compilationsSection.compilations.map(compilation => (
              <Container
                data-qa-hook="compilationContainer"
                style={{ margin: `${Grid._12} auto` }}
                key={compilation._id}
              >
                <SectionTitle title={compilation.name} subtitle={compilation.shortDescription}>
                  <div>
                    <LinkStyleButton
                      data-qa-hook="compilationViewAll"
                      onClick={() => windowService.redirectTo(`#/catalog/compilation/${compilation._id}`)}
                    >
                      View All
                    </LinkStyleButton>
                  </div>
                </SectionTitle>
                {compilation.trainingItems.length ? (
                  <>
                    {compilation.catalogComponentStyle === 'Grid' && (
                      <TrainingCompilationGrid
                        compilationId={compilation._id}
                        trainingItems={compilation.trainingItems}
                      />
                    )}
                    {compilation.catalogComponentStyle === 'Carousel' && (
                      <TrainingCompilationCarousel
                        compilationId={compilation._id}
                        trainingItems={compilation.trainingItems}
                      />
                    )}
                  </>
                ) : (
                  <HelpAndInfoBox
                    title="Training is on the way"
                    description="Authors are curating content for this compilation and it will be visible here soon."
                  />
                )}
              </Container>
            ))}
          {data.catalog.buttonsSection.buttons && data.catalog.buttonsSection.buttons.length > 0 && (
            <Container style={{ margin: `${Grid._12} auto` }}>
              <SectionTitle
                title={data.catalog.buttonsSection.title}
                subtitle={data.catalog.buttonsSection.description}
              />
              <div
                data-qa-hook="trainingButtonContainer"
                className="grid-container grid-col-1 grid-sm-col-2 grid-md-col-3 grid-gap-24"
              >
                {data.catalog.buttonsSection.buttons.map(button => {
                  const link = button.link;
                  const buttonLinkType = getButtonLinkType(link);
                  const redirectUrl = () =>
                    buttonLinkType === 'training' ? redirectToTrainingLinkUrl(link) : redirectToSearchLinkUrl(link);
                  return (
                    <ActionRowButton data-qa-hook="trainingButtonItem" key={button.id} onClick={() => redirectUrl()}>
                      {button.text} <i className="fas fa-chevron-right" />
                    </ActionRowButton>
                  );
                })}
              </div>
            </Container>
          )}
          <ViewAllTraining description={data.catalog.viewAllSection.description || ''} />
          <NewPurchasesOverlay instructionsType="training" />
        </>
      )}
    </>
  );
}

angular.module('lwNamb').component('trainingCatalog', react2angular(TrainingCatalog));
