import { AxiosResponse } from 'axios';
import cacheableRequest from '../../services/cacheableRequest';
import { Assignment } from '../models/assignment';

export type Share = {
  _id: string;
  sharedWith: {
    orgId: {
      id: string;
    };
    share: {
      on: number;
    };
  }[];
};

export const getAssignmentShares = (assignment: Assignment): Promise<Share> => {
  return cacheableRequest('/v1/user-tasklists/' + assignment.instanceId + '/shares').then(
    ({ data }: AxiosResponse<Share>) => {
      if (data._id) {
        return data;
      }
    }
  );
};
