/* @ts-ignore */
import { EditorState } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';
import { stateFromHTML } from 'draft-js-import-html';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import Breadcrumbs from '../components/Breadcrumbs';
import { Button, ButtonGroup, PrimaryButton } from '../components/Buttons';
import ErrorMessage from '../components/ErrorMessage';
import { FormFieldSet } from '../components/FormElements';
import { Container, PageTitle } from '../components/Layout';
import LoadingState from '../components/LoadingState';
import RichTextEditor from '../containers/RichTextEditor';
import alertService from '../services/AlertService';
import windowService from '../services/windowService';
import { handleError } from '../utils/apiUtils';
import { AddRecipientForm } from './components/AddRecipientForm';
import { AddRecipientList } from './components/AddRecipientList';
import { ContentContainer, EmailEditor, EmailPreview } from './components/ManageEmailInviteElements';
import { useManageEmailInvite } from './hooks/useManageEmailInvite';

type UrlParams = {
  orgId: string;
  inviteId?: string;
};

export const ManageEmailInvite = () => {
  const bodyRef = useRef<any>(null);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const { orgId, inviteId } = useParams<UrlParams>();
  const { isPending, isSubmitting, error, invite, recipients, addRecipient, removeRecipient, sendInvitations } =
    useManageEmailInvite();
  const crumbs = [
    { name: 'Organization', route: '#/org' },
    { name: 'Advanced Invites', route: '#/invites' },
    ...(inviteId
      ? invite
        ? [{ name: invite.name, route: `#/invite/${orgId}/email/${inviteId}` }, { name: 'Manage Email Invitation' }]
        : []
      : [
          { name: 'New Email Invitation', route: `#/invite/${orgId}/email/settings` },
          { name: 'Manage Email Invitation' },
        ]),
  ];

  const submit = () => {
    if (!editorState) return;

    sendInvitations(stateToHTML(editorState.getCurrentContent()))
      .then((redirectLocation: string) => {
        alertService.showOnNextPage('Invitations Sent');
        windowService.redirectTo(redirectLocation);
      })
      .catch(handleError);
  };

  useEffect(() => {
    if (isPending) return;

    const lastSentEmailBody = invite?.emails[invite.emails.length - 1].body;
    if (!lastSentEmailBody) return;

    setEditorState(EditorState.createWithContent(stateFromHTML(lastSentEmailBody)));
  }, [isPending, invite]);

  return (
    <>
      <Breadcrumbs crumbs={crumbs} />
      <Container>
        <PageTitle className="mt-32 mb-40">
          <h1>Manage Email Invitation</h1>
          <p>Add recipients and prepare your email invitation</p>
        </PageTitle>

        {error && <ErrorMessage />}
        {isPending && <LoadingState />}

        {/* allow the email template to load while page data is loading */}
        <ContentContainer style={isPending || error ? { display: 'none' } : {}}>
          <div>
            <FormFieldSet name={'Recipients'}>
              <AddRecipientForm recipients={recipients} addRecipient={addRecipient} />
              <AddRecipientList recipients={recipients} removeRecipient={removeRecipient} />
            </FormFieldSet>

            <FormFieldSet name={'Message'}>
              <EmailEditor>
                <RichTextEditor
                  ref={bodyRef}
                  editorState={editorState}
                  setEditorState={setEditorState}
                  blockTypes={[]}
                  config={{
                    placeholder: 'Enter Message (optional)',
                    height: '200px',
                  }}
                />
              </EmailEditor>
            </FormFieldSet>
            <ButtonGroup>
              <PrimaryButton
                onClick={submit}
                disabled={recipients.length === 0 || isSubmitting}
                operating={isSubmitting}
              >
                Send Invitations
              </PrimaryButton>
              <Button onClick={() => windowService.redirectTo(crumbs[crumbs.length - 2].route)}>Back</Button>
            </ButtonGroup>
          </div>

          <div>
            <FormFieldSet name={'Preview'}>
              <EmailPreview
                templateConfig={{
                  name: 'invite',
                  keys: { personalMessage: '{personalMessage}' },
                }}
                templateValues={{
                  personalMessage: stateToHTML(editorState.getCurrentContent()),
                }}
              />
            </FormFieldSet>
          </div>
        </ContentContainer>
      </Container>
    </>
  );
};
