import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { useUser } from '../../authentication';
import { OutlineButton } from '../../components/Buttons';
import ErrorMessage from '../../components/ErrorMessage';
import HelpAndInfoBox from '../../components/HelpAndInfoBox';
import { Container, FullWidthCard, Image, SubHeaderWithButton } from '../../components/Layout';
import LoadingState from '../../components/LoadingState';
import PopOver from '../../components/PopOver';
import { PreviewRestrictionModal } from '../../components/PreviewRestrictionModal';
import useCurriculum from '../../hooks/useCurriculum';
import useModal from '../../hooks/useModal';
import curriculumService from '../../services/curriculumService';
import windowService from '../../services/windowService';
import { Breakpoints, Color, Grid, Type } from '../../StyleGuide';
import { isTrialLocked, Session } from '../models/session';
import { Volume } from '../models/volume';
import BibleStudyNavigation from './BibleStudyNavigation';
import LeaderTimelinesOverlay from './LeaderTimelinesOverlay';
import { LockedContentOverlay } from './LockedContentOverlay';
import RescheduleSessionModal from './modals/RescheduleSessionModal';
import { ScheduleSessionListItem } from './ScheduleSessionListItem';
import { getCurriculumPricingById } from '../api/getCurriculumPricing';

export type Curriculum = {
  access: {
    package: string;
    itemNumber: string;
  }[];
  combos: Curriculum[];
  id: string;
  license: { package: string; itemNumber: string }[];
  name: string;
  offCycleEnabled?: boolean;
  subscription: {
    itemNumber: string;
    package: string;
    period: string;
    price: string;
    size: string;
  }[];
  trialItemNumber: string;
};

const PageContainer = styled(Container)`
  padding-bottom: ${Grid._8};
`;

const VolumeHeader = styled.div`
  padding: ${Grid._3} ${Grid._4};
  margin: 0 -${Grid._4} 0 -${Grid._4};
  color: ${Type.Color.dark};
  border-top: 1px solid ${Color.Blue._15};
  border-bottom: 1px solid ${Color.Blue._15};
  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    padding: ${Grid._3};
    margin: 0;
  }
  h4 {
    margin: 0;
    font-size: ${Type.Scale._3};
    font-weight: ${Type.Weight.semibold};
  }
`;

const EmptyVolumeSeparator = styled.div`
  border-top: 1px solid ${Color.Blue._15};
`;

const Sessions = styled.ul`
  list-style: none;
  padding: 0 0 ${Grid._4};
`;

const MoreUpcomingContainer = styled.div`
  padding-bottom: ${Grid._6};

  > div {
    margin: 0;
  }
`;

const FullWidthCardContainer = styled(FullWidthCard)`
  padding-top: ${Grid._5};
`;

const VolumeWrapper = ({ volume, index, children }: { volume?: Volume; index: number; children: React.ReactNode }) => (
  <>
    {volume && (
      <VolumeHeader>
        <h4>
          {volume.number && `Volume ${volume.number} • `}
          {volume.name}
        </h4>
      </VolumeHeader>
    )}
    {!volume && index > 0 && <EmptyVolumeSeparator />}
    <div className="grid-container grid-sm-col-8 grid-gap-24" style={{ margin: '16px auto' }}>
      {volume && (
        <div className="grid-sm-col-span-1 hidden-xs">
          <Image src={volume.imageUrl} alt={volume.name} />
        </div>
      )}
      <div className={volume ? 'grid-sm-col-span-7' : 'grid-sm-col-span-8'}>{children}</div>
    </div>
  </>
);

export default function ManageBibleStudySchedule() {
  const user = useUser();
  const { brand, ageCategory } = useCurriculum();
  const { curriculumId } = useParams<{ curriculumId: string }>();
  const [data, setData] = useState<{
    isLoading: boolean;
    isError?: boolean;
    hasLockedSessions?: boolean;
    curriculum?: Curriculum;
    schedule?: { sessions: Session[]; volumes: Volume[] };
    sections?: { volume?: Volume; sessions: Session[] }[];
  }>({ isLoading: true });

  const crumbs = [{ name: 'Curriculum', route: '#/bible-studies' }, { name: `${brand.name}: ${ageCategory}` }];

  useEffect(() => {
    getSessions();
  }, []);

  const getVolume = (volumes: Volume[], volumeId: string) => volumes.find(v => v._id === volumeId);

  const haveSameVolume = (session1?: Session, session2?: Session) =>
    session1 && session2 && session1.volumeId === session2.volumeId;

  const buildSections = (
    schedule: { sessions: Session[]; volumes: Volume[] },
    sections: { volume?: Volume; sessions: Session[] }[] = []
  ): { volume?: Volume; sessions: Session[] }[] => {
    const { sessions, volumes } = schedule;

    const activeSessions = sessions.filter((s: Session) => !brand.timelineEnabled || s.timelines?.length > 0);
    const unlockedSessions = activeSessions.filter((s: Session) => !isTrialLocked(s));
    const lockedSessions = activeSessions.filter((s: Session) => isTrialLocked(s)).slice(0, 2);
    const displaySessions = [...unlockedSessions, ...lockedSessions];

    displaySessions.forEach((session: Session, index: number) => {
      if (index > 0 && haveSameVolume(displaySessions[index - 1], session)) {
        sections[sections.length - 1].sessions.push(session);
      } else {
        sections.push({ volume: getVolume(volumes, session?.volumeId || ''), sessions: [session] });
      }
    });

    return sections;
  };

  const getSessions = () => {
    setData({ isLoading: true });
    Promise.all([
      getCurriculumPricingById(brand.code, curriculumId),
      curriculumService.getCurriculumSchedule(curriculumId),
    ])
      .then(([curriculumPricingData, schedule]) => {
        const [curriculumEntity] = curriculumPricingData;
        const curriculum = curriculumEntity.curriculum[0];
        setData({
          curriculum,
          schedule,
          sections: buildSections(schedule),
          isLoading: false,
          hasLockedSessions: schedule.sessions.some((s: Session) => isTrialLocked(s)),
        });
      })
      .catch(reason => {
        setData({ isLoading: false, isError: true });
        console.error(reason);
      });
  };

  const updateSessionTimelines = (sessionId: string, timelines: any[]) => {
    setData(prev => {
      const updatedSessions = prev.schedule?.sessions.map((session: Session) => {
        if (session.sessionId !== sessionId) return session;

        return {
          ...session,
          timelines,
        };
      });

      const updatedSchedule = {
        ...prev.schedule,
        sessions: updatedSessions,
      } as {
        sessions: Session[];
        volumes: Volume[];
      };

      return {
        ...prev,
        schedule: updatedSchedule,
        sections: buildSections(updatedSchedule),
      };
    });
  };

  const handleSessionClick = (session: Session) => {
    if (brand.timelineEnabled) return openModal('timelines', { session });

    windowService.redirectTo(
      `/manage-bible-study/schedule/${brand.code}/${ageCategory}/${curriculumId}/${session.sessionId}`
    );
  };

  const [modal, openModal] = useModal((type: string, payload: { session: Session }, dismissModal: () => void) => {
    if (type === 'reschedule') {
      if (brand.previewOptions?.restrictions.reschedule)
        return <PreviewRestrictionModal handleDismiss={dismissModal} />;
      if (!user) return null;
      return (
        <RescheduleSessionModal
          orgId={user.lastSelectedAccount}
          curriculumId={curriculumId}
          initiatingUserId={user.userId}
          session={payload.session}
          handleDismiss={dismissModal}
          getSessions={getSessions}
          firstAllowedDate={brand.rescheduleStartDate}
        />
      );
    }

    if (type === 'timelines') {
      const session = data?.schedule?.sessions.find((s: Session) => s.sessionId === payload.session.sessionId);
      if (!session) return null;
      return (
        <LeaderTimelinesOverlay
          session={session}
          updateSessionTimelines={updateSessionTimelines}
          handleDismiss={dismissModal}
        />
      );
    }

    return null;
  });

  return (
    <>
      <BibleStudyNavigation crumbs={crumbs} curriculumId={curriculumId} activeTab="schedule"></BibleStudyNavigation>
      <FullWidthCardContainer>
        <PageContainer data-qa-hook="scheduleViewContainer">
          {data.isLoading ? (
            <LoadingState />
          ) : data.isError ? (
            <ErrorMessage>
              A problem occurred showing this page. Please refresh the page to try again.{' '}
              <a href="#/help">Contact Us</a>
            </ErrorMessage>
          ) : (
            <>
              <SubHeaderWithButton style={{ margin: '24px 0px' }}>
                <h4>All Sessions</h4>
                {data.curriculum?.offCycleEnabled && (
                  <PopOver
                    title="Are you using a previous volume?"
                    description="Now you can reset your schedule to start with a volume in the past."
                    buttonText="Got it, Thanks"
                    storageKey="reset-schedule"
                  >
                    <OutlineButton
                      onClick={() =>
                        windowService.redirectTo(
                          `#/manage-bible-study/reset-schedule/${brand.code}/${ageCategory}/${curriculumId}`
                        )
                      }
                    >
                      <i className="fas fa-cog"></i> Reset Schedule
                    </OutlineButton>
                  </PopOver>
                )}
              </SubHeaderWithButton>
              {data?.sections?.map(({ volume, sessions }, index) => (
                <VolumeWrapper
                  key={index}
                  index={index}
                  volume={volume}
                  data-tracking-component="volume"
                  data-tracking-id={volume ? volume.name : 'empty-volume'}
                >
                  <Sessions data-qa-hook="sessionsContainer">
                    {sessions.map(session => (
                      <ScheduleSessionListItem
                        key={session.sessionId}
                        session={session}
                        onListItemClick={handleSessionClick}
                        onRescheduleClick={s => openModal('reschedule', { session: s })}
                      />
                    ))}
                  </Sessions>
                </VolumeWrapper>
              ))}

              <LockedContentOverlay
                brandCode={brand.code}
                curriculumId={curriculumId}
                isScheduleLoading={data.isLoading}
                schedule={data.schedule}
              />

              {!data.hasLockedSessions && !brand.hideUpcomingSessionsMessage && (
                <MoreUpcomingContainer>
                  <HelpAndInfoBox
                    title="Don't worry, more sessions are on the way"
                    description="We'll add more as they get closer."
                  />
                </MoreUpcomingContainer>
              )}
            </>
          )}
        </PageContainer>
      </FullWidthCardContainer>
      {modal}
    </>
  );
}
