import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Grid, Breakpoints, Type } from '../StyleGuide';
import classNames from 'classnames';
import curriculumService from '../services/curriculumService';
import LoadingState from '../components/LoadingState';
import { ErrorMessage } from '../components/AlertMessages';
import {
  FullWidthCard,
  Container,
  CollectionImgPlaceholder,
  CollectionImgContainer,
  Image,
} from '../components/Layout';
import { SmallRoundedIconButton, TileButton } from '../components/Buttons';
import BibleStudyNavigation from './components/BibleStudyNavigation';
import { ListItemFlexRow } from '../components/ListItemFlexRow';
import { MaterialList, MaterialListItem } from './MaterialList';

import { InlineList } from '../components/Layout';
import { DeemphasizedBadge } from '../components/Badge';
import windowService from '../services/windowService';
import sessionTimelineService from '../services/sessionTimelineService';
import useCurriculum from '../hooks/useCurriculum';
import { SessionDescription, SessionDateHeaderAndDetails } from '../components/SessionElements';

const Collections = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0 0 ${Grid._4};
`;

const MaterialsHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${Grid._3};
  h3 {
    margin: 0;
  }
  select {
    max-width: 130px;
  }
`;

const Details = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: ${Grid._4} 0;
  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    margin: 0 0 0 ${Grid._4};
  }
`;

const CollectionDetails = styled(Details)`
  h2 {
    margin: 0 0 ${Grid._4} 0;
  }
`;

const LeaderGuideButtonsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, max-content));
  grid-gap: ${Grid._4};

  margin-bottom: ${Grid._6};

  button {
    margin: 0;
  }
`;

export default function ManageBibleStudySession() {
  const { brand, ageCategory } = useCurriculum();
  const { curriculumId, sessionId } = useParams();
  const [data, setData] = useState({ isLoading: true });

  const [crumbs, setCrumbs] = useState([
    { name: 'Curriculum', route: '#/bible-studies' },
    {
      name: `${brand.name}: ${ageCategory}`,
      route: `#/manage-bible-study/schedule/${brand.code}/${ageCategory}/${curriculumId}`,
    },
  ]);

  const getTimelinesForSession = () =>
    new Promise((resolve, reject) => {
      if (brand.timelineEnabled !== true) return resolve([]);

      sessionTimelineService
        .getPublishedSessionTimelines(sessionId)
        .then(timelines => (timelines.length === 1 ? reject(timelines[0].id) : resolve(timelines)))
        .catch(() => resolve([]));
    });

  useEffect(() => {
    getTimelinesForSession()
      .then(timelines => {
        const getSession = curriculumService.getSessionFromCurriculumSchedule(curriculumId, sessionId);
        const getCollections = curriculumService.getCollections(curriculumId);
        const getAllSessionMaterials = curriculumService.getAllSessionMaterials(curriculumId, sessionId);

        Promise.all([getSession, getCollections, getAllSessionMaterials])
          .then(([session, collectionsResponse, allSessionMaterials]) => {
            const collections = collectionsResponse
              .map(c => c.collection)
              .map(c => ({
                ...c,
                materials: curriculumService.filterSessionMaterials(c.collectionId, allSessionMaterials),
              }));

            setCrumbs([...crumbs, { name: session.name }]);

            const collectionsWithLeaderGuides = collections.map(c => ({
              ...c,
              leaderGuides: timelines.filter(t => t.collectionId === c.collectionId),
            }));

            if (allSessionMaterials.length > 0) {
              collectionsWithLeaderGuides.push({
                isAllSessionMaterials: true,
                name: 'All Session Materials',
                materials: allSessionMaterials,
                leaderGuides: [],
              });
            }

            setData({
              isLoading: false,
              session,
              collections: collectionsWithLeaderGuides,
            });
          })
          .catch(reason => {
            setData({ isLoading: false, isError: true });
            console.error(reason);
          });
      })
      .catch(timelineId => redirectToLeaderGuide(timelineId, true)); // if only one timeline, redirect to it immediately
  }, []);

  const downloadFile = (type, mediaId) => {
    const downloadUrl = `${getApiUrl()}/curriculum-storage/${
      type === 'video' || type === 'audio' ? 'downloadvideo' : 'files'
    }/${mediaId}`;
    window.open(downloadUrl);
  };

  const redirectToLeaderGuide = (id, replace = false) =>
    windowService.redirectTo(
      `/manage-bible-study/schedule/${brand.code}/${ageCategory}/${curriculumId}/${sessionId}/timeline/${id}`,
      replace
    );

  const handleMaterialClick = material => {
    if (material.materialType === 'podcast' && material.providerData) {
      windowService.openUrl(material.providerData);
    } else {
      downloadFile(material.materialType, material.mediaId);
    }
  };

  return (
    <>
      <BibleStudyNavigation
        isLoading={data.isLoading}
        crumbs={crumbs}
        curriculumId={curriculumId}
        activeTab="schedule"
      />
      <FullWidthCard>
        <Container>
          {data.isLoading ? (
            <LoadingState />
          ) : data.isError ? (
            <ErrorMessage>
              A problem occurred showing this page. Please refresh the page to try again.{' '}
              <a href="#/help">Contact Us</a>
            </ErrorMessage>
          ) : (
            <div data-tracking-section="session" data-tracking-id={data.session.name}>
              <SessionDateHeaderAndDetails session={data.session}>
                <InlineList content={'•'}>
                  <li>{data.session.issueName}</li>
                  <li>Session {data.session.sessionNumber}</li>
                  <li>{data.session.getAvailableThroughMessage('MMMM d')}</li>
                </InlineList>
              </SessionDateHeaderAndDetails>
              <SessionDescription dangerouslySetInnerHTML={{ __html: data.session.description }}></SessionDescription>
              <Collections>
                {data.collections.map((collection, i) => {
                  return (
                    <ListItemFlexRow
                      key={i}
                      data-tracking-component="collection"
                      data-tracking-id={collection.name}
                      align="normal"
                    >
                      <CollectionImgContainer>
                        {collection.isAllSessionMaterials ? null : collection.image ? (
                          <Image src={collection.image} alt={`${brand.name}: ${collection.name}`} />
                        ) : (
                          <CollectionImgPlaceholder />
                        )}
                      </CollectionImgContainer>
                      <CollectionDetails>
                        <h2>{collection.name}</h2>
                        {!!collection.leaderGuides?.length && (
                          <LeaderGuideButtonsContainer>
                            {collection.leaderGuides.map(lg => (
                              <TileButton key={lg.id} onClick={() => redirectToLeaderGuide(lg.id)}>
                                <i className="fas fa-chalkboard-teacher" /> Leader Guide
                              </TileButton>
                            ))}
                          </LeaderGuideButtonsContainer>
                        )}
                        {!collection.isAllSessionMaterials && (
                          <MaterialsHeader>
                            <h3>Materials</h3>
                          </MaterialsHeader>
                        )}
                        {!!collection.materials.length && (
                          <MaterialList>
                            {collection.materials
                              .sort((a, b) => b.hasAccess - a.hasAccess)
                              .map((material, i) => {
                                const isPlus = material.package === 'Plus';
                                const materialType = type => (type === 'download' ? 'document' : type);
                                return (
                                  <MaterialListItem
                                    key={i}
                                    data-tracking-component="session-material"
                                    data-tracking-id={material.name}
                                    disabled={!material.hasAccess}
                                    onClick={material.hasAccess ? () => handleMaterialClick(material) : undefined}
                                  >
                                    <div className="material-text">
                                      <p className="material-label">{materialType(material.materialType)}</p>
                                      <p className="material-name">
                                        {material.name}
                                        {isPlus && (
                                          <DeemphasizedBadge style={{ marginLeft: '8px' }}>Plus</DeemphasizedBadge>
                                        )}
                                      </p>
                                      {collection.isAllSessionMaterials && (
                                        <InlineList content={','}>
                                          {material.series.map(c => (
                                            <li key={c.collectionId}>{c.name}</li>
                                          ))}
                                        </InlineList>
                                      )}
                                    </div>
                                    {material.hasAccess && material.materialType !== 'podcast' && (
                                      <SmallRoundedIconButton>
                                        <i className="fas fa-download fa-fw"></i>
                                      </SmallRoundedIconButton>
                                    )}
                                    {material.hasAccess && material.materialType === 'podcast' && (
                                      <SmallRoundedIconButton>
                                        <i className="fa fa-external-link-alt"></i>
                                      </SmallRoundedIconButton>
                                    )}
                                  </MaterialListItem>
                                );
                              })}
                          </MaterialList>
                        )}
                        {!collection.materials.length && (
                          <p>This collection does not have any materials in this session.</p>
                        )}
                      </CollectionDetails>
                    </ListItemFlexRow>
                  );
                })}
              </Collections>
            </div>
          )}
        </Container>
      </FullWidthCard>
    </>
  );
}
