import React from 'react';
import styled from 'styled-components';
import AssignmentCard from './components/AssignmentCard';
import useTraining from './hooks/useTraining';
import LoadingState from '../components/LoadingState';
import EmptyState from '../components/EmptyState';
import ErrorMessage from '../components/ErrorMessage';
import { Grid } from '../StyleGuide';
import { useUser } from '../authentication';
import { SmallRoundedIconButton } from '../components/Buttons';
import useModal from '../hooks/useModal';
import UnassignTrainingModal from './components/UnassignTrainingModal';
import { removeTraining } from './api/removeTraining';
import alertService from '../services/AlertService';
import { Assignment } from './models/assignment';
import { handleError } from '../utils/apiUtils';

const AssignmentContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${Grid._4};
`;

export default function ActiveTraining() {
  const user = useUser();
  const userId = user?.userId || '';
  const { data, setData } = useTraining(false);

  const [modal, openModal] = useModal((type: string, payload: Assignment, dismissModal: () => void) => {
    switch (type) {
      case 'unassignTraining':
        return (
          <UnassignTrainingModal
            assignment={payload}
            handleDismiss={dismissModal}
            handleSubmit={() => {
              removeTraining(payload, userId)
                .then(() => {
                  alertService.show('Training unassigned');
                  setData({
                    training: data.training?.filter(t => t.instanceId !== payload.instanceId),
                    isLoading: false,
                  });
                  dismissModal();
                })
                .catch(handleError);
            }}
          />
        );
      default:
        return null;
    }
  });

  return (
    <>
      {data.isLoading ? (
        <LoadingState />
      ) : data.isError ? (
        <ErrorMessage />
      ) : data.training && data.training.length > 0 ? (
        <AssignmentContainer>
          <div className="grid-container grid-gap-16" style={{ flex: 1 }}>
            {data.training?.map(assignment => (
              <AssignmentCard
                key={assignment.instanceId}
                data-tracking-component="active-training-assignment"
                data-tracking-id={assignment.name}
                assignment={assignment}
                isActiveAssignment
                assigneeUserId={userId}
                actionButtons={
                  <>
                    {assignment.selfAssigned && (
                      <SmallRoundedIconButton
                        data-tracking-component="button"
                        data-tracking-id="unassign-training"
                        onClick={() => openModal('unassignTraining', assignment)}
                        aria-label="unassign training"
                      >
                        <i className="far fa-trash-alt"></i>
                      </SmallRoundedIconButton>
                    )}
                  </>
                }
              />
            ))}
          </div>
        </AssignmentContainer>
      ) : (
        <EmptyState title="No Training Assigned" description="When you are assigned training, it will show up here." />
      )}
      {modal}
    </>
  );
}
