import { Formik } from 'formik';
import React from 'react';
import { useParams } from 'react-router-dom';
import { useUser } from '../../authentication';
import { Button, DangerButton, PrimaryButton, ToggleButton } from '../../components/Buttons';
import { DatePickerField, FormField, FormFieldContainer, Label } from '../../components/FormElements';
import useModal from '../../hooks/useModal';
import alertService from '../../services/AlertService';
import cacheService from '../../services/cacheService';
import windowService from '../../services/windowService';
import { handleError } from '../../utils/apiUtils';
import { createEasyLinkInvite } from '../api/createEasyLinkInvite';
import { EasyLinkFormValues } from '../models/inviteForm';
import { InviteSettings } from '../models/inviteSettings';
import { InviteUrlParams } from '../models/inviteUrlParams';
import {
  FormValuesListener,
  easyLinkInviteSchema,
  getInitialEasyLinkInviteFormValues,
  getUpdateRequests,
  handleSchemaValidation,
} from '../state/inviteFormState';
import { clearStoredInvite } from '../state/storedInviteState';
import { DeactivateInviteModal } from './DeactivateInviteModal';
import {
  ButtonGroup,
  InputOptionsContainer,
  InviteForm,
  InviteNameFormFieldContainer,
  ToggleInputGroup,
} from './InviteSettingsElements';

type Props = {
  children?: React.ReactNode;
};

export const EasyLinkSettingsForm = ({ children }: Props) => {
  const user = useUser();
  const { inviteId, orgId, settingsId } = useParams<InviteUrlParams>();
  const initialValues = getInitialEasyLinkInviteFormValues();

  const [modal, openModal] = useModal((type: string, payload: { inviteName: string }, dismissModal: () => void) =>
    type ? (
      <DeactivateInviteModal inviteId={inviteId || ''} orgId={orgId} dismissModal={dismissModal} {...payload} />
    ) : null
  );

  const submit = (values: EasyLinkFormValues) => {
    if (inviteId) {
      return Promise.all(getUpdateRequests(values, initialValues, inviteId, settingsId, user?.userId || ''))
        .then(() => {
          cacheService.remove(`/v1/invites/${orgId}`);
          clearStoredInvite();
          alertService.showOnNextPage('Invite Saved');
          windowService.redirectTo(`invite/${orgId}/code/${inviteId}`);
        })
        .catch(handleError);
    }
    return createEasyLinkInvite(
      values.inviteName,
      orgId,
      user?.userId || '',
      new InviteSettings(values.roles, values.groups, values.trainings),
      values.hasRedemptionLimit ? values.redemptionLimit : undefined,
      values.hasExpirationDate ? values.expirationDate : undefined
    )
      .then((invite: { inviteId: string }) => {
        cacheService.remove(`/v1/invites/${orgId}`);
        // If we see that the invite isn't ready in the BE by the time we redirect,
        // we can use this to create a redemption link to display on the next page.
        // const redemptionLink = `${window.location.origin}/#/redeem-invite/${invite.code.prefix}/${invite.code.suffix}`;
        // cacheService.set('membershipCode', { redemptionLink });
        clearStoredInvite();
        alertService.showOnNextPage('Invite Added');
        windowService.redirectTo(`invite/${orgId}/code/view/${invite.inviteId}`);
      })
      .catch(handleError);
  };

  const clearStorageAndRedirectToInvites = () => {
    clearStoredInvite();

    if (inviteId) return windowService.redirectTo(`invite/${orgId}/code/${inviteId}`);
    return windowService.redirectTo(`invites`);
  };

  return (
    <>
      <Formik
        enableReinitialize={!!inviteId}
        initialValues={initialValues}
        validate={values =>
          handleSchemaValidation(
            easyLinkInviteSchema.validate(values, { abortEarly: false, context: { orgId, inviteId } })
          )
        }
        onSubmit={submit}
      >
        {({ values, isSubmitting, setFieldValue }) => (
          <InviteForm>
            <FormField
              Wrapper={InviteNameFormFieldContainer}
              placeholder="Enter Invitation Name"
              name="inviteName"
              id="inviteName"
              label="Invitation Name"
            />

            <InputOptionsContainer>
              <FormFieldContainer>
                <Label htmlFor="redemptionLimit">Redemption Limit</Label>
                <ToggleInputGroup>
                  <FormField
                    name="redemptionLimit"
                    id="redemptionLimit"
                    type="number"
                    disabled={!values.hasRedemptionLimit}
                  />
                  <ToggleButton
                    isOn={!values.hasRedemptionLimit}
                    onClick={() => setFieldValue('hasRedemptionLimit', !values.hasRedemptionLimit)}
                  >
                    {values.hasRedemptionLimit ? 'Unlimited Off' : 'Unlimited On'}
                  </ToggleButton>
                </ToggleInputGroup>
              </FormFieldContainer>

              <FormFieldContainer>
                <Label htmlFor="expirationDate">Expiration Date</Label>
                <ToggleInputGroup>
                  <DatePickerField
                    name="expirationDate"
                    id="expirationDate"
                    dateFormat="MM/dd/yyyy"
                    disabled={!values.hasExpirationDate}
                    minDate={new Date()}
                  />
                  <ToggleButton
                    isOn={values.hasExpirationDate}
                    onClick={() => setFieldValue('hasExpirationDate', !values.hasExpirationDate)}
                  >
                    {values.hasExpirationDate ? 'Has Due Date' : 'No Due Date'}
                  </ToggleButton>
                </ToggleInputGroup>
              </FormFieldContainer>
            </InputOptionsContainer>
            {children}
            <ButtonGroup>
              <div>
                <PrimaryButton operating={isSubmitting} disabled={isSubmitting} type="submit">
                  {inviteId ? 'Save Invitation' : 'Generate Easy Link'}
                </PrimaryButton>
                <Button onClick={clearStorageAndRedirectToInvites}>Cancel</Button>
              </div>

              {inviteId && (
                <>
                  <hr />
                  <DangerButton onClick={() => openModal('deactivate', { inviteName: values.inviteName })}>
                    Deactivate
                  </DangerButton>
                </>
              )}
            </ButtonGroup>
            <FormValuesListener />
          </InviteForm>
        )}
      </Formik>
      {modal}
    </>
  );
};
