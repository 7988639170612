import React, { useState } from 'react';
import styled from 'styled-components';
import { SmallRoundedIconButton } from '../../components/Buttons';
import { RaisedContainer } from '../../components/Layout';
import { Color, Grid } from '../../StyleGuide';
import { limitText } from '../../utils/textUtils';
import { Recipient } from '../models/recipient';

const UpDownIcon = styled.i.attrs({ className: 'fas fa-chevron-down' })<{ isUp: boolean }>`
  transform: rotate(${props => (props.isUp ? '180' : '0')}deg);
`;

const RecipientListHeader = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: ${Grid._4};
  cursor: pointer;
  user-select: none;
`;

const RecipientListContainer = styled(RaisedContainer)`
  padding: ${Grid._4};
  margin-bottom: ${Grid._8};
`;

const RecipientListItemContainer = styled.div`
  display: grid;
  margin-top: ${Grid._4};
`;

const RecipientListItem = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr min-content;
  align-items: center;
  width: 100%;
  padding: ${Grid._4} 0;

  & + & {
    border-top: 1px solid ${Color.Gray._20};
  }

  h6,
  p {
    margin: 0;
  }
`;

type RecipientListProps = {
  recipients: Recipient[];
  removeRecipient: (recipient: Recipient) => void;
};

export const AddRecipientList = ({ recipients, removeRecipient }: RecipientListProps) => {
  const [showList, setShowList] = useState(false);

  return (
    <RecipientListContainer>
      <RecipientListHeader onClick={() => setShowList(_ => !_)}>
        <SmallRoundedIconButton aria-label="toggle recipient list">
          <UpDownIcon isUp={showList} />
        </SmallRoundedIconButton>
        <h6>{recipients.length} Recipients</h6>
      </RecipientListHeader>
      <RecipientListItemContainer style={!showList ? { display: 'none' } : {}}>
        {recipients.map(recipient => (
          <RecipientListItem key={recipient.email}>
            <h6>
              {recipient.firstName} {recipient.lastName}
            </h6>
            <p>{limitText(recipient.email)}</p>
            <SmallRoundedIconButton onClick={() => removeRecipient(recipient)} aria-label="remove">
              <i className="far fa-trash-alt fa-fw"></i>
            </SmallRoundedIconButton>
          </RecipientListItem>
        ))}
      </RecipientListItemContainer>
    </RecipientListContainer>
  );
};
