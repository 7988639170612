import React, { useState } from 'react';
import styled from 'styled-components';
import { Color, Grid } from '../StyleGuide';
import { useUser } from '../authentication';
import AddPeopleTo from '../components/AddPeopleTo';
import Breadcrumbs from '../components/Breadcrumbs';
import { DangerButton } from '../components/Buttons';
import ErrorMessage from '../components/ErrorMessage';
import { Container } from '../components/Layout';
import LoadingState from '../components/LoadingState';
import useModal from '../hooks/useModal';
import useOrg from '../hooks/useOrg';
import alertService from '../services/AlertService';
import { groupService } from '../services/groupService';
import orgService from '../services/orgService';
import windowService from '../services/windowService';
import { handleError } from '../utils/apiUtils';
import RemovePeopleFromOrgModal from './components/RemovePeopleFromOrgModal';

const PageContainer = styled(Container)`
  background: ${Color.white};
  padding: ${Grid._5};
  margin-top: ${Grid._6};
  margin-bottom: ${Grid._10};
`;

const DangerButtonWithRoundedLabel = styled(DangerButton)`
  .rounded-label {
    color: ${Color.Red._50};
  }
`;

type Org = {
  _id: { id: string };
  id?: string;
  accountName: string;
  ownerId: string;
  users: OrgUser[];
};

type OrgUser = {
  _id: { id: string };
};

type Group = {
  id: string;
  name: string;
};

type GroupResponse = {
  id: { id: string };
  name: string;
}[];

type State = {
  org: Org;
  roles: unknown[];
  people: OrgUser[];
  groups: Group[];
  isLoading: boolean;
  isSubmitting: boolean;
  isError: boolean;
};

const RemovePeopleFromOrg = () => {
  const user = useUser();
  const [data, setData] = useState<Partial<State>>({
    roles: [],
    people: [],
    groups: [],
    isLoading: true,
    isSubmitting: false,
    isError: false,
  });

  const [selectedPeople, setSelectedPeople] = useState<OrgUser[]>([]);

  const { isLoading, isError, roles, groups, people, org } = data;

  const crumbs = [{ name: 'People', route: '#/people' }, { name: 'Remove' }];

  useOrg(({ org, isLoading }: { org: Org; isLoading: boolean }) => {
    if (isLoading) return;

    const getOrg = orgService.getOrgUsers(org.id);
    const getRoles = orgService.getRolesOverview(org.id, true);
    const getGroups = groupService.getGroups(org.id);

    const promises = [getOrg, getRoles, getGroups];
    Promise.all(promises)
      .then(([orgResponse, roleResponse, groupResponse]: [Org, unknown[], GroupResponse]) => {
        const formattedGroups: Group[] = groupResponse.map(el => ({
          id: el.id.id,
          name: el.name,
        }));

        const formattedPeople = orgResponse?.users?.filter((user: OrgUser) => user._id && user._id.id !== org.ownerId);

        setData({
          org: orgResponse,
          roles: roleResponse,
          people: formattedPeople,
          groups: formattedGroups,
          isLoading: false,
          isError: false,
        });
      })
      .catch(error => {
        console.error(error);
        setData({ isError: true, isLoading: false });
      });
  });

  const handleRemovePeople = () => {
    orgService
      .removeMembers(org?._id.id, selectedPeople, user?.userId)
      .then(({ succeeded, failed }: { succeeded: number; failed: number }) => {
        alertService.showOnNextPage(
          failed > 0
            ? `Failed to remove ${failed} ${failed > 1 ? 'People' : 'Person'}. Please try again.`
            : `${succeeded} ${succeeded > 1 ? 'People' : 'Person'} removed`
        );
        windowService.redirectTo('/people');
      })
      .catch(error => {
        handleError(error);
        dismissModal();
      });
  };

  const selectedPeopleCallback = (peopleToRemove: OrgUser[]) => {
    setSelectedPeople(peopleToRemove);
    openModal('remove-org');
  };

  const [modal, openModal, dismissModal] = useModal((type: string, _: void, dismissModal: () => void) => {
    if (!type) return;

    return (
      <RemovePeopleFromOrgModal
        handleSubmit={handleRemovePeople}
        selectedPeople={selectedPeople}
        handleDismiss={dismissModal}
        orgName={org?.accountName || ''}
      />
    );
  });

  return isLoading ? (
    <LoadingState />
  ) : isError ? (
    <ErrorMessage />
  ) : (
    <>
      <Breadcrumbs crumbs={crumbs} />
      <PageContainer>
        <AddPeopleTo
          user={user || { userId: '', lastSelectedAccount: '', logInEmail: '' }}
          submitButtonText="Remove"
          handleAddPeople={selectedPeopleCallback}
          alreadyAddedPeople={people || []}
          preventSubmitConfirmation={true}
          filters={{
            roles: { displayName: 'Roles', items: roles },
            groups: { displayName: 'Groups', items: groups },
          }}
          ButtonComponent={DangerButtonWithRoundedLabel}
          isSubmitting={false}
        />
      </PageContainer>
      {modal}
    </>
  );
};

export default RemovePeopleFromOrg;
