import React from 'react';
import ErrorMessage from '../../components/ErrorMessage';
import ConfirmationModal from '../../components/ConfirmationModal';
import { Material } from '../models/material';

const ManageCurriculumRemoveMaterialModal = ({
  material,
  handleDismiss,
  handleSubmit,
}: {
  material: Material;
  handleDismiss: () => void;
  handleSubmit: () => void;
}) => (
  <ConfirmationModal
    buttonActionText="Remove"
    buttonType="danger"
    title="Remove Material"
    prompt={<span>Are you sure you want to remove this material?</span>}
    subtext={material.name}
    handleDismiss={handleDismiss}
    handleSubmit={handleSubmit}
  >
    <ErrorMessage>This action cannot be undone</ErrorMessage>
  </ConfirmationModal>
);

export default ManageCurriculumRemoveMaterialModal;
