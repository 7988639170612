import { PendingInviteRecipient } from '../models/pendingInviteRecipient';
import cacheService from '../../services/cacheService';
import submitCommand from '../../services/submitCommand';
import uuid, { generateUUID } from '../../services/uuid';
import eventBus from '../../services/globalEventBus';

export const createPendingInvites = (
  users: PendingInviteRecipient[],
  orgId: string,
  personalMessage: string,
  invitorId: string,
  initiatingUserId: string
): Promise<{ successRecipients: PendingInviteRecipient[]; failedRecipients: PendingInviteRecipient[] }> => {
  return new Promise((resolve, reject) => {
    const successRecipients: PendingInviteRecipient[] = [];
    const failedRecipients: PendingInviteRecipient[] = [];

    const cmd = {
      id: uuid.generate(),
      invites: users.map(user =>
        generateUUID((id: string) => {
          return {
            id: id,
            email: user.email,
            orgId: {
              id: orgId,
            },
            invitorId: invitorId,
            firstName: user?.firstName,
            lastName: user?.lastName,
            personalMessage: personalMessage,
            initiatingUserId: initiatingUserId,
          };
        })
      ),
    };

    cacheService.remove(`/v1/invites/${orgId}`);
    cacheService.remove(`/v1/organizations/${orgId}/users`);

    eventBus.subscribe('PendingInviteCreated', (event: { id: string; email: string }) => {
      if (cmd.invites.find(item => item.id === event.id)) {
        const userToAdd = users.find(user => user.email === event.email);
        if (userToAdd) {
          successRecipients.push(userToAdd);
        }
        if (successRecipients.length + failedRecipients.length >= users.length) {
          resolve({ successRecipients, failedRecipients });
        }
      }
    });

    eventBus.subscribe('PendingInviteFailed', (event: { id: string }) => {
      if (cmd.invites.find(item => item.id === event.id)) {
        const userToAdd = users.find(user => user.email === event.id);
        if (userToAdd) {
          failedRecipients.push(userToAdd);
        }
        if (successRecipients.length + failedRecipients.length >= users.length) {
          resolve({ successRecipients, failedRecipients });
        }
      }
    });

    submitCommand(cmd.id, cmd, 'CreatePendingInvites', 'PendingInviteCreated', 'PendingInviteError').catch(reject);
  });
};
