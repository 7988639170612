import { ReactNode } from 'react';
import { Timeline } from './timeline';
import { Material } from './material';

export type Session = {
  availableStartDate: Date;
  availableThroughDate: Date;
  curriculumId: string;
  getAvailableThroughMessage: (date: string) => ReactNode;
  isPreview: boolean;
  isScheduled: boolean;
  isThisWeek: boolean;
  issueId: string;
  issueName: string;
  materials: Material[];
  name: string;
  publishEndDate: string;
  publishStartDate: string;
  recommendedDate: Date;
  scheduledDate: Date;
  sessionId: string;
  sessionNumber: number;
  timelines: Timeline[];
  useDate?: boolean;
  volumeId?: string;
  description?: string;
  lockReason?: 'Trial' | 'SubscriptionExpiration';
};

// TODO: Should Session be a class with utility methods attached to it?
export const isTrialLocked = (session: Session): boolean => {
  return session.lockReason?.toLowerCase() === 'trial';
};
