import React from 'react';
import styled from 'styled-components';
import { SessionBadge } from '../../components/Badge';
import { TileButton } from '../../components/Buttons';
import CalendarDateIcon from '../../components/CalendarDateIcon';
import { InlineList } from '../../components/Layout';
import { ListItemFlexRow } from '../../components/ListItemFlexRow';
import { monthAbbrFilter } from '../../filters/date-filters';
import { Breakpoints, Grid, Type } from '../../StyleGuide';
import { isTrialLocked, Session } from '../models/session';

const Date = styled.div`
  width: 102px;
`;

const Details = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin: ${Grid._4} 0;
  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    margin: 0;
  }
  h2,
  h3 {
    margin-top: 0;
    font-weight: ${Type.Weight.semibold};
    color: ${Type.Color.dark};
  }
  h2 {
    font-size: ${Type.Scale._5};
  }
  p {
    color: ${Type.Color.medium};
    margin-bottom: 10px;
  }
`;

type Props = {
  session: Session;
  onListItemClick: (session: Session) => void;
  onRescheduleClick: (session: Session) => void;
};

export const ScheduleSessionListItem = ({ session, onListItemClick, onRescheduleClick }: Props) => {
  const handleReschedleClick = (event: React.MouseEvent) => {
    event.stopPropagation();
    onRescheduleClick(session);
  };

  return (
    <ListItemFlexRow
      key={session.sessionId}
      id={session.sessionId}
      data-tracking-component="session-list-item"
      data-tracking-id={session.name || session.sessionId}
      onClick={() => onListItemClick(session)}
      style={isTrialLocked(session) ? { pointerEvents: 'none' } : {}}
    >
      <Date>
        {session.isScheduled && (
          <CalendarDateIcon
            month={monthAbbrFilter(session.scheduledDate.getMonth())}
            day={session.scheduledDate.getDate().toString()}
            primary={session.isThisWeek}
            large={session.isThisWeek}
          />
        )}
      </Date>
      <Details>
        <SessionBadge session={session} />

        {session.isScheduled && session.isThisWeek ? (
          <h2 data-qa-hook="sessionName">{session.name}</h2>
        ) : (
          <h3 data-qa-hook="sessionName">{session.name}</h3>
        )}
        <InlineList content={'•'}>
          <li data-qa-hook="unitName">{session.issueName}</li>
          <li data-qa-hook="sessionNumber">Session {session.sessionNumber}</li>
          <li data-qa-hook="availableThrough">{session.getAvailableThroughMessage('MMMM d')}</li>
        </InlineList>
      </Details>
      <TileButton data-tracking-id="reschedule-session" onClick={handleReschedleClick}>
        <i className="far fa-calendar-alt"></i> Reschedule
      </TileButton>
    </ListItemFlexRow>
  );
};
