import { AxiosResponse } from 'axios';
import apiClient from '../../services/apiClient';
import catalogService from '../../services/catalogService';
import { AggregateKey, Facet, FacetValue } from '../models/Facet';
import { SearchResponse, SearchResultItem } from '../models/Search';

type Aggregation = {
  buckets: FacetValue[];
};

type RawSearchResponse = {
  aggregations?: Record<AggregateKey, Aggregation>;
  training: SearchResultItem[];
  hitTotal: number;
};

const transform: (data: RawSearchResponse, hasGridSubscription: boolean) => SearchResponse = (
  data,
  hasGridSubscription
) => ({
  ...data,
  facets: data.aggregations
    ? Object.entries<Aggregation>(data.aggregations).map(([key, value]) => {
        return Facet.fromAggregateKey(
          key as AggregateKey,
          value.buckets.map(facetValue => ({ ...facetValue, type: key })),
          hasGridSubscription
        );
      })
    : [],
});

const postQuery = (
  orgId: string,
  query: string,
  catalogId: string,
  selectedFacetValues: FacetValue[],
  showPublishedOnly: boolean,
  hasGridSubscription: boolean
): Promise<SearchResponse> => {
  const facets: { [key: string]: string } = showPublishedOnly ? { published: 'true' } : {};

  if (selectedFacetValues !== undefined) {
    selectedFacetValues.forEach(item => {
      facets[item.type] = item.type === 'trainingType' && item.key === 'Task List' ? 'Tasklist' : item.key;
    });
  }

  const requestBody = {
    ownerId: orgId,
    query: query || undefined,
    catalogId: catalogId,
    facets,
  };

  return apiClient
    .post('/v1/search', requestBody)
    .then((response: AxiosResponse<RawSearchResponse>) => transform(response.data, hasGridSubscription));
};

type Options = {
  selectedFacetValues: FacetValue[];
  showPublishedOnly: boolean;
  hasGridSubscription: boolean;
};

const defaultOptions: Options = {
  selectedFacetValues: [],
  showPublishedOnly: true,
  hasGridSubscription: false,
};

declare let GridRootOrgId: string; // Global variable from index.html

export const submitQuery = (
  query: string,
  orgId: string = GridRootOrgId,
  { selectedFacetValues, showPublishedOnly, hasGridSubscription } = defaultOptions
) =>
  catalogService
    .getCatalogIdForSite()
    .then((catalogId: string) =>
      postQuery(orgId, query, catalogId, selectedFacetValues, showPublishedOnly, hasGridSubscription)
    );
