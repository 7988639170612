import React from 'react';
import { SmallPrimaryButton } from '../../components/Buttons';
import StepIcons from '../../StepIcons';
import SubInfoRow from '../../components/SubInfoRow';
import { purchaseLockedItem } from '../../utils/purchaseUtils';
import alertService from '../../services/AlertService';
import { Assignment, UpNext } from '../models/assignment';

type AssignmentFooterProps = {
  assignment: Assignment;
  redirectToTask: (assignment: Assignment, type: string) => void;
  upNext?: UpNext;
};
export default function AssignmentFooter({ assignment, redirectToTask, upNext }: AssignmentFooterProps) {
  const isLocked = assignment.purchaseRequired;

  const upNextTrainingType = assignment._type === 'ActiveWorkflowOverview' ? 'workflow' : 'course';

  const purchaseLockedTraining = (itemNumber: string) => purchaseLockedItem(itemNumber) || alertService.showError();

  if (isLocked) {
    return (
      <SubInfoRow>
        <div />
        <div>
          <SmallPrimaryButton
            onClick={() => {
              const value =
                typeof assignment.purchaseRequired === 'object'
                  ? assignment.purchaseRequired.value
                  : assignment.purchaseRequired;
              if (value) {
                purchaseLockedTraining(value);
              }
            }}
            aria-label="purchase to unlock"
          >
            Purchase to Unlock
          </SmallPrimaryButton>
        </div>
      </SubInfoRow>
    );
  } else {
    return (
      <>
        {upNext && (
          <SubInfoRow>
            <div>Next</div>
            <div>
              <div style={{ display: 'flex' }}>
                <i className={'icon ' + StepIcons[upNext._type as keyof typeof StepIcons]} />
                <a
                  onClick={() => redirectToTask?.(assignment, upNextTrainingType)}
                  data-tracking-component="up-next"
                  data-tracking-id={upNext.name}
                >
                  {upNext.name}
                </a>
              </div>
            </div>
          </SubInfoRow>
        )}
      </>
    );
  }
}
