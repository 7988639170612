import React from 'react';
import styled from 'styled-components';
import { Grid, Breakpoints, Color, Type } from '../../StyleGuide';
import { Image } from '../../components/Layout';
import { DocumentThumbnailPlaceholder } from '../../components/TrainingElements';
import { SmallRoundedIconButton } from '../../components/Buttons';
import { Material } from '../models/material';

const DocumentRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${Grid._3} 0;
  min-height: 75px;
  color: ${Type.Color.dark};
  transition: all 0.15s ease-in-out;
  @media screen and (min-width: ${Breakpoints.screen_sm}) {
    padding-right: ${Grid._6};
  }
  div:nth-child(1) {
    width: 75px;
    text-align: center;
    & > img {
      max-height: 88px;
    }
    @media screen and (min-width: ${Breakpoints.screen_sm}) {
      width: 100px;
    }
  }
  div:nth-child(2) {
    flex: 1;
    margin: 0 ${Grid._4};
    display: flex;
    flex-direction: column;
    h4,
    ul {
      margin: 0;
      flex: 1;
    }
    ul {
      flex: 2;
    }
    @media screen and (min-width: ${Breakpoints.screen_sm}) {
      flex-direction: row;
      align-items: center;
      margin: 0 ${Grid._5};
      h4 {
        margin-right: ${Grid._6};
      }
    }
  }
  div:nth-child(3) {
    width: 35px;
  }
  + div {
    border-top: 1px solid ${Color.Gray._10};
  }
  &:hover {
    background: ${Color.white};
    cursor: pointer;
    h4:not(.deemphasized-text) {
      text-decoration: underline;
    }
  }
`;

const InlineList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;
  li {
    font-size: ${Type.Scale._3};
    font-weight: ${Type.Weight.normal};
    text-transform: capitalize;
    color: ${Type.Color.medium};
    display: inline-block;
    &:not(:last-of-type)::after {
      content: ', ';
      margin: 0 ${Grid._2};
    }
  }
`;

export default function BibleStudyDocument({
  document,
  redirectToMaterial,
}: {
  document: Material;
  redirectToMaterial: (value: string) => void;
}) {
  const fileExt = document.metadata
    ? document.metadata.fileName.substr(document.metadata.fileName.lastIndexOf('.') + 1)
    : 'text';

  const handleRedirectToMaterial = (e: React.MouseEvent<HTMLElement>) => {
    if (document.materialType === 'text' || (document.materialType === 'download' && fileExt === 'pdf')) {
      redirectToMaterial(document.materialId);
    } else {
      downloadFile(e);
    }
  };

  const downloadFile = (e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    const downloadUrl = `${window.getApiUrl()}/curriculum-storage/files/${document.mediaId}`;
    window.open(downloadUrl);
  };

  return (
    <DocumentRow data-qa-hook="document" onClick={e => handleRedirectToMaterial(e)}>
      <div>
        {fileExt === 'pdf' && (
          <Image
            data-qa-hook="thumbnail"
            src={`${window.getApiUrl()}/curriculum-storage/files/thumbnails/${document.mediaId}`}
          />
        )}
        {fileExt !== 'pdf' && <DocumentThumbnailPlaceholder type={fileExt} />}
      </div>
      <div>
        <h4 data-qa-hook="documentName">{document.name}</h4>
        <InlineList className="deemphasized-text">
          {document?.series?.map(c => (
            <li key={c.collectionId}>{c.name}</li>
          ))}
        </InlineList>
      </div>
      <div>
        {document.materialType !== 'text' && (
          <SmallRoundedIconButton onClick={e => downloadFile(e)}>
            <i className="fas fa-download fa-fw"></i>
          </SmallRoundedIconButton>
        )}
      </div>
    </DocumentRow>
  );
}
