import apiClient from './apiClient';
import submitCommand from './submitCommand';
import uuid, { generateUUID } from './uuid';
import eventBus from './globalEventBus';
import TimeoutPromise from './TimeoutPromise';
import cacheableRequest from './cacheableRequest';
import cacheService from './cacheService';
import licenseService from './licenseService';
import { on } from 'events';

const PERMISSIONS = [
  { access: 'Roles View', label: 'Can Create/Remove Invites' },
  { access: 'Groups View', label: 'Can Create/Remove Groups' },
  { access: 'Build View', label: 'Can Build Training' },
  { access: 'Assign View', label: 'Can Assign Training' },
  { access: 'Account View', label: 'Can Manage Organization' },
  { access: 'Build Curriculum View', label: 'Can Build Curriculum' },
  { access: 'Comment Moderator', label: 'Can Moderate Comments' },
];

export const getUserId = user => (user._type === 'PendingUser' ? user.emailAddress : user._id.id);

export const getUserEmail = user => (user._type === 'PendingUser' ? user.emailAddress : user.email);

const sortUsers = users => {
  const nonPending = users
    .filter(user => user.email)
    .sort((a, b) => (a.firstName.toLowerCase() < b.firstName.toLowerCase() ? -1 : 1));
  const pending = users
    .filter(user => user.emailAddress)
    .sort((a, b) => (a.emailAddress.toLowerCase() < b.emailAddress.toLowerCase() ? -1 : 1));
  return [...nonPending, ...pending];
};

const addDisplayName = people =>
  people.map(person => ({
    ...person,
    displayName: person.firstName ? person.firstName + ' ' + person.lastName : null,
  }));

const transformRoles = roles => roles.filter(role => role.active).map(role => ({ id: role.id.id, name: role.name }));

const transformUser = user => ({
  ...user,
  isPending: user._type === 'PendingUser',
  name: user.firstName ? user.firstName + ' ' + user.lastName : null,
  profileImageUrl:
    user.profile !== undefined && user.profile.imageUrl
      ? `${getApiUrl()}/v1/images/profile/${user._id.id}profile.png`
      : undefined,
  groupNames: user.groups,
  roles: user.roles
    ? user.roles.map(role => ({
        ...role,
        label: role.name === 'Candidate' ? 'primary' : 'success',
      }))
    : [{ name: '' }],
  roleNames: user.roles ? user.roles.map(r => r.name) : undefined,
});

const formatOrgName = name => name.replace(/\n|\r/g, ' ').trim();

const updateRolePermission = (orgId, role, initiatingUserId) => {
  const cmd = {
    id: orgId,
    initiatingUserId: initiatingUserId,
    role: role,
  };

  cacheService.removeAllInPath(`/v1/organizations/${orgId}`);

  return submitCommand(orgId, cmd, 'UpdateOrgAccountRole', 'OrgAccountRoleUpdated', [
    'NoAdminRemaining',
    'DoesNotExist',
  ]);
};

const toggleSearch = (orgId, initiatingUserId, success, failure) => {
  cacheService.removeAllInPath(`/v1/organizations/${orgId}`);
  const cmd = { id: orgId, initiatingUserId: initiatingUserId };
  return submitCommand(orgId, cmd, success, failure, 'OrgAccountError');
};

const orgService = {
  getOrg: orgId =>
    apiClient
      .get(`/v1/organizations/${orgId}`)
      .then(response => response.data)
      .catch(reason => reason),
  getOrgOverview: orgId =>
    apiClient
      .get(`/v1/organizations/${orgId}/overview`)
      .then(response => response.data)
      .catch(reason => reason),
  getOrgUsers: orgId =>
    cacheableRequest(`/v1/organizations/${orgId}/users`)
      .then(response => ({
        ...response.data.org,
        users: addDisplayName(sortUsers(response.data.org.users)),
      }))
      .catch(reason => reason),
  getMember: member =>
    cacheableRequest(`/v1/organizations/users/${member}`).then(response =>
      transformUser({
        ...response.data.user,
        origin: response.data.origin,
      })
    ),
  getRolesOverview: (orgId, transform) =>
    apiClient
      .get(`/v1/organizations/${orgId}/roles`)
      .then(response => (transform ? transformRoles(response.data) : response.data))
      .catch(reason => reason),
  getRole: (orgId, roleId) =>
    apiClient
      .get(`/v1/organizations/${orgId}/roles/${roleId}`)
      .then(({ data: role }) => ({
        ...role,
        permissions: [...PERMISSIONS]
          .map(permission => ({
            ...permission,
            selected: !!role.permission.permissions.find(p => p.access === permission.access),
          }))
          .filter(p => !(p.label === 'Can Build Curriculum' && p.selected === false)),
      }))
      .catch(reason => reason),
  createRole: (orgId, roleUUID, roleName, initiatingUserId) => {
    cacheService.removeAllInPath(`/v1/organizations/${orgId}`);

    return generateUUID(genUUID => {
      return submitCommand(
        orgId,
        {
          id: orgId,
          initiatingUserId: initiatingUserId,
          role: {
            id: { id: roleUUID },
            name: roleName,
            permission: {
              id: { id: genUUID },
              name: roleName,
              permissions: [],
            },
          },
        },
        'CreateOrgAccountRole',
        'OrgAccountRoleCreated',
        ['RoleNameTooLong', 'RoleNameTaken', 'DoesNotExist']
      ).then(res => res);
    });
  },

  hasSubscription: orgId =>
    licenseService.getPairedLicenses(orgId).then(licenses => licenses.some(license => !license.expired)),

  hasIndividualSubscription: userId =>
    licenseService
      .getIndividualLicenses(userId)
      .then(licenses => licenses.some(license => license.subscription?.status === 'Active')),

  hideSearch: (orgId, initiatingUserId) =>
    toggleSearch(orgId, initiatingUserId, 'HideSearchForOrg', 'SearchHiddenForOrg'),
  unhideSearch: (orgId, initiatingUserId) =>
    toggleSearch(orgId, initiatingUserId, 'UnhideSearchForOrg', 'SearchUnhiddenForOrg'),
  updateRoleName: (orgId, roleId, name, initiatingUserId) => {
    cacheService.removeAllInPath(`/v1/organizations/${orgId}`);

    return submitCommand(
      orgId,
      {
        id: orgId,
        roleId: roleId,
        name: name,
        initiatingUserId: initiatingUserId,
      },
      'RenameOrgAccountRole',
      'OrgAccountRoleRenamed',
      ['RoleNameTooLong', 'RoleNameTaken', 'DoesNotExist']
    );
  },
  addPermission: (orgId, roleId, access, initiatingUserId) => {
    return orgService.getRole(orgId, roleId).then(role => {
      if (!role.permission.permissions.find(e => e.access === access)) {
        delete role.permissions;
        role.permission.permissions.push({
          id: { id: access },
          access: access,
        });

        return updateRolePermission(orgId, role, initiatingUserId);
      }
    });
  },
  removePermission: (orgId, roleId, access, initiatingUserId) => {
    return orgService.getRole(orgId, roleId).then(role => {
      if (role.permission.permissions.find(e => e.access === access)) {
        delete role.permissions;
        role.permission.permissions = role.permission.permissions.filter(e => e.access !== access);

        return updateRolePermission(orgId, role, initiatingUserId);
      }
    });
  },
  removeRole: (orgId, roleId, initiatingUserId) => {
    cacheService.removeAll();

    const cmd = {
      id: orgId,
      roleId: roleId,
      initiatingUserId: initiatingUserId,
    };

    return submitCommand(cmd.id, cmd, 'DisableOrgAccountRole', 'OrgAccountRoleDisabled', 'OrgAccountError');
  },
  getEasyLink: orgId =>
    apiClient
      .get(`/v1/organizations/${orgId}/easy-link`)
      .then(response => response.data)
      .catch(reason => reason),
  createPendingInvites: (users, orgId, personalMessage, initiatingUserId, timeoutSeconds = 30) => {
    if (users.length <= 0) return Promise.resolve();

    const cmd = {
      invites: users.map(user => ({
        id: uuid.generate(),
        email: user.email,
        orgId: { id: orgId },
        invitorId: initiatingUserId,
        firstName: user.firstName,
        lastName: user.lastName,
        personalMessage: personalMessage,
        initiatingUserId: initiatingUserId,
      })),
    };

    let unsubscribe;
    return TimeoutPromise({ seconds: timeoutSeconds }, (resolve, reject) => {
      let responsesRemaining = users.length;
      unsubscribe = eventBus.subscribe('PendingInviteCreated', () => {
        if (--responsesRemaining === 0) resolve();
      });

      submitCommand(orgId, cmd, 'CreatePendingInvites', ['PendingInviteCreated'], ['PendingInviteError']).catch(
        reason => reject(reason)
      );
    }).finally(() => {
      if (typeof unsubscribe === 'function') unsubscribe();
    });
  },

  createOrg: (orgId, orgName, initiatingUserId) =>
    new Promise((resolve, reject) => {
      cacheService.remove('/v1/license-pairing-orgs');
      submitCommand(
        orgId,
        {
          id: orgId,
          name: formatOrgName(orgName),
          owner: { id: initiatingUserId },
          initiatingUserId,
        },
        'CreateOrgAccount',
        'OrgAccountRoleAssigned'
      )
        .then(response => {
          const respond = () => resolve(response);
          const timeout = setTimeout(respond, 5000);
          window.addEventListener(
            'USER_LOADED',
            () => {
              clearTimeout(timeout);
              respond();
            },
            { once: true }
          );
        })
        .catch(reject);
    }),

  setOrgLogo: (orgId, initiatingUserId) => {
    cacheService.remove('/v1/license-pairing-orgs');
    return submitCommand(
      orgId,
      { id: orgId, logo: `${orgId}org-icon.png`, initiatingUserId },
      'SetOrgLogo',
      'OrgLogoSet'
    );
  },

  removePendingMember: (inviteId, email, orgId, initiatingUserId) => {
    cacheService.removeAllInPath(`/v1/organizations/${orgId}`);
    const cmd = {
      id: inviteId,
      email: email,
      orgId: {
        id: orgId,
      },
      initiatingUserId: initiatingUserId,
    };
    return submitCommand(inviteId, cmd, 'DisablePendingInvite', 'PendingInviteDisabled', 'PendingInviteError');
  },

  removeMembers: (orgId, users, initiatingUserId) =>
    new Promise((resolve, reject) => {
      cacheService.removeAllInPath(`/v1/organizations/${orgId}`);

      let succeeded = 0;
      let failed = 0;
      let unsubscribeOrgAccountMemberDisabled = () => {};
      let unsubscribeOrgAccountError = () => {};
      const timeout = setTimeout(() => reject('Unable to remove all users. Request timed out.'), 15000);

      const onComplete = () => {
        clearTimeout(timeout);
        unsubscribeOrgAccountError();
        unsubscribeOrgAccountMemberDisabled();
        resolve({ succeeded, failed });
      };

      unsubscribeOrgAccountMemberDisabled = eventBus.subscribe('OrgAccountMemberDisabled', event => {
        if (event?.id && event.id !== orgId) return;

        succeeded++;
        if (succeeded + failed === users.length) onComplete();
      });

      unsubscribeOrgAccountError = eventBus.subscribe('OrgAccountError', event => {
        if (event?.id && event.id !== orgId) return;

        failed++;
        if (failed + succeeded === users.length) onComplete();
      });

      submitCommand(
        orgId,
        {
          orgID: orgId,
          initiatingUserID: initiatingUserId,
          userIDs: users.map(u => u._id),
        },
        'DisableOrgAccountMembers'
      ).catch(reject);
    }),

  resetOrgLogo: (orgId, initiatingUserId) => {
    cacheService.remove('/v1/license-pairing-orgs');
    return submitCommand(orgId, { id: orgId, initiatingUserId }, 'SetOrgLogo', 'OrgLogoSet');
  },

  getLicensePairingOrgs: () => cacheableRequest('/v1/license-pairing-orgs').then(res => res.data),

  putOrgAtBeginning: (orgId, orgs) => {
    const orgIndex = orgs.findIndex(org => org._id.id === orgId);
    return [orgs[orgIndex]].concat(orgs.slice(0, orgIndex).concat(orgs.slice(orgIndex + 1)));
  },

  sortAlphabetically: orgs => orgs.sort((o1, o2) => o1.name.localeCompare(o2.name)),

  removeUserFromRole: (orgId, userId, roleId, initiatingUserId) => {
    cacheService.removeAllInPath(`/v1/organizations/${orgId}`);

    const cmd = {
      id: uuid.generate(),
      orgId: { id: orgId },
      possibleUserId: userId,
      roleId: { id: roleId },
      initiatingUserId: initiatingUserId,
    };

    return submitCommand(cmd.id, cmd, 'RemoveFromRole', 'RemoveFromRoleSucceeded', 'NoAdminRemaining');
  },

  assignRoleToUsers: (orgId, users, roleId, roleName, initiatingUserId) => {
    cacheService.removeAllInPath(`/v1/organizations/${orgId}`);

    const cmd = {
      id: uuid.generate(),
      orgId: { id: orgId },
      roleId: roleId,
      roleName: roleName,
      assigneeIds: users.map(function (m) {
        const assigneeId = getUserId(m);
        return assigneeId;
      }),
      initiatingUserId: initiatingUserId,
    };
    return submitCommand(cmd.id, cmd, 'AddToRole', 'AddToRoleSucceeded', 'OrgAccountError');
  },

  resendEmailInvite: (inviteId, orgId, emailAddress, initiatingUserId) => {
    cacheService.removeAllInPath(`/v1/organizations/people`);

    const cmd = {
      id: inviteId,
      orgId: { id: orgId },
      forEmail: emailAddress,
      initiatingUserId: initiatingUserId,
    };

    return submitCommand(inviteId, cmd, 'ResendEmailInvite', 'EmailInviteResent');
  },
};

export default orgService;
