import cacheService from '../../services/cacheService';
import submitCommand from '../../services/submitCommand';
import uuid from '../../services/uuid';

export const shareResults = (
  instanceId: string,
  sharedWith: string,
  userId: string,
  initiatingUserId: string,
  dateOverride?: Date
) => {
  cacheService.removeAllInPath(`/v1/licenses`);
  const id = uuid.generate();
  const sharedOn = dateOverride ? dateOverride.getTime() : new Date().getTime();
  const cmd = {
    id,
    sharedOn,
    assignable: {
      taskListInstanceId: {
        id: instanceId,
      },
      _type: 'TaskList',
    },
    sharedWith: {
      id: sharedWith,
    },
    userId: {
      id: userId,
    },
    initiatingUserId: initiatingUserId,
  };

  return submitCommand(id, cmd, 'ShareResults', 'ResultsShared', 'ResultsSharedError');
};
