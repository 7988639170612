import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import curriculumService from '../services/curriculumService';
import HelpAndInfoBox from '../components/HelpAndInfoBox';
import { Container, FixedHeaderContainer, FixedHeaderContent } from '../components/Layout';
import SectionHeader from '../components/SectionHeader';
import BibleStudyDocument from './components/BibleStudyDocument';
import BibleStudyVideoCard from './components/BibleStudyVideoCard';
import BibleStudyAudioCard from './components/BibleStudyAudioCard';
import windowService from '../services/windowService';
import useCurriculum from '../hooks/useCurriculum';
import LoadingState from '../components/LoadingState';
import ErrorMessage from '../components/ErrorMessage';
import SessionImageAndDetails from '../curriculum/SessionImageAndDetails';
import SessionHeader from '../curriculum/SessionHeader';
import BibleStudyBanner from './components/BibleStudyBanner';
import { Session } from './models/session';
import { Material } from './models/material';
import BibleStudyPodcast from './components/BibleStudyPodcast';

type SessionData = Session & {
  documents: Material[];
  videos: Material[];
  audio: Material[];
  podcast: Material[];
};

export default function BibleStudySession() {
  const { brand, ageCategory } = useCurriculum();
  const { sessionId, issueId } = useParams<{ sessionId: string; issueId: string }>();
  const [data, setData] = useState<{ isLoading: boolean; session?: SessionData; isError?: boolean }>({
    isLoading: true,
  });

  useEffect(() => {
    curriculumService
      .getSessionAndMaterials(issueId, sessionId)
      .then((session: Session) => {
        if (session.timelines?.length) redirectToTimeline(session.timelines[0].id);

        setData({
          isLoading: false,
          session: {
            ...session,
            documents: session.materials.filter(m => m.materialType === 'download' || m.materialType === 'text'),
            videos: session.materials.filter(m => m.materialType === 'video'),
            audio: session.materials.filter(m => m.materialType === 'audio'),
            podcast: session.materials.filter(m => m.materialType === 'podcast'),
          },
        });
      })
      .catch(error => {
        console.error(error);
        setData({ isLoading: false, isError: true });
      });
  }, []);

  const redirectToTimeline = (timelineId: string) =>
    windowService.redirectTo(
      `/curriculum/${brand.code}/${ageCategory}/session/${sessionId}/issue/${issueId}/timeline/${timelineId}`,
      true
    );

  const redirectToMaterial = (materialId: string) =>
    windowService.redirectTo(`#/curriculum/${brand.code}/${ageCategory}/session/${sessionId}/material/${materialId}`);

  return (
    <FixedHeaderContainer fullViewport>
      <div>
        <SessionHeader
          brandName={`${brand.name}: ${ageCategory}`}
          sessionName={data.session ? data.session.name : 'Loading...'}
          isLoading={!data.isLoading}
        />
      </div>
      <FixedHeaderContent id="session-timeline-container">
        <BibleStudyBanner />
        <Container style={{ paddingBottom: '132px' }}>
          {data.isLoading ? (
            <LoadingState />
          ) : data.isError || !data.session ? (
            <ErrorMessage />
          ) : (
            <>
              <SessionImageAndDetails session={data.session} />

              {!!data.session?.podcast.length && (
                <>
                  <SectionHeader title="Podcast" />
                  {data.session.podcast.map((podcast, i) => (
                    <BibleStudyPodcast episodeUrl={podcast.providerData} key={i} />
                  ))}
                </>
              )}

              {!!data.session?.documents.length && (
                <>
                  <SectionHeader title="Documents" />
                  {data.session.documents.map((document, i) => (
                    <BibleStudyDocument document={document} key={i} redirectToMaterial={redirectToMaterial} />
                  ))}
                </>
              )}
              {!!data.session?.videos.length && (
                <>
                  <SectionHeader title="Videos" margin="40px 0 32px" />
                  <div className="grid-container grid-col-1 grid-xs-col-2 grid-md-col-3 grid-gap-32">
                    {data.session.videos
                      .filter(m => m.materialType === 'video')
                      .map((video, i) => (
                        <BibleStudyVideoCard video={video} key={i} redirectToMaterial={redirectToMaterial} />
                      ))}
                  </div>
                </>
              )}
              {!!data.session?.audio.length && (
                <>
                  <SectionHeader title="Audio" margin="40px 0 32px" />
                  <div className="grid-container grid-col-1 grid-sm-col-2 grid-md-col-3 grid-gap-32">
                    {data.session.audio.map((audio, i) => (
                      <BibleStudyAudioCard audio={audio} key={i} redirectToMaterial={redirectToMaterial} />
                    ))}
                  </div>
                </>
              )}
              {data.session && !data.session.materials.length && (
                <HelpAndInfoBox
                  title="Materials are on the way"
                  description="Authors are curating content for this session and it will be visible here soon."
                />
              )}
            </>
          )}
        </Container>
      </FixedHeaderContent>
    </FixedHeaderContainer>
  );
}
