import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { format, parseISO } from 'date-fns';
import { Grid, Type, Color } from '../StyleGuide';
import { MediumButton, MediumPrimaryButton } from '../components/Buttons';
import { ModalContainer, ModalBody, ModalHeader, ModalFooter } from '../components/ModalElements';
import BibleStudyHeaderImg from './components/BibleStudyHeaderImg';
import { createCurriculum } from '../hooks/useCurriculum';
import { useUser } from '../authentication';
import { formatCurrency } from '../utils/textUtils';
import { handleError } from '../utils/apiUtils';
import licenseService from '../services/licenseService';
import { CurriculumBrandMap } from '../services/curriculumService';
import { getDateFromDateTime } from '../utils/dateUtils';

const ModalContent = styled(ModalBody)`
  margin: ${Grid._5} ${Grid._7};

  h4 {
    margin-bottom: ${Grid._6};
  }
`;

const BibleStudyBrand = styled.div`
  display: flex;
  grid-gap: ${Grid._4};
  flex-wrap: wrap;

  > img {
    width: min(100%, 200px);
  }
`;

const BibleStudyBrandDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
  min-width: 180px;

  h5 {
    font-size: ${Type.Scale._5};
    font-weight: ${Type.Weight.semibold};
  }
`;

const Subscription = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: ${Grid._5};
`;

const SubcriptionDetails = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-width: 220px;

  p {
    margin: 0;

    &:first-child {
      font-size: ${Type.Scale._4};
    }
  }
`;

const SubscriptionPrice = styled.div`
  font-size: 76px;
  font-weight: ${Type.Weight.semibold};
  color: ${Type.Color.medium};

  &:before {
    content: '$';
    font-size: 28px;
    position: relative;
    top: -34px;
  }
`;

const Savings = styled.p`
  color: ${Color.Primary._50};
  text-align: center;
  font-size: ${Type.Scale._3};
  font-weight: ${Type.Weight.semibold};
  margin: ${Grid._2} 0 0 0;
`;

const ConfirmButton = styled(MediumPrimaryButton)`
  min-width: 180px;
`;

export function AnnualUpgradeActionWrapper({ subscription, children }) {
  const [annualSubscription, setAnnualSubscription] = useState();

  const calculateSavings = (monthlyPrice, yearlyPrice) => {
    const savings = Math.floor(monthlyPrice * 12 - yearlyPrice);
    return savings > 0 ? savings : 0;
  };

  const transformSubscriptionTitle = title => {
    const [, brandCode] = Object.entries(CurriculumBrandMap).find(([brandName]) =>
      title.toLowerCase().includes(brandName)
    );
    return [brandCode, title.replace('Monthly', 'Annual')];
  };

  useEffect(() => {
    if (
      !licenseService.isActiveSubscription({ subscription }) ||
      !licenseService.isMonthlySubscription({ subscription })
    )
      return setAnnualSubscription();

    licenseService
      .getAnnualSubscriptionForUpgrade(subscription.item?.number)
      .then(({ newItemNumber, newPrice }) => {
        const [brandCode, title] = transformSubscriptionTitle(subscription.item?.title);
        setAnnualSubscription({
          ...subscription,
          item: {
            ...subscription.item,
            title,
            number: newItemNumber,
          },
          price: newPrice,
          savings: calculateSavings(subscription.price, newPrice),
          brandCode,
        });
      })
      .catch(console.error);
  }, [subscription]);

  return annualSubscription ? children(annualSubscription) : null;
}

AnnualUpgradeActionWrapper.propTypes = {
  subscription: PropTypes.object,
  curriculumId: PropTypes.string,
  children: PropTypes.func.isRequired,
};

export default function AnnualSubscriptionModal({ subscription, onUpgradeComplete, handleDismiss }) {
  const user = useUser();
  const { brand } = createCurriculum(subscription.brandCode);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const confirm = () => {
    setIsSubmitting(true);
    licenseService
      .updateSubscriptionPeriod(subscription.id, user.lastSelectedAccount, subscription.item.number, user.userId)
      .then(updatedSubscription => ({
        ...updatedSubscription,
        item: {
          number: updatedSubscription.newItemNumber,
          title: updatedSubscription.newItemName,
        },
        period: 'Yearly',
        nextRenewalDate: getDateFromDateTime(updatedSubscription.nextRenewalDate),
      }))
      .then(onUpgradeComplete)
      .catch(handleError)
      .finally(handleDismiss);
  };

  return (
    <ModalContainer dismissHandler={handleDismiss}>
      <ModalHeader>
        <h3>Switch to Annual Subscription</h3>
      </ModalHeader>
      <ModalContent>
        <h4>Please confirm the changes to your subscription plan</h4>
        <BibleStudyBrand>
          <BibleStudyHeaderImg src={brand.imgSrc} background={brand.background} alt={brand.name} />
          <BibleStudyBrandDetails>
            <h5 data-qa-hook="switchToAnnualTitle">{subscription.item.title}</h5>
          </BibleStudyBrandDetails>
        </BibleStudyBrand>
        <Subscription>
          <SubcriptionDetails>
            <p>Annual Total</p>
            <p>Beginning {format(parseISO(subscription.nextRenewalDate), 'MMMM dd, yyyy')}</p>
          </SubcriptionDetails>
          <SubscriptionPrice data-qa-hook="switchToAnnualPrice">{formatCurrency(subscription.price)}</SubscriptionPrice>
        </Subscription>
        {subscription.savings > 0 && <Savings>A savings of ${formatCurrency(subscription.savings)} per year</Savings>}
      </ModalContent>
      <ModalFooter>
        <ConfirmButton
          data-qa-hook="switchToAnnualConfirm"
          onClick={confirm}
          disabled={isSubmitting}
          operating={isSubmitting}
        >
          Confirm
        </ConfirmButton>
        <MediumButton onClick={handleDismiss} disabled={isSubmitting}>
          Cancel
        </MediumButton>
      </ModalFooter>
    </ModalContainer>
  );
}

AnnualSubscriptionModal.propTypes = {
  subscription: PropTypes.object.isRequired,
  onUpgradeComplete: PropTypes.func.isRequired,
  handleDismiss: PropTypes.func.isRequired,
};
