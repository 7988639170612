import { Form, Formik, FormikHelpers } from 'formik';
import React from 'react';
import styled from 'styled-components';
import * as Yup from 'yup';
import { Grid, Type } from '../StyleGuide';
import Breadcrumbs from '../components/Breadcrumbs';
import { PrimaryButton } from '../components/Buttons';
import ErrorMessage from '../components/ErrorMessage';
import { FormField, FormFieldContainer, Label } from '../components/FormElements';
import ImageCrop from '../components/ImageCrop';
import { Container, ImageDimensionLabel, SixteenNineAspectRatioPlaceholder } from '../components/Layout';
import LoadingState from '../components/LoadingState';
import alertService from '../services/AlertService';
import windowService from '../services/windowService';
import { handleError } from '../utils/apiUtils';
import { usePathwaySettings } from './hooks/usePathwaySettings';
import { PathwaySettingsFormValues } from './models/pathwaySettings';

const Header = styled.h2`
  color: ${Type.Color.dark};
  font-size: ${Type.Scale._5};
  font-weight: ${Type.Weight.bold};
  overflow-wrap: break-word;
  margin: 0;
`;

const SubHeader = styled.h5`
  color: ${Type.Color.medium};
  font-size: ${Type.Scale._2};
`;

export const PathwaySettings = () => {
  const { isLoading, isError, pathway, crumbs, savePathwaySettings } = usePathwaySettings();
  const initialFormValues: PathwaySettingsFormValues = Object.assign(
    { name: '', description: '', posterImage: '' },
    pathway
  );
  const previousPage = crumbs[crumbs.length - 2]?.route;

  const onSubmit = (values: PathwaySettingsFormValues, { setFieldError }: FormikHelpers<PathwaySettingsFormValues>) =>
    savePathwaySettings(values)
      .then(() => {
        alertService.showOnNextPage(`Pathway Saved`);
        windowService.redirectTo(previousPage);
      })
      .catch(err => {
        if (err?.eventType === 'WorkflowNameDuplicateError') {
          setFieldError('name', 'Sorry, that pathway name is taken');
        } else {
          handleError(err);
        }
      });

  return (
    <>
      <Breadcrumbs crumbs={crumbs} />
      <Container data-qa-hook="pathwaySettingsView" style={{ margin: `${Grid._6} auto` }}>
        {isLoading ? (
          <LoadingState />
        ) : isError ? (
          <ErrorMessage />
        ) : (
          <>
            <div className="grid-container grid-col-4 grid-sm-col-8 grid-col-gap-40" style={{ marginBottom: Grid._5 }}>
              <div className="grid-col-span-4">
                <SubHeader>
                  <i className="fas fa-cogs"></i> Pathway Settings
                </SubHeader>
                <Header>{pathway?.name || ''}</Header>
              </div>
            </div>
            <Formik
              initialValues={initialFormValues}
              enableReinitialize={true}
              validationSchema={Yup.object({
                name: Yup.string().max(256, 'Name is too long').required('A name is required'),
                description: Yup.string().max(32767, 'Description is too long'),
              })}
              onSubmit={onSubmit}
            >
              {({ dirty, isSubmitting, values, setValues }) => (
                <Form>
                  <div className="grid-container grid-col-4 grid-sm-col-8 grid-col-gap-40">
                    <div className="grid-col-span-4">
                      <FormFieldContainer>
                        <Label>Poster</Label>
                        <SixteenNineAspectRatioPlaceholder actionable={!values.posterImage}>
                          <ImageCrop
                            defaultImage={values.posterImage}
                            onUpdate={image => setValues(prevValues => ({ ...prevValues, posterImage: image }), false)}
                            buttonText="Upload Poster"
                            aspectRatio={16 / 9}
                          />
                        </SixteenNineAspectRatioPlaceholder>
                        <ImageDimensionLabel>Recommended: 405 x 227</ImageDimensionLabel>
                      </FormFieldContainer>
                    </div>
                    <div className="grid-col-span-4">
                      <FormField
                        data-qa-hook="pathwaySettingsName"
                        label="Name"
                        name="name"
                        type="text"
                        placeholder="Enter Name"
                      />
                      <FormField
                        data-qa-hook="pathwaySettingsDescription"
                        label="Description"
                        name="description"
                        as="textarea"
                        rows="10"
                        placeholder="Enter Description"
                      />
                      <FormFieldContainer>
                        <PrimaryButton
                          type="submit"
                          data-tracking-id="pathway-settings-save"
                          disabled={!dirty || isSubmitting}
                          operating={isSubmitting}
                        >
                          Save Settings
                        </PrimaryButton>
                      </FormFieldContainer>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </>
        )}
      </Container>
    </>
  );
};
